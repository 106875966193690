import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import FitbitParticipantDataRaw from '../fitbit/participant/FitbitParticipantDataRaw';
import FitbitParticipantConsent from '../fitbit/participant/FitbitParticipantConsent';
import OmronParticipantDataRaw from '../omron/participant/OmronParticipantDataRaw';
import OmronParticipantConsent from '../omron/participant/OmronParticipantConsent';
import DexcomParticipantDataRaw from '../dexcom/participant/DexcomParticipantDataRaw';
import {getLocationState} from '../../services/URL/urlLocation';

const Participant = () => {
  const [participant, setParticipant] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();

  useEffect(() => {
    try {
      const res = getLocationState(location, 'participant');
      if (res.data) {
        setParticipant(res.data.participant);
      }
    } catch (error) {
      console.error(error);
      setError("Something went wrong! We could not find participant's info.");
    }
  }, [location]);

  return (
    <div>
      {error && <div className="error-message centered-text">{error}</div>}
      <h2>Participant Raw Data Page</h2>
      {participant && (
        <div>
          <p>ID: {participant.id}</p>
          <p>Email: {participant.email}</p>
          <hr />
          <FitbitParticipantDataRaw id={participant.id} />
          <FitbitParticipantConsent participant={participant} />
          <hr></hr>
          <OmronParticipantDataRaw id={participant.id} />
          <OmronParticipantConsent participant={participant} />
          <hr></hr>
          <DexcomParticipantDataRaw id={participant.id} />
        </div>
      )}
    </div>
  );
};

export default Participant;
