const getLocationState = (location, keyName) => {
  let res = [];

  if (location && location.state) {
    // Get the data stored in location.state
    res.data = location.state;
  } else {
    throw new Error(`Something went wrong! We could not find ${keyName} info.`);
  }

  return res;
};

const getLocationStateUseStorage = (location, keyName) => {
  let res = [];

  // We may get here by redirecting from another page or due to a page refresh.
  // If we are coming from another page we should have a location available
  if (location && location.state) {
    // Get the data stored in location.state
    res.data = location.state;
    // Remove previous item with the same name from the storage
    sessionStorage.removeItem(keyName);
    // In case of page refresh we are going to lose the location.state
    // Store location's data in sessionStorage to be able ot use it later.
    sessionStorage.setItem(keyName, JSON.stringify(location.state));
  } else {
    // Try to restore data from sessionStorage
    const storageData = sessionStorage.getItem(keyName);
    if (storageData) {
      res.data = JSON.parse(storageData);
    } else {
      throw new Error(
        `Something went wrong! We could not find ${keyName} info.`
      );
    }
  }

  return res;
};

export {getLocationState, getLocationStateUseStorage};
