import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {getLocationState} from '../../services/URL/urlLocation';
import ParticipantSelectDeviceForm from './ParticipantSelectDeviceForm';
import TrialInfo from '../trials/TrialInfo';

const ParticipantDataPage = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [participant, setParticipant] = useState('');
  const [errors, setErrors] = useState('');

  useEffect(() => {
    try {
      const res = getLocationState(location, 'participant');
      if (res.data) {
        setParticipant(res.data.participant);
      }
    } catch (error) {
      console.error(error);
      setErrors("Something went wrong! We could not find participant's info.");
    }
  }, [location]);

  // Secretly open /ParticipantRaw page by using a combination of keystrokes (Control+Shift+Q)
  useEffect(() => {
    let keysPressed = {};
    // Check keys combination on keyDown event
    function handleKeyDown(evt) {
      keysPressed[evt.key] = true;

      if (keysPressed['Control'] && keysPressed['Shift'] && evt.key == 'Q') {
        navigate('/ParticipantRaw', {state: {participant: participant}});
      }
    }
    // If a keyUp event was detected cancel the keysPressed event
    function handleKeyUp() {
      keysPressed = {};
    }
    // Add eventListeners for keyDown and keyUp
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    // Clean up eventListeners
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [navigate, participant]);

  const getDataSelectedDevice = (res) => {
    switch (res.device) {
      case 'fitbit':
        return navigate('/FitbitParticipantDataPage', {state: {participant}});
      case 'omron':
        return navigate('/OmronParticipantDataPage', {state: {participant}});
    }
  };

  return (
    <div className="participant-page">
      <TrialInfo />
      <div>
        {/* {loading ? <div className='spinnerModal'></div> : ''} */}
        <h2>Participant Data</h2>
        <div>
          <p>ID: {participant.id}</p>
          <p>Email: {participant.email}</p>
          <ParticipantSelectDeviceForm
            selectionCallback={getDataSelectedDevice}
          />
          <div className={''}>
            {errors && <DisplayErrors errors={errors} />}
          </div>
        </div>
      </div>
    </div>
  );
};

ParticipantDataPage.propTypes = {
  id: PropTypes.string,
};

export default ParticipantDataPage;
