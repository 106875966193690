import {normalizeResponseErrors} from '../../errorHandling/NormalizeErrors';
import {fetchApiGet} from '../../api/fetchApi';

function hasEmptyArray(obj) {
  for (let key of Object.keys(obj)) {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      return true;
    }
  }
  return false;
}

const GetParticipantDataApi = async (id, email, queryStr) => {
  let response = {data: null, errors: null};
  let url = `/getFitbitServerParticipantData/${id}/${email}/${queryStr}`;
  try {
    let res = await fetchApiGet(url);
    if (res.errors) {
      // If the reason for the failure is 'expired_token', try to refresh
      // the token and to get the data with the new access token
      // TODO: move refresh token to another method
      if (res.errors.length > 0 && res.errors[0].errorType) {
        if (res.errors[0].errorType === 'expired_token') {
          res = await FitbitApi.RefreshTokenApi(id);
          if (res.status === 200) {
            // console.log('Fitbit token refresh ok');
            // refresh token ok
            res = await fetchApiGet(url);
            if (res.errors && res.errors.length > 0) {
              throw new Error(
                `Error: getting data from Fitbit server after refresh failed! - ${res.errors[0].errorType}`
              );
            }
          } else {
            throw new Error(`Error: refresh token failed!`);
          }
        } else if (res.errors[0].errorType === 'system') {
          if (res.errors[0].message === 'Too Many Requests') {
            throw new Error('Too Many Requests');
          }
        } else if (res.errors[0].errorType === 'validation') { throw new Error(
          `Getting data from Fitbit server failed! - ${res.errors[0].message}`
          )
        }
        else {
          // For any other reason just throw an error
          throw new Error(
            `Error1: getting data from Fitbit server failed! - ${res.errors[0].errorType}`
          );
        }
      } else {
        throw new Error('Error: could not get data from Fitbit server!');
      }
    }
    // If everything is ok set the data state to be able to display it
    if (hasEmptyArray(res)) {
      throw new Error('Could not find any data!');
    }
    response.data = res;
  } catch (error) {
    console.error(error.message);
    // TODO: decide what kind of errors to display here
    if (error) {
      response.errors = error.message;
    } else {
      response.errors = 'Error2: getting data from Fitbit server failed!';
    }
  }
  return response;
};

const RefreshTokenApi = async (id) => {
  let res = await fetch(`/fitbitRefreshToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
    }),
  });
  return res;
};

const RevokeAccessTokenApi = async (id) => {
  let res = await fetch(`/revokeAccessToken/${id}`, {
    method: 'GET',
  });
  res = await normalizeResponseErrors(res);
  return res;
};

const SetFitbitDataLocalDBApi = async (id, email, data) => {
  let res = await fetch(`/fitbitDataLocalDB/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      data,
    }),
  });
  return res;
};

const GetFitbitDataLocalDBApi = async (id) => {
  let res = await fetch(`/fitbitDataLocalDB/${id}`, {
    method: 'GET',
  });
  res = await normalizeResponseErrors(res);
  return res;
};

export const FitbitApi = {
  GetParticipantDataApi,
  RefreshTokenApi,
  RevokeAccessTokenApi,
  SetFitbitDataLocalDBApi,
  GetFitbitDataLocalDBApi,
};
