import React from 'react';

import NavBar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import AppRoutes from './Routes';
import {AuthProvider} from './components/authorization/AuthContext';
import {CurrentTrialInfoProvider} from './components/trials/TrialInfoContext';
// import useTokenRefresh from './useTokenRefresh';

const App = () => {
  // TODO: it seems like we don't need this here and it is also caused some issues in case
  //  that user is not logged in. Reconsider using this and if not in use for 3 months delete Shlomi 05/09/2024
  // const isRefreshing = useTokenRefresh(); // Initial token refresh on app start
  // Wait until initial token refresh is done
  // if (isRefreshing) {
  //   return <div>Loading...</div>;
  // }
  return (
    <AuthProvider>
      <header>
        <NavBar />
      </header>
      <main className="centered-container max">
        <CurrentTrialInfoProvider>
          <AppRoutes />
        </CurrentTrialInfoProvider>
      </main>
      <Footer />
    </AuthProvider>
  );
};

export default App;
