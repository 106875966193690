import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';

import '../modal/ModalForm.css';
import './TrialForm.css';

function DeleteTrialForm({
  trial,
  getTrialsDataCallback,
  cancelTrialsDeleteCallback,
}) {
  const {trialId, trialNumber, trialName, trialNotes} = trial;

  const {
    handleSubmit,
    register,
    reset,
    formState: {errors},
  } = useForm({mode: 'onSubmit'});

  const onSubmit = (data) => {
    // Upon success return data to the parent component
    getTrialsDataCallback(data);
  };

  const handleCancelTrialsDelete = () => {
    cancelTrialsDeleteCallback();
  };

  // Insert current values to form's fields
  useEffect(() => {
    reset({
      trialId: trialId,
      trialNumber: trialNumber,
      trialName: trialName,
      trialNotes: trialNotes,
    });
  }, [reset, trialId, trialNumber, trialName, trialNotes]);

  return (
    <form className="modal-form trials-form" onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled>
        <input type="hidden" {...register(`trialId`)} />
        <div className="form-item">
          <div className="form-error" aria-live="polite">
            {errors.trialNumber?.message}
          </div>
          <div>
            <label>Trial Number</label>
          </div>
          <div>
            <input
              name="trialNumber"
              type="text"
              {...register('trialNumber')}
            />
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>Trial Name</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.trialName?.message}
          </div>
          <div>
            <input name="trialName" type="text" {...register('trialName')} />
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>Notes</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.trialNotes?.message}
          </div>
          <div>
            <input name="trialNotes" type="text" {...register('trialNotes')} />
          </div>
        </div>
      </fieldset>

      <div className="form-group icons-flex-container">
        <button type="submit" className="submit-btn">
          <AiOutlineCheck
            className="submit-icon"
            data-tip
            data-for="submit-delete"
          />
          <ReactTooltip
            id="submit-delete"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Submit
          </ReactTooltip>
        </button>
        <button
          type="button"
          className="cancel-btn"
          onClick={handleCancelTrialsDelete}
        >
          <AiOutlineClose
            className="cancel-icon"
            data-tip
            data-for="cancel-delete"
          />
          <ReactTooltip
            id="cancel-delete"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Cancel
          </ReactTooltip>
        </button>
      </div>
    </form>
  );
}

DeleteTrialForm.propTypes = {
  trial: PropTypes.object,
  getTrialsDataCallback: PropTypes.func,
  cancelTrialsDeleteCallback: PropTypes.func,
};

export default DeleteTrialForm;
