import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {getLocationState} from '../../services/URL/urlLocation';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import DeleteParticipantPage from './DeleteParticipantPage';
import FitbitParticipantConsent from '../fitbit/participant/FitbitParticipantConsent';
import FitbitManageParticipantToken from '../fitbit/participant/FitbitManageParticipantToken';
import OmronManageParticipantToken from '../omron/participant/OmronManageParticipantToken';
import OmronParticipantConsent from '../omron/participant/OmronParticipantConsent';
// import DexcomParticipantConsent from '../dexcom/participant/DexcomParticipantConsent';
import TrialInfo from '../trials/TrialInfo';

import './ParticipantManagement.css';

const ParticipantManagement = () => {
  const [participant, setParticipant] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();

  useEffect(() => {
    try {
      const res = getLocationState(location, 'participant');
      if (res.data) {
        setParticipant(res.data.participant);
      }
    } catch (error) {
      console.error(error);
      setError("Something went wrong! We could not find participant's info.");
    }
  }, [location]);

  const displayDevicesControls = () => {
    return (
      <>
        {/* TODO: display only devices that are relevant for the current user*/}
        <h2>Devices Management</h2>
        <hr />
        {participant && (
          <>
            <h2>Fitbit:</h2>
            <FitbitParticipantConsent participant={participant} />
            <FitbitManageParticipantToken participant={participant} />
            <hr />
            <h2>Omron:</h2>
            <OmronParticipantConsent participant={participant} />
            <OmronManageParticipantToken participant={participant} />
            {/* <hr />
            <h2>Dexcom:</h2>
            <DexcomParticipantConsent participant={participant} /> */}
            {/* <DexcomManageParticipantToken participantId={participant.id} /> */}
            {/* <hr /> */}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="centered-text">
        {error && <DisplayErrors errors={error} />}
      </div>
      <TrialInfo />
      {participant && (
        <div>
          <div className="participant-flex-container">
            <div className="participant-info flex-item">
              <h2>Participant</h2>
              <hr />
              <p>ID: {participant.id}</p>
              <p>Email: {participant.email}</p>
              <div className="">
                <DeleteParticipantPage
                  participant={participant}
                  deleteCallback={null}
                />
              </div>
            </div>
            <div className="participant-info flex-item">
              {displayDevicesControls()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ParticipantManagement;
