import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {DisplayDateValueChart} from '../../displayData/DisplayDateValueChart';
import {DisplayDateValueTable} from '../../displayData/DisplayDateValueTable';
import {
  OmronPrepareBloodPressure,
  OmronPrepareWeight,
} from './OmronPrepareDataForDisplay';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';

const OmronDisplayRefinedData = (props) => {
  const {deviceName = null, subject = null, data, queryInfo} = props;
  const [participantData, setParticipantData] = useState(null);
  const [displayDataName, setDisplayDataName] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    const refineData = (data, queryInfo) => {
      try {
        const category = queryInfo.category;
        let result = [];
        switch (category) {
          case 'bloodPressure':
            result.chartType = 'LineChart';
            result.data = data;
            result = OmronPrepareBloodPressure(result);
            setParticipantData(result);
            setDisplayDataName('dateValueData');
            break;
          case 'weight':
            result.chartType = 'LineChart';
            result.data = data;
            result = OmronPrepareWeight(result);
            setParticipantData(result);
            setDisplayDataName('dateValueData');
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
        setErrors('Error: Could not prepare Omron data for display');
      }
    };

    // Refine the data for display
    refineData(data, queryInfo);
  }, [data, queryInfo]);

  const displayData = () => {
    switch (displayDataName) {
      case 'dateValueData':
        return (
          <div>
            <h3 className="data-title">{queryInfo.subject}</h3>
            <DisplayDateValueChart
              content={participantData}
              displayTable={true}
              chartType={participantData.chartType}
            />
            <DisplayDateValueTable
              deviceName={deviceName}
              tableName={subject}
              content={participantData}
              displayTable={true}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {errors && <DisplayErrors errors={errors} />}
      {participantData && displayData()}
    </>
  );
};

OmronDisplayRefinedData.propTypes = {
  deviceName: PropTypes.string,
  subject: PropTypes.string,
  data: PropTypes.array,
  queryInfo: PropTypes.object,
};

export default OmronDisplayRefinedData;
