import React, {useState, useEffect, createContext, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {UserAuthApi} from './UserAuthApi';
import {fetchApiSet} from '../api/fetchApi';
import {normalizeResponseErrors} from '../errorHandling/NormalizeErrors';

// Use context for handling users authorizations and permissions
const AuthContext = createContext(null);

const AuthProvider = (props) => {
  let navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const login = async (userStatusMessage, role) => {
    setCurrentUser(userStatusMessage);
    setUserRole(role); // Set the user role on login
  };

  const sfaValidate = (status) => {
    switch (status) {
      case 'Authorized':
        setCurrentUser('Authorized');
        navigate('/');
        break;
      case 'SFAValidateEmailCodeNeeded':
        setCurrentUser('SFAValidateEmailCodeNeeded');
        navigate('/SFASendCodeViaMsgPage');
        break;
      case 'SFAValidateCodeNeeded':
        setCurrentUser('SFAValidateCodeNeeded');
        navigate('/SFAValidatePage');
        break;
      default:
        setCurrentUser('SFAIsNotSet');
        navigate('/SFASetupPage');
        break;
    }
  };

  const logout = useCallback(
    async (message) => {
      setCurrentUser(null);
      setUserRole(null); // Clear the role on logout

      try {
        const response = await UserAuthApi.LogoutApi();
        console.log('Successfully logged out:', response);
      } catch (error) {
        console.error('Logout Error:', error);
      }

      // Redirect to login page with a dynamic message
      navigate('/LoginPage', {state: {message}});
    },
    [navigate]
  );

  const handleError = (err) => {
    if (err.code === 403 && err.message) {
      if (err.user?.role) {
        setUserRole(err.user.role);
        switch (err.message) {
          case 'SFAValidateCodeNeeded':
            setCurrentUser('SFAValidateCodeNeeded');
            break;
          case 'SFAValidateEmailCodeNeeded':
            setCurrentUser('SFAValidateEmailCodeNeeded');
            break;
          case 'SFAIsNotSet':
            setCurrentUser('SFAIsNotSet');
            break;
          default:
            setCurrentUser(null);
            setUserRole(null);
            break;
        }
      }
    } else {
      setCurrentUser(null);
      setUserRole(null);
    }
  };

  // Check if user is logged in on the server
  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await fetch(`/auth/user`, {method: 'GET'});
        await normalizeResponseErrors(res);
        const data = await res.json();

        if (data) {
          if (data.message === 'Authorized') {
            // User is authorized
            setUserRole(data.user.role); // Get user's role from the server
            setCurrentUser('Authorized');
          } else {
            // User is not authorized
            setUserRole(null);
            setCurrentUser(null);
          }
        }
      } catch (err) {
        handleError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getUser();
  }, []);

  // Periodically refresh token
  useEffect(() => {
    // Since no user is logged in, there's no need to refresh the token
    if (!currentUser) {
      return;
    }

    const refreshToken = async () => {
      try {
        const response = await fetchApiSet('/auth/refreshToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: '',
        });
        if (response) {
          console.log('Token refreshed successfully');
          // Store a flag in local storage to indicate the token has been refreshed
          localStorage.setItem('tokenRefreshed', 'true');
        } else {
          // User is not authorized
          throw new Error('Failed to refresh token');
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        // User is not authorized, log the user out
        logout();
      }
    };

    // Check if the token has already been refreshed in this session
    const tokenRefreshed = localStorage.getItem('tokenRefreshed');
    if (!tokenRefreshed) {
      // Call refreshToken initially on load
      refreshToken();
    }

    // Set up interval for refreshing the token
    const intervalId = setInterval(refreshToken, 12 * 60 * 1000); // 12 minutes in milliseconds

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentUser, logout]);

  // Check if token is valid on every render or update
  useEffect(() => {
    async function validateToken() {
      try {
        let res = await fetch(`/auth/validateToken`, {
          method: 'GET',
        });
        res = await normalizeResponseErrors(res);
        if (res) {
          // console.log('Token is valid');
        } else {
          console.error('Invalid token');
          // User is not authorized
          logout('Your session has expired. Please log in again.');
        }
      } catch (error) {
        console.error('Error invalid token:', error);
        // User is not authorized
        logout();
      }
    }

    // Validate the token only if user is logged in
    if (currentUser) {
      validateToken();
    }
  });

  const authContextValue = {
    login,
    logout,
    sfaValidate,
    currentUser,
    userRole,
    isLoading,
  };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

// Create a custom hook
const useAuth = () => React.useContext(AuthContext);

export {AuthProvider, useAuth};
