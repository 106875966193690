import {useState, useEffect} from 'react';

import {normalizeResponseErrors} from '../errorHandling/NormalizeErrors';

// Custom hook to fetch data from the server
const useApi = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      setLoading(true);
      setError('');
      try {
        const res = await fetch(url, {
          method: 'GET',
        });
        if (!didCancel) {
          await normalizeResponseErrors(res);
          if (res.ok) {
            const result = await res.json();
            // console.log(`Result: ${result}`);
            if (result) {
              if (result.data) {
                setData(result.data);
              } else {
                setData(result);
              }
            }
          } else {
            // TODO: decide about what errors to display here
            setError('Error: res not ok');
          }
        }
      } catch (error) {
        if (!didCancel) {
          if (error) {
            // TODO: decide about what errors to display here
            if (error.displayToUser === true) {
              if (error.message) {
                setError(error.message);
              } else {
                setError(`Unknown Error 1!`);
              }
            } else {
              setError(`Unknown Error 2!`);
            }
          }
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // Clean up function to check if the component was unmounted
    // during the await time, if this happened we catch it here and we are
    // canceling the state transition after we get the promise back.
    return () => {
      didCancel = true;
    };
  }, [url]);

  return {data, loading, error};
};

export default useApi;
