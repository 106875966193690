import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../table/Table';
import {DisplayDateValueChart} from '../../displayData/DisplayDateValueChart';

import './FitbitParticipant1DayData.css';

const FitbitParticipant1DayData = (props) => {
  let {participantInfo = null, participantData = null} = props;

  const displayData = () => {
    if (!participantData.displayStyle) {
      participantData.displayStyle = {};
    }
    participantData.displayStyle.stacked = null;
    participantData.displayStyle.height = 400;
    return (
      <div>
        <div className="charts-flex-container">
          <div className="flex-item">
            <DisplayDateValueChart
              content={participantData}
              displayTable={true}
              chartType={participantData.chartType}
            />
          </div>
          {participantData.pieChartInfo && (
            <div className="flex-item">
              <DisplayDateValueChart
                content={participantData}
                displayTable={true}
                chartType={'PieChart'}
              />
            </div>
          )}
        </div>
        <div className="">
          <Table
            deviceName={'Fitbit'}
            tableName={participantData.subject}
            data={participantData.data}
            columns={participantData.columns}
            dataCallback={null}
            features={{
              singleRowSelection: false,
              multipleRowsSelection: false,
              maxNumOfSelectedRows: null,
              displayGlobalFilter: false,
              exportDataToFile: true,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="participant-data">
      {participantData && (
        <h2 className="data-title">Fitbit - {participantData.subject}</h2>
      )}
      <div className="centered-text"></div>
      {participantInfo && (
        <div>
          {participantInfo.id && <div>ID: {participantInfo.id}</div>}{' '}
          {participantInfo.email && <div>Email: {participantInfo.email}</div>}
        </div>
      )}
      {participantData && displayData()}
    </div>
  );
};

FitbitParticipant1DayData.propTypes = {
  participantInfo: PropTypes.object,
  participantData: PropTypes.object,
};

export default FitbitParticipant1DayData;
