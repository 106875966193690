import React from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Line,
  Bar,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import CustomToolTip from './CustomTooltip';

const ComposedBarLineChartDraw = ({content}) => {
  const {data, chartInfo, xAxisKey, yAxisInfo, displayStyle, subject} = content;

  const {
    stacked = null,
    height = 300,
    customTooltip = false,
  } = displayStyle ? displayStyle : '';

  return (
    <ResponsiveContainer width="98%" height={height} debounce={1}>
      <ComposedChart data={data}>
        {xAxisKey.map((item, key) => {
          return (
            <XAxis
              key={`XAxis${key}`}
              xAxisId={key}
              dataKey={item}
              fontSize={'80%'}
              dy={5} /*distance between label text and axis*/
            />
          );
        })}
        {yAxisInfo &&
          yAxisInfo.map((item, key) => {
            return (
              <YAxis
                key={`YAxis${key}`}
                yAxisId={key}
                type={item.type}
                fontSize={item.fontSize}
                orientation={item.orientation}
                label={item.label}
                width={80} // Keeps some distance between the text label and YAxis
              />
            );
          })}
        {chartInfo.map((item, key) => {
          return chartInfo[key].chart === 'line' ? (
            <Line
              yAxisId={1}
              key={`line${item.valueName}${key}`}
              stackId={stacked}
              dataKey={item.valueName}
              name={item.valueName}
              fill={item.valueColor}
              unit={item.units}
            />
          ) : (
            <Bar
              yAxisId={0}
              key={`bar${item.valueName}${key}`}
              stackId={stacked}
              dataKey={item.valueName}
              name={item.valueName}
              fill={item.valueColor}
              unit={item.units}
            />
          );
        })}
        <Legend />
        {customTooltip ? (
          <Tooltip
            cursor={false}
            content={<CustomToolTip chartInfo={chartInfo} subject={subject} />}
            position={{y: 0}}
          />
        ) : (
          <Tooltip cursor={false} />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

ComposedBarLineChartDraw.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ComposedBarLineChartDraw;
