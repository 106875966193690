// import ReCAPTCHA from 'react-google-recaptcha';
import {REACT_APP_RECAPTCHA_SITE_KEY} from '../../config';

import {fetchApiSet} from '../api/fetchApi';

const loadReCaptchaVer3ScriptByURL = (id, url, callback) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

const recaptchaBackendVerification = async (token, url) => {
  const res = await fetchApiSet(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      recaptchaResponse: token,
    }),
  });
  return res;
};
const onFormSubmitRecaptchaVerification = async () => {
  let res = null;
  try {
    const token = await window.grecaptcha.execute(
      REACT_APP_RECAPTCHA_SITE_KEY,
      {action: 'submit'}
    );
    if (token) {
      // Getting the token was successful, run a backend verification
      res = await recaptchaBackendVerification(token, '/recaptchaVer3Verify');
      return res;
    } else {
      throw new Error(`V3 Token is missing!`);
    }
  } catch (error) {
    console.error(error);
    return error;
    // throw new Error(`Error: V3 reCAPTCHA verification failed!`);
    // if (error && error.displayToUser && error.message) {
    //   setRegErrors(error.message);
    // } else {
    //   setRegErrors(`Message could not be sent (V3)!`);
    // }
  }
};

// load the reCAPTCHA version 3 script by passing the URL
loadReCaptchaVer3ScriptByURL(
  'recaptcha-key',
  `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_SITE_KEY}`,
  () => {
    console.log(`reCAPTCHA version 3 Script loaded!`);
  }
);

export {loadReCaptchaVer3ScriptByURL, onFormSubmitRecaptchaVerification};
