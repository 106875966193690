import React from 'react';
import PropTypes from 'prop-types';

import './Modal.css';

const Modal = ({title, text, closeModalCallback, children}) => {
  return (
    <div className="modal-backdrop" onClick={closeModalCallback}>
      <div
        className="modal"
        onClick={(e) => {
          // Do not close modal if the modal itself was clicked
          e.stopPropagation();
        }}
      >
        <h3 className="modal-title">{title}</h3>
        <div className="modal-text">{text}</div>
        <div className="centered-content"></div>
        <button
          type="button"
          className="modal-close"
          onClick={closeModalCallback}
        >
          X
        </button>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  closeModalCallback: PropTypes.func,
  children: PropTypes.node,
};

export default Modal;
