import React from 'react';
import {Link, Navigate, useLocation} from 'react-router-dom';

import LoginForm from './LoginForm';
import {useAuth} from '../authorization/AuthContext';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';

import './AuthForms.css';

const LoginPage = () => {
  const {currentUser} = useAuth();
  const location = useLocation();
  // If user is already logged in redirect to the dashboard
  if (currentUser) {
    return <Navigate to="/" />;
  }
  const message = location.state?.message;
  return (
    <div className="login">
      <div className="centered-container centered-text content">
        <h1>Welcome to CDConnect</h1>
        {/* Display message as an error */}
        {message && <DisplayErrors errors={message} />}
      </div>
      <LoginForm />
      {/* <div className="centered-container narrow flex-container"> */}
      <div className="centered-container narrow">
        <p>
          <Link to="/ResetPwdReqPage">Forgot Password?</Link>
        </p>
        <div>
          {/* At this point don't allow new users to sign in */}
          <div>
            <Link to="/ContactUsForm/">
              New user? To sign up, please click here to get in touch with us.
            </Link>
            {/* <Link to="https://cdconnect.net/contact-us/" target="_blank">
              New user? To sign up, please click here to get in touch with us.
            </Link> */}
          </div>
          {/* {<Link to="/SignupPage"> Sign Up</Link>} */}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
