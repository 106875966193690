import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {IoEyeOffOutline} from 'react-icons/io5';
import {IoEyeOutline} from 'react-icons/io5';

import {useYupValidationResolver} from '../forms/YupValidationResolver';
import {onFormSubmitRecaptchaVerification} from '../common/googleRecaptcha';

// Yup form validation rules
const validationSchema = yup.object({
  currentPassword: yup.string().required('Current Password is required'),
  newPassword: yup
    .string()
    .required('New Password is required')
    .min(8, 'Password can not be less than 8 characters')
    .max(20, 'Password can not be more than 20 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\\!#$%()*+,-./:;=?@[\]^_`{|}~])[A-Za-z\d\\!#$%()*+,-./:;=?@[\]^_`{|}~]+$/,
      'Password must have at least One Uppercase, One Lowercase, One Number, and One special character  \\!#$%()*+,-./:;=?@[]^_`{|}~'
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], '2 New Passwords must match')
    .required('Confirm New Password is required'),
});

function ChangePwdReqForm({getDataCallback, cancelBtnClickedCallback}) {
  const resolver = useYupValidationResolver(validationSchema);
  const [regError, setRegError] = useState('');
  const [currentPasswordType, setCurrentPasswordType] = useState('password');
  const [displayCurrentPassword, setDisplayCurrentPassword] = useState(false);
  const [newPasswordType, setNewPasswordType] = useState('password');
  const [displayNewPassword, setDisplayNewPassword] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = async (data) => {
    await window.grecaptcha.ready(async () => {
      try {
        const res = await onFormSubmitRecaptchaVerification();
        if (res.errors) {
          // If reCAPTCHA version 3 failed, display an error
          throw new Error(res.errors);
        } else {
          // Upon success send data back to the parent
          getDataCallback(data);
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setRegError(error.message);
        } else {
          setRegError(`reCAPTCHA V3 verification failed!`);
        }
      }
    });
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  const handleToggleDisplayCurrentPassword = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDisplayCurrentPassword(!displayCurrentPassword);
    if (!displayCurrentPassword) {
      setCurrentPasswordType('text');
    } else {
      setCurrentPasswordType('password');
    }
  };

  const handleToggleDisplayNewPassword = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDisplayNewPassword(!displayNewPassword);
    if (!displayNewPassword) {
      setNewPasswordType('text');
    } else {
      setNewPasswordType('password');
    }
  };

  const handleToggleDisplayConfirmPassword = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDisplayConfirmPassword(!displayConfirmPassword);
    if (!displayConfirmPassword) {
      setConfirmPasswordType('text');
    } else {
      setConfirmPasswordType('password');
    }
  };

  return (
    <form
      className="change-pwd-form centered-container narrow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-error centered-text">{regError}</div>
      <fieldset>
        <div className="flex-title-icon">
          <label className="flex-title-icon-item">*Current Password</label>
          <span
            className="form-btn flex-title-icon-item"
            onClick={handleToggleDisplayCurrentPassword}
          >
            {displayCurrentPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
          </span>
        </div>
        <div className="form-error" aria-live="polite">
          {errors.currentPassword?.message}
        </div>
        <input
          name="currentPassword"
          type={currentPasswordType}
          autoComplete="off"
          {...register('currentPassword')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.currentPassword ? 'is-invalid' : ''}`}
        />

        <div className="flex-title-icon">
          <label className="flex-title-icon-item">*New Password</label>
          <span
            className="form-btn flex-title-icon-item"
            onClick={handleToggleDisplayNewPassword}
          >
            {displayNewPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
          </span>
        </div>
        <div className="form-error" aria-live="polite">
          {errors.newPassword?.message}
        </div>
        <input
          name="newPassword"
          type={newPasswordType}
          autoComplete="off"
          {...register('newPassword')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.newPassword ? 'is-invalid' : ''}`}
        />

        <div className="flex-title-icon">
          <label className="flex-title-icon-item">*Confirm New Password</label>
          <span
            className="form-btn flex-title-icon-item"
            onClick={handleToggleDisplayConfirmPassword}
          >
            {displayConfirmPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
          </span>
        </div>
        <div className="form-error" aria-live="polite">
          {errors.confirmNewPassword?.message}
        </div>
        <input
          name="confirmNewPassword"
          type={confirmPasswordType}
          autoComplete="off"
          {...register('confirmNewPassword')}
          onFocus={setRegErrorMessage}
          className={`form-input ${
            errors.confirmNewPassword ? 'is-invalid' : ''
          }`}
        />
      </fieldset>

      <div className="form-group centered-text">
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
        <button
          type="button"
          className="btn cancel-btn"
          onClick={() => cancelBtnClickedCallback()}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

ChangePwdReqForm.propTypes = {
  getDataCallback: PropTypes.func,
  cancelBtnClickedCallback: PropTypes.func,
};

export default ChangePwdReqForm;
