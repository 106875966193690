import {fetchApiGet} from '../../api/fetchApi';

const GetParticipantDataApi = async (id, queryStr) => {
  let response = {data: null, errors: null};
  let url = `/getDexcomParticipantData/${id}/${queryStr}`;
  try {
    let res = await fetchApiGet(url);
    if (res.errors) {
      throw new Error(
        `Error: getting data from Dexcom server failed! - ${res.errors[0].errorType}`
      );
    } else {
      // If everything is ok set the data state to be able to display it
      response.data = res;
    }
  } catch (error) {
    console.error(error.message);
    // TODO: decide what kind of errors to display here
    if (error) {
      response.errors = error.message;
    } else {
      response.errors = 'Error: getting data from Dexcom server failed!';
    }
  }
  return response;
};

const RefreshTokenApi = (id) => {
  return fetch(`/dexcomRefreshToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
    }),
  });
};

const RevokeAccessTokenApi = (id) => {
  return fetch(`/dexcomRefreshToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
    }),
  });
};

export const DexcomApi = {
  GetParticipantDataApi,
  RefreshTokenApi,
  RevokeAccessTokenApi,
};
