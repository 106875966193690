import React from 'react';

import './DisplayData.css';

const DisplayDataNestedObject = (values) => {
  const results = [];
  let idx = 0;

  // TODO: See how I can indent the list items of the sub categories
  // Use recursive to read and display all the nested items
  const recurse = (values, data) => {
    // If we have a data (key) from the previous call push it to results as a new title
    if (data) {
      results.push(
        <div className='object' key={idx++}>
          {data}
        </div>
      );
    }
    Object.entries(values).map(([key, value]) => {
      if (value != undefined) {
        if (value && typeof value === 'object') {
          // In case of an object call 'recurse' again with values
          // the new object and the current key
          recurse(value, key);
        } else {
          // If not an object push the key and the value to results as a list item
          results.push(
            <li className='listItem' key={idx++}>
              {key}: {value}
            </li>
          );
        }
      }
    });
  };
  // First call to recurse
  recurse(values);
  return results;
};

export {DisplayDataNestedObject};
