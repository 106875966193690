export const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN;

// reCAPTCHA Version3
export const REACT_APP_RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const REACT_APP_RECAPTCHA_V2_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY;

// Fitbit
export const REACT_APP_FITBIT_OAUTH_URL =
  process.env.REACT_APP_FITBIT_OAUTH_URL ||
  'https://www.fitbit.com/oauth2/authorize';

// Omron
export const REACT_APP_OMRON_OAUTH_URL =
  process.env.REACT_APP_OMRON_OAUTH_URL ||
  'https://prd-oauth-website.ohiomron.com/connect/authorize';

// Dexcom
// For sandbox env
export const REACT_APP_DEXCOM_OAUTH_URL =
  process.env.REACT_APP_DEXCOM_OAUTH_URL ||
  'https://sandbox-api.dexcom.com/v2/oauth2/login';
