import {fetchApiSet} from '../../api/fetchApi';

const AddParticipantApi = async ({data}) => {
  let url = `/trialParticipants`;
  let res = await fetchApiSet(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({data}),
  });
  return res;
};

export {AddParticipantApi};
