import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {getAppVersion} from '../appVersion/appVersion';

import './Footer.css';

const Footer = () => {
  const [appVersion, setAppVersion] = useState('');

  useEffect(() => {
    const getAppVer = async () => {
      // Get app version from the server
      try {
        const res = await getAppVersion();
        if (res) {
          setAppVersion(res);
        }
      } catch (error) {
        console.error(error);
        // setErrors("Something went wrong! We could not find app version.");
      }
    };

    getAppVer();
  }, []);

  let today = new Date();
  let year = today.getFullYear();
  let footerText = `${year} © All rights reserved, Powered by Onca, Inc.`;
  return (
    <footer className="centered-text">
      <div>{footerText}</div>
      {appVersion && <div>{appVersion}</div>}
      <div className="links-container">
        <div>
          <a
            href="https://cdconnect.net/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="CDConnect Privacy Policy"
          >
            Privacy Policy
          </a>
        </div>
        <div>
          <a
            href="https://cdconnect.net/terms-and-conditions/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="CDConnect Terms and Conditions"
          >
            Terms and Conditions
          </a>
        </div>
        <div>
          <Link to="/ContactUsForm/"> Contact Us</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
