import {fetchApiSet} from '../api/fetchApi';

const setTrialApi = async (data) => {
  let url = `/userTrials`;
  let res = await fetchApiSet(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({data}),
  });
  return res;
};

const updateTrialApi = async (data) => {
  let url = `/userTrials`;
  let res = await fetchApiSet(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({data}),
  });
  return res;
};

const deleteTrialApi = async (data) => {
  let url = `/userTrials`;
  let res = await fetchApiSet(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({data}),
  });
  return res;
};

export {setTrialApi, updateTrialApi, deleteTrialApi};
