import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';

import '../modal/ModalForm.css';
import './TrialUsersForm.css';

function DeleteTrialUsersForm({
  trialUserInfo,
  getTrialUsersDataCallback,
  cancelTrialUsersDeleteCallback,
}) {
  const {userId, userEmail} = trialUserInfo;

  const {
    handleSubmit,
    register,
    reset,
    formState: {errors},
  } = useForm({mode: 'onSubmit'});

  const onSubmit = (data) => {
    // Upon success return data to the parent component
    getTrialUsersDataCallback(data);
  };

  const handleCancelTrialUsersDelete = () => {
    cancelTrialUsersDeleteCallback();
  };

  // Insert current values to form's fields
  useEffect(() => {
    reset({
      userId: userId,
      userEmail: userEmail,
    });
  }, [reset, userId, userEmail]);

  return (
    <form
      className="modal-form trial-users-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset disabled>
        <div className="form-item">
          <div className="form-error" aria-live="polite">
            {errors.userId?.message}
          </div>
          <div>
            <label>User Id</label>
          </div>
          <div>
            <input name="userId" type="text" {...register('userId')} />
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>User Email</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.userEmail?.message}
          </div>
          <div>
            <input name="userEmail" type="text" {...register('userEmail')} />
          </div>
        </div>
      </fieldset>

      <div className="form-group icons-flex-container">
        <button type="submit" className="submit-btn">
          <AiOutlineCheck
            className="submit-icon"
            data-tip
            data-for="submit-delete"
          />
          <ReactTooltip
            id="submit-delete"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Submit
          </ReactTooltip>
        </button>
        <button
          type="button"
          className="cancel-btn"
          onClick={handleCancelTrialUsersDelete}
        >
          <AiOutlineClose
            className="cancel-icon"
            data-tip
            data-for="cancel-delete"
          />
          <ReactTooltip
            id="cancel-delete"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Cancel
          </ReactTooltip>
        </button>
      </div>
    </form>
  );
}

DeleteTrialUsersForm.propTypes = {
  trialUserInfo: PropTypes.object,
  getTrialUsersDataCallback: PropTypes.func,
  cancelTrialUsersDeleteCallback: PropTypes.func,
};

export default DeleteTrialUsersForm;
