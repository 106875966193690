import React from 'react';
import PropTypes from 'prop-types';

import './CustomTooltip.css';

const customSleepToolTip = (payload, chartInfo) => {
  return (
    <div className="custom-tooltip">
      <div className="tooltip-details">
        <p className="tooltip-label">
          Start: {payload[0].payload['start time']}
        </p>
        <p className="tooltip-label">End: {payload[0].payload['end time']}</p>
        <p className="tooltip-label">
          Time Asleep: {payload[0].payload['timeAsleep']}
        </p>
        {/* Iterate over chartInfo and payload and display info if match in names found */}
        {chartInfo.map((info, idx) => {
          return Object.entries(payload[0].payload).map((item, key) => {
            if (info.valueName === item[0]) {
              // In this case we have some additional data that we want to display in tooltip
              let hrsMins = payload[0].payload[`${info.valueName} HourMin`];
              hrsMins = hrsMins ? `(${hrsMins})` : ``;
              let percent = payload[0].payload[`${info.valueName} percent`];
              percent = percent ? `(${percent}%)` : ``;
              return (
                <p key={key} style={{color: chartInfo[idx].valueColor}}>
                  {item[0]}: {item[1]} {chartInfo[idx].units} {hrsMins}{' '}
                  {percent}
                </p>
              );
            }
          });
        })}
      </div>
    </div>
  );
};

const customActiveMinutesToolTip = (payload, chartInfo, label) => {
  return (
    <div className="custom-tooltip">
      <div className="tooltip-details">
        <p className="tooltip-label">{label}</p>
        {/* Iterate over chartInfo and payload and display info if match in names found */}
        {chartInfo.map((info, idx) => {
          return Object.entries(payload[0].payload).map((item, key) => {
            if (info.valueName === item[0]) {
              // In this case we have some additional data that we want to display in tooltip
              let hrsMins = payload[0].payload[`${info.valueName} HourMin`];
              hrsMins = hrsMins ? `(${hrsMins})` : ``;
              let percent = payload[0].payload[`${info.valueName} percent`];
              percent = percent ? `(${percent}%)` : ``;
              return (
                <p key={key} style={{color: chartInfo[idx].valueColor}}>
                  {item[0]}: {item[1]} {chartInfo[idx].units} {hrsMins}{' '}
                  {percent}
                </p>
              );
            }
          });
        })}
      </div>
    </div>
  );
};

const CustomToolTip = ({active, payload, label, chartInfo, subject}) => {
  if (active && payload) {
    if (subject === 'Sleep Duration') {
      return customSleepToolTip(payload, chartInfo);
    } else if (subject === 'Total Tracker Active Minutes') {
      return customActiveMinutesToolTip(payload, chartInfo, label);
    } else return null;
  }

  return null;
};

CustomToolTip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  chartInfo: PropTypes.array,
  label: PropTypes.string,
  subject: PropTypes.string,
};

export default CustomToolTip;
