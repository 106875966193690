import React, {useState, createContext, useContext} from 'react';

// Use context for passing down trial info to children
const TrialInfoContext = createContext(null);

const CurrentTrialInfoProvider = (props) => {
  const [currentTrialInfo, setCurrentTrial] = useState(null);

  const setCurrentTrialInfo = (trialInfo) => {
    // console.log(`setCurrentTrialInfo: ${trialInfo}`);
    setCurrentTrial(trialInfo);
  };

  const getCurrentTrialInfo = () => {
    let trialInfo = currentTrialInfo;
    if (!trialInfo) {
      try {
        // Check if trial info exists in localStorage
        trialInfo = JSON.parse(localStorage.getItem('trialInfo'));
      } catch (error) {
        console.error('Reading Trial Info from localStorage failed');
      }
    }
    return trialInfo;
  };

  const authContextValue = {
    setCurrentTrialInfo,
    getCurrentTrialInfo,
    currentTrialInfo,
  };

  return <TrialInfoContext.Provider value={authContextValue} {...props} />;
};

// Create a custom hook
const useTrialInfo = () => useContext(TrialInfoContext);

export {CurrentTrialInfoProvider, useTrialInfo};
