import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';

import {
  GetParticipantDataFromFitbit,
  GetParticipantFitbitDataLocalDB,
} from '../participant/FitbitParticipantData';
import GraphicDisplayTile from '../../tiles/GraphicDisplayTile';
import DisplayDataChartTable from '../../displayData/DisplayDataChartTable';
import {
  prepareDateValueData,
  prepareSleepDurationData,
  prepareRestingHeartRateData,
} from '../displayData/FitbitPrepareDataForDisplay';
import SwapItems from '../../tiles/SwapItems';
import {exportMultipleTablesToCSV} from '../../table/ExportFileBlob';

import './DisplayParticipantOverviewData.css';

const DisplayParticipantOverviewData = (props) => {
  const participant = props.participant;
  const [participantData, setParticipantData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  // Return string indicating the diff between last 7 days to prev. 7 days
  const diffResults7Days = (subject, firstVal, secondVal) => {
    if (subject == null || firstVal == null || secondVal == null) {
      return '';
    }
    let diff = 0;
    if (subject === 'Distance') {
      firstVal = firstVal * 5280;
      secondVal = secondVal * 5280;
      diff = firstVal - secondVal;
      diff = diff / 5280;
      diff = diff.toFixed(2);
    } else {
      diff = firstVal - secondVal;
    }
    let diffLastVSPrev = '';
    let trend = '';
    if (diff === 0) {
      diffLastVSPrev = 'same as prev. 7 days';
      trend = 'same';
    } else if (diff < 0) {
      diffLastVSPrev = `${diff * -1} below prev. 7 days`;
      trend = 'below';
    } else {
      diffLastVSPrev = `${diff} over prev. 7 days`;
      trend = 'over';
    }
    return (diffLastVSPrev = {text: diffLastVSPrev, trend: trend});
  };

  // Gather participant's data from the server and prepare it for display
  useEffect(() => {
    const GetParticipantData = async (participant) => {
      let results = [];
      let result = null;
      // Display results of the last 7 days
      let endDate = DateTime.now();
      endDate = endDate.toISODate();
      let startDate = DateTime.now();
      startDate = startDate.minus({days: 6}).toISODate();

      const queries = [
        {
          subject: 'Steps',
          query: `/activities/steps/date/${startDate}/${endDate}.json`,
          chart: 'LineChart',
        },
        {
          subject: 'Distance',
          query: `/activities/distance/date/${startDate}/${endDate}.json`,
          chart: 'BarChart',
        },
        {
          subject: 'Floors',
          query: `/activities/floors/date/${startDate}/${endDate}.json`,
          chart: 'BarChart',
        },
        {
          subject: 'Resting Heart Rate',
          query: `/activities/heart/date/${startDate}/${endDate}.json`,
          chart: 'LineChart',
        },
        {
          subject: 'Sleep Duration',
          query: `/sleep/date/${startDate}/${endDate}.json`,
          chart: 'ComposedBarLineChart',
        },
        {
          subject: 'Weight',
          query: `/body/weight/date/${startDate}/${endDate}.json`,
          chart: 'LineChart',
        },
      ];

      try {
        setLoading(true);
        // Get participant's Fitbit data from local DB.
        // This is some statistics that we save in our database
        const localDBRes = await GetParticipantFitbitDataLocalDB(participant);
        // Get all the data related to the selected queries from Fitbit server
        // Then prepare and send to display
        results = await Promise.all(
          // Get data of all given queries from Fitbit
          Object.values(queries).map(async (item, idx) => {
            result = await GetParticipantDataFromFitbit(
              participant,
              item.query
            );
            if (result.errors) {
              // In this case add only the subject,
              // then the subject and the error message will be displayed
              result.subject = item.subject;
            } else {
              // Add other info that we need to display with the data
              // and arrange the results the way we want to display them
              result.idx = idx;
              result.subject = item.subject;
              result.chartType = item.chart;
              let prevPeriodDiffRes = null;
              let last7Days = null;
              let prev7Days = null;
              if (result.subject === 'Resting Heart Rate' && result.data) {
                if (
                  localDBRes &&
                  localDBRes.data &&
                  localDBRes.data.avrg_resting_heart_rate_last_7days
                ) {
                  if (
                    localDBRes.data.avrg_resting_heart_rate_prev_7days != null
                  ) {
                    prevPeriodDiffRes = diffResults7Days(
                      result.subject,
                      localDBRes.data.avrg_resting_heart_rate_last_7days,
                      localDBRes.data.avrg_resting_heart_rate_prev_7days
                    );
                    result.last7daysInfo = {
                      text: `Avg. last 7 days: ${`${localDBRes.data.avrg_resting_heart_rate_last_7days}`} bpm ${
                        prevPeriodDiffRes.text
                          ? `(${prevPeriodDiffRes.text})`
                          : ''
                      }`,
                      trend: `${
                        prevPeriodDiffRes.trend ? prevPeriodDiffRes.trend : ''
                      }`,
                    };
                  }
                }
                result = prepareRestingHeartRateData(result);
              } else if (result.subject === 'Sleep Duration' && result.data) {
                if (
                  localDBRes &&
                  localDBRes.data &&
                  localDBRes.data.avrg_sleep_duration_last_7days
                ) {
                  if (localDBRes.data.avrg_sleep_duration_prev_7days != null) {
                    prevPeriodDiffRes = diffResults7Days(
                      result.subject,
                      localDBRes.data.avrg_sleep_duration_last_7days,
                      localDBRes.data.avrg_sleep_duration_prev_7days
                    );
                    result.last7daysInfo = {
                      text: `Avg. last 7 days: ${`${localDBRes.data.avrg_sleep_duration_last_7days} min.`}  ${
                        prevPeriodDiffRes.text
                          ? `(${prevPeriodDiffRes.text})`
                          : ''
                      }`,
                      trend: `${
                        prevPeriodDiffRes.trend ? prevPeriodDiffRes.trend : ''
                      }`,
                    };
                  }
                }
                result = prepareSleepDurationData(result, 'overview');
              } else if (result.data) {
                if (result.subject === 'Steps') {
                  if (
                    localDBRes &&
                    localDBRes.data &&
                    localDBRes.data.total_steps_last_7days
                  ) {
                    if (localDBRes.data.total_steps_prev_7days != null) {
                      prevPeriodDiffRes = diffResults7Days(
                        result.subject,
                        localDBRes.data.total_steps_last_7days,
                        localDBRes.data.total_steps_prev_7days
                      );
                      result.last7daysInfo = {
                        text: `Total last 7 days: ${`${localDBRes.data.total_steps_last_7days}`}  ${
                          prevPeriodDiffRes.text
                            ? `(${prevPeriodDiffRes.text})`
                            : ''
                        }`,
                        trend: `${
                          prevPeriodDiffRes.trend ? prevPeriodDiffRes.trend : ''
                        }`,
                      };
                    }
                  }
                }
                if (result.subject === 'Distance') {
                  if (
                    localDBRes &&
                    localDBRes.data &&
                    localDBRes.data.total_distance_last_7days
                  ) {
                    if (localDBRes.data.total_distance_prev_7days != null) {
                      last7Days = localDBRes.data.total_distance_last_7days;
                      prev7Days = localDBRes.data.total_distance_prev_7days;
                      prevPeriodDiffRes = diffResults7Days(
                        result.subject,
                        last7Days,
                        prev7Days
                      );
                      last7Days = last7Days.toFixed(2);
                      result.last7daysInfo = {
                        text: `Total last 7 days: ${`${last7Days}`} Miles ${
                          prevPeriodDiffRes.text
                            ? `(${prevPeriodDiffRes.text})`
                            : ''
                        }`,
                        trend: `${
                          prevPeriodDiffRes.trend ? prevPeriodDiffRes.trend : ''
                        }`,
                      };
                    }
                  }
                }
                if (result.subject === 'Weight') {
                  if (
                    localDBRes &&
                    localDBRes.data &&
                    localDBRes.data.avrg_weight_last_7days
                  ) {
                    if (localDBRes.data.avrg_weight_prev_7days != null) {
                      prevPeriodDiffRes = diffResults7Days(
                        result.subject,
                        localDBRes.data.avrg_weight_last_7days,
                        localDBRes.data.avrg_weight_prev_7days
                      );
                      result.last7daysInfo = {
                        text: `Avg. last 7 days: ${`${localDBRes.data.avrg_weight_last_7days}`} Lbs ${
                          prevPeriodDiffRes.text
                            ? `(${prevPeriodDiffRes.text})`
                            : ''
                        }`,
                        trend: `${
                          prevPeriodDiffRes.trend ? prevPeriodDiffRes.trend : ''
                        }`,
                      };
                    }
                  }
                }
                if (result.subject === 'Floors') {
                  if (
                    localDBRes &&
                    localDBRes.data &&
                    localDBRes.data.total_floors_last_7days
                  ) {
                    if (localDBRes.data.total_floors_prev_7days != null) {
                      last7Days = localDBRes.data.total_floors_last_7days;
                      prev7Days = localDBRes.data.total_floors_prev_7days;
                      prevPeriodDiffRes = diffResults7Days(
                        result.subject,
                        last7Days,
                        prev7Days
                      );
                      result.last7daysInfo = {
                        text: `Total last 7 days: ${`${last7Days}`}  ${
                          prevPeriodDiffRes.text
                            ? `(${prevPeriodDiffRes.text})`
                            : ''
                        }`,
                        trend: `${
                          prevPeriodDiffRes.trend ? prevPeriodDiffRes.trend : ''
                        }`,
                      };
                    }
                  }
                }
                result = prepareDateValueData(result);
              } else {
                throw new Error('Error: could not process results from Fitbit');
              }
            }
            return result;
          })
        );
        setParticipantData(results);
      } catch (error) {
        console.error(`Error: can't display participant data from Fitbit`);
      } finally {
        setLoading(false);
      }
    };

    if (participant) {
      GetParticipantData(participant);
    }
  }, [participant]);

  const swapTiles = (fromTile, toTile) => {
    let tiles = [...participantData];
    // Get current index of both tiles. Return -1 in case of null or undefined
    let fromIndex = tiles.findIndex((tile) => tile.idx === fromTile.idx) ?? -1;
    let toIndex = tiles.findIndex((tile) => tile.idx === toTile.idx) ?? -1;

    // Swap the tiles
    if (fromIndex != -1 && toIndex != -1) {
      let temp = tiles[fromIndex];
      tiles[fromIndex] = {...tiles[toIndex], idx: tiles[fromIndex].idx};
      tiles[toIndex] = {...temp, idx: tiles[toIndex].idx};
      // Update the state
      setParticipantData(tiles);
    }
  };

  // Check if all activities are selected
  useEffect(() => {
    if (participantData != null && participantData.length > 0) {
      const allSubjects = participantData.map((activity) => activity.subject);
      setAllSelected(allSubjects.length === selectedActivities.length);
    }
  }, [selectedActivities, participantData]);

  const toggleSelectAllTiles = () => {
    if (allSelected) {
      setSelectedActivities([]);
    } else {
      const allSubjects = participantData.map((activity) => activity.subject);
      setSelectedActivities(allSubjects);
    }
    setAllSelected(!allSelected);
  };

  const handleCheckboxChange = (subject) => {
    setSelectedActivities((prevSelected) => {
      if (prevSelected.includes(subject)) {
        return prevSelected.filter((item) => item !== subject);
      } else {
        return [...prevSelected, subject];
      }
    });
  };

  const formatDataForExport = (activities, selectedSubjects) => {
    let formattedData = [];

    activities.forEach((activity) => {
      const {data, columns, subject} = activity;
      if (selectedSubjects.includes(subject)) {
        const headers = columns.map((col) => col.Header);
        const rows = data.map((row) => columns.map((col) => row[col.accessor]));

        // Add the subject as a section header
        formattedData.push([subject]);
        // Add headers
        formattedData.push(headers);
        // Add rows
        formattedData.push(...rows);
        // Add an empty row for spacing
        formattedData.push([]);
      }
    });

    return formattedData;
  };

  const handleExportDataCSV = () => {
    const formattedData = formatDataForExport(
      participantData,
      selectedActivities
    );
    exportMultipleTablesToCSV(
      formattedData,
      'CDConnect-Fitbit-Overview-Data.csv'
    );
  };

  return (
    <div className="overview-data">
      {loading ? <div className="spinnerModal"></div> : ''}
      <div className="button-container">
        <button type="button" className="btn" onClick={toggleSelectAllTiles}>
          {allSelected ? 'Deselect All Activities' : 'Select All Activities'}
        </button>
        {selectedActivities.length > 0 && (
          <button type="button" className="btn" onClick={handleExportDataCSV}>
            Export Selected Data
          </button>
        )}
      </div>
      <div className="flex-container">
        {participantData &&
          participantData.length > 0 &&
          Object.values(participantData).map((item, index) => (
            <div key={index} className="draggable-tile flex-item">
              <SwapItems
                id={index}
                draggable="true"
                item={item}
                swapItemsCallback={swapTiles}
              >
                <GraphicDisplayTile
                  info={item}
                  className=""
                  isSelected={selectedActivities.includes(item.subject)}
                  onSelect={() => handleCheckboxChange(item.subject)}
                >
                  <DisplayDataChartTable
                    deviceName={'Fitbit'}
                    subject={item.subject}
                    content={item}
                  />
                </GraphicDisplayTile>
              </SwapItems>
            </div>
          ))}
      </div>
    </div>
  );
};

DisplayParticipantOverviewData.propTypes = {
  participant: PropTypes.object,
};

export default DisplayParticipantOverviewData;
