import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const LineChartDraw = ({content}) => {
  const {data, chartInfo, xAxisKey} = content;

  return (
    <ResponsiveContainer width="98%" height={300} debounce={1}>
      <LineChart
        // width={730}
        // height={250}
        data={data}
        margin={{top: 5, right: 5, left: 5, bottom: 5}}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {/* <XAxis dataKey={xAxisKey} /> */}
        {xAxisKey.map((item, key) => {
          return (
            <XAxis
              key={key}
              xAxisId={key}
              dataKey={item}
              fontSize={12}
              dy={5} /*distance between label text and axis*/
              // interval={0}
            />
          );
        })}
        <YAxis type="number" fontSize={12} />
        <Tooltip cursor={false} />
        <Legend />
        {chartInfo.map((item, key) => {
          return (
            <Line
              key={key}
              type="monotone"
              dataKey={item.valueName}
              stroke={item.valueColor}
              unit={item.units}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

LineChartDraw.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default LineChartDraw;
