import React, {useState, useEffect, useCallback} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';

import {normalizeResponseErrors} from '../../errorHandling/NormalizeErrors';
import DexcomParticipantConsentForm from './DexcomParticipantConsentForm';
import {useTrialInfo} from '../../trials/TrialInfoContext';

const DexcomCallback = () => {
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);
  const [authCode, setAuthCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [regError, setRegError] = useState('');
  const {getCurrentTrialInfo} = useTrialInfo();

  // Exchange the authorization code we've just received for an access and refresh token
  const getToken = useCallback(
    async (participantData) => {
      try {
        setLoading(true);
        let res = await fetch(`/dexcom-callback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({authCode, participantData}),
        });
        // console.log(res);
        res = await normalizeResponseErrors(res);
        if (res.status === 200) {
          setAuthorized(true);
        } else {
          throw new Error('Error: Could not get tokens from Dexcom server!');
        }
      } catch (error) {
        console.error('Error', error);
        setRegError('Authorization failed!');
      } finally {
        setLoading(false);
      }
    },
    [authCode]
  );

  useEffect(() => {
    // Extract the code we got back from Dexcom from the url
    // console.log(`URL:${window.location.href}`);
    const current_url = window.location.href;
    if (current_url.includes('code')) {
      // Get the search part of the url
      const searchStr = window.location.search;
      // Parse the search string parameters
      const searchParams = new URLSearchParams(searchStr);
      // Extract the auth code from the parameters
      const authCode = searchParams.get('code');
      setAuthCode(authCode);
    } else if (current_url.includes('error')) {
      // Get the search part of the url
      let searchStr = window.location.search;
      // Parse the search string parameters
      const searchParams = new URLSearchParams(searchStr);
      // Extract the error message
      const error = searchParams.get('code');
      // TODO: decide how to display this error
      console.error(`Error: ${error}`);
    } else {
      console.error(`Error: No Code`);
    }
  }, []);

  if (authorized) {
    // Get trial info
    let currentTrialInfo = null;
    try {
      currentTrialInfo = getCurrentTrialInfo();
    } catch (error) {
      console.error(
        'Dexcom Callback - Reading Trial Info from localStorage failed'
      );
    }
    if (currentTrialInfo) {
      navigate('/TrialPage', {
        state: currentTrialInfo,
      });
    } else {
      return <Navigate to="/" />;
    }
  }

  return (
    <div>
      <h1>Dexcom Callback Page</h1>
      <div className="error-message centered-text">{regError}</div>
      {loading ? <div className="spinnerModal"></div> : ''}
      <DexcomParticipantConsentForm participantDataCallback={getToken} />
    </div>
  );
};

export default DexcomCallback;
