// We are using the oauth2 Authorization Code Grant Flow to obtain consent from Omron
// For more information refer to: https://omronhealthcare-ohi.atlassian.net/wiki/spaces/API/pages/2480308225/Omron+Partner+API+for+US
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {fetchApiGet} from '../../api/fetchApi';
import {REACT_APP_OMRON_OAUTH_URL} from '../../../config';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';

const OmronConsentPage = ({participant}) => {
  const [errors, setErrors] = useState(null);

  // Send the authorization request to Omron
  const omronAuthorization = async () => {
    var auth_url = new URL(REACT_APP_OMRON_OAUTH_URL);
    var search_params = auth_url.searchParams;

    let clientId = '';
    try {
      const res = await fetchApiGet('/omronClientId');
      if (res) {
        clientId = res.OMRON_CLIENT_ID;
      } else {
        throw new Error(`Error: missing parameter, can't authorize!`);
      }

      // Append parameters to 'auth_url'
      search_params.append('client_id', clientId);
      search_params.append('response_type', 'code');
      search_params.append(
        'redirect_uri',
        process.env.REACT_APP_OMRON_OAUTH_CALLBACK_URL
      );
      search_params.append(
        'scope',
        'bloodpressure activity weight openid offline_access'
      );
      search_params.append('state', participant.id);

      // Add the search properties to the url
      auth_url.search = decodeURIComponent(search_params);
      window.location.href = auth_url;
    } catch (error) {
      console.error(error);
      if (error && error.displayToUser && error.message) {
        setErrors(`Error:  ${error.message}!`);
      } else {
        setErrors('Something went wrong! We could not revoke the token.');
      }
    }
  };

  return (
    <div>
      <h3>Consent!</h3>
      {errors && <DisplayErrors errors={errors} />}
      <button type="button" className="btn" onClick={omronAuthorization}>
        Authorize Access
      </button>
      {/* <button type="button" className="btn" onClick={''}>
        Send Auth Request
      </button> */}
    </div>
  );
};

OmronConsentPage.propTypes = {
  participant: PropTypes.object,
};

export default OmronConsentPage;
