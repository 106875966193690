import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {FitbitApi} from '../api/FitbitApi';
import FitbitParticipantDataForm from './FitbitParticipantDataForm';
import {FitbitQueryBuilder} from '../queryBuilder/FitbitQueryBuilder';
import {DisplayDataNestedObject} from '../../displayData/DisplayNestedData';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';
import './FitbitParticipant.css';

const FitbitParticipantDataRaw = (props) => {
  const id = props.id;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');

  // TODO: add error display to the user
  // Get participant's data from Fitbit based on the specific information in the query's string
  const FitbitGetParticipantData = async (queryStr) => {
    try {
      setLoading(true);
      // Email is not needed in this case
      let res = await FitbitApi.GetParticipantDataApi(id, null, queryStr);
      if (res.errors) {
        throw new Error(res.errors);
      }
      // If everything is ok set the data state to be able to display it
      setData(res.data);
    } catch (error) {
      console.error(error.message);
      // TODO: decide what kind of errors to display here
      if (error) {
        setErrors(error.message);
      } else {
        setErrors('Error5: getting data from Fitbit server failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  const HandleGetDataRequest = (data) => {
    let queryStr = '';
    try {
      // Clear results and errors from previous query
      setData('');
      setErrors('');
      // console.log(data);
      // Build a query based on the form's data
      const res = FitbitQueryBuilder(data);
      if (res) {
        if (res.error) {
          console.log(res.error);
          setErrors(res.error);
        } else if (res.data) {
          queryStr = res.data;
          // Get the actual data from Fitbit
          FitbitGetParticipantData(queryStr);
        } else {
          setErrors('Error: Fitbit data request - some parameters are missing');
        }
      } else {
        setErrors('Error: Fitbit request - bad data request');
      }
    } catch (error) {
      setErrors('Error: Fitbit request failed!');
    }
  };

  const handleGetDataFromDB = async () => {
    try {
      let res = await FitbitApi.GetFitbitDataLocalDBApi(id);
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {loading ? <div className="spinnerModal"></div> : ''}
      <h2>Participant Raw Data Fitbit</h2>
      {errors && <DisplayErrors errors={errors} />}
      <button className="btn" onClick={handleGetDataFromDB}>
        Get data from DB
      </button>
      <FitbitParticipantDataForm
        getDataCallback={HandleGetDataRequest}
        displayDataType={'rawData'}
      />
      {data ? DisplayDataNestedObject(data) : <></>}
    </div>
  );
};

FitbitParticipantDataRaw.propTypes = {
  id: PropTypes.string,
};

export default FitbitParticipantDataRaw;
