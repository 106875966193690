import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import ReactTooltip from 'react-tooltip';
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';

import {useYupValidationResolver} from '../forms/YupValidationResolver';

import '../modal/ModalForm.css';
import './EditParticipantForm.css';

// Yup form validation rules
const validationSchema = yup.object({
  age: yup
    .string()
    .notRequired()
    .test('greaterThanZero', 'Value must be greater than zero', (value) =>
      value ? value > 0 : ' '
    )
    .max(3, 'Age cannot be more than 3 digits')
    .matches(/^[0-9]+$|^$/, 'Age must be only digits'), // Age can also be empty!
});

const EditParticipantForm = ({
  participant,
  editParticipantCallback,
  cancelEditCallback,
}) => {
  const {id, email, gender, age, notes, auth_devices} = participant;
  const resolver = useYupValidationResolver(validationSchema);
  const [messageCharCount, setMessageCharCount] = useState(0);

  const handleTextChange = (evt) => {
    evt.preventDefault();
    setMessageCharCount(evt.target.value.length);
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = (data) => {
    // Upon success return data to the parent component
    editParticipantCallback(data);
  };

  const handleCancelEdit = () => {
    cancelEditCallback();
  };

  // Insert current values to form's fields
  useEffect(() => {
    reset({
      id: id,
      email: email,
      gender: gender ? gender : '',
      age: age ? age : '',
      notes: notes ? notes : '',
      auth_devices: auth_devices ? auth_devices : '',
    });
  }, [reset, id, email, gender, age, notes, auth_devices]);

  // Display the current number of chars after the form is loaded
  useEffect(() => {
    setMessageCharCount(notes.length);
  }, [notes]);

  return (
    <form
      className="modal-form edit-participant-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset>
        <div className="form-item">
          <div>
            <label>Participant ID</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.id?.message}
          </div>
          <div>
            <input disabled name="id" type="text" {...register('id')} />
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>Email</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.email?.message}
          </div>
          <div>
            <input disabled name="email" type="text" {...register('email')} />
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>Gender</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.gender?.message}
          </div>
          <div>
            <select
              className="select-menu"
              name="gender"
              type="text"
              {...register('gender')}
            >
              <option value="">None</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>Age</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.age?.message}
          </div>
          <div>
            <input name="age" type="number" {...register('age')} />
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>Notes</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.notes?.message}
          </div>
          <div>
            <textarea
              name="notes"
              type="input"
              {...register('notes')}
              onChange={(evt) => handleTextChange(evt)}
              maxLength={300}
              className={`form-input ${errors.notes ? 'is-invalid' : ''}`}
            />
            <p>{messageCharCount}/300</p>
          </div>
        </div>
      </fieldset>

      <div className="form-group">
        <button type="submit" className="submit-btn">
          <AiOutlineCheck
            className="submit-icon"
            data-tip
            data-for="submit-edit"
          />
          <ReactTooltip
            id="submit-edit"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Submit
          </ReactTooltip>
        </button>
        <button type="button" className="cancel-btn" onClick={handleCancelEdit}>
          <AiOutlineClose
            className="cancel-icon"
            data-tip
            data-for="cancel-edit"
          />
          <ReactTooltip
            id="cancel-edit"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Cancel
          </ReactTooltip>
        </button>
      </div>
    </form>
  );
};

EditParticipantForm.propTypes = {
  participant: PropTypes.object,
  editParticipantCallback: PropTypes.func,
  cancelEditCallback: PropTypes.func,
};

export default EditParticipantForm;
