import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import SignUpApprovalForm from './SignupApprovalForm';
import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';

const SignUpApprovalPage = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const handleSubmitSignUpApproval = async (data) => {
    try {
      setLoading(true);
      setErrors(null);
      setSuccessMsg(null);
      let url = `/userSignUpPermission/approveSignUp`;
      let res = await fetchApiSet(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data.email,
          role: data.role,
          teamId: data.teamId,
          teamName: data.teamName,
        }),
      });
      if (res.errors) {
        console.error(res.errors);
        throw new Error(`Error: sign-up approval unsuccessful`);
      } else {
        // If everything is ok, display a message to the user
        setSuccessMsg('Sending sign-up approval to the user was successful.');
      }
    } catch (error) {
      console.error(error);
      if (error && error.displayToUser && error.message) {
        setErrors(error.message);
      } else {
        setErrors('Error: sign-up approval failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancelBtnClicked = () => {
    navigate('/');
  };

  return (
    <>
      {loading ? <div className="spinnerModal"></div> : ''}
      <div className="centered-text">
        <h2>Sign-Up Approval</h2>
        {errors && <DisplayErrors errors={errors} />}
      </div>
      {!errors && successMsg ? (
        <div className="centered-text">{successMsg}</div>
      ) : (
        <SignUpApprovalForm
          getDataCallback={handleSubmitSignUpApproval}
          cancelBtnClickedCallback={handleCancelBtnClicked}
        />
      )}
    </>
  );
};

export default SignUpApprovalPage;
