import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import ParticipantSelectDeviceForm from '../participant/ParticipantSelectDeviceForm';
import {getLocationState} from '../../services/URL/urlLocation';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import TrialInfo from '../trials/TrialInfo';

import './ParticipantsDataPage.css';

// Display data of multiple participants side by side
const ParticipantsDataPage = () => {
  const [participants, setParticipants] = useState('');
  const [errors, setErrors] = useState('');
  const location = useLocation();
  let navigate = useNavigate();

  // Get participants' info from location
  useEffect(() => {
    try {
      const res = getLocationState(location, 'multipleParticipantsData');
      if (res.data) {
        setParticipants(res.data);
      }
    } catch (error) {
      console.error(error);
      setErrors('Something went wrong! We could not find participants info.');
    }
  }, [location]);

  const getDataSelectedDevice = (res) => {
    switch (res.device) {
      case 'fitbit':
        return navigate('/FitbitParticipantsDataPage', {state: {participants}});
      case 'omron':
        return navigate('/OmronParticipantsDataPage', {state: {participants}});
    }
  };

  return (
    <div className="participants-page">
      <div>
        <TrialInfo />
        <h2>Participants Data</h2>
        <div>
          <ParticipantSelectDeviceForm
            selectionCallback={getDataSelectedDevice}
          />
          <div className={''}>
            {errors && <DisplayErrors errors={errors} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantsDataPage;
