import React from 'react';
import PropTypes from 'prop-types';

import Table from '../table/Table';

const DisplayDateValueTable = (props) => {
  const {deviceName = null, tableName = null} = props;
  const {data, columns} = props.content;

  return (
    <>
      {data && data.length > 0 && (
        <div>
          <Table
            deviceName={deviceName}
            tableName={tableName}
            data={data}
            columns={columns}
            features={{
              singleRowSelection: true,
              multipleRowsSelection: false,
              maxNumOfSelectedRows: null,
              displayGlobalFilter: false,
              exportDataToFile: true,
            }}
          />
        </div>
      )}
    </>
  );
};

DisplayDateValueTable.propTypes = {
  deviceName: PropTypes.string,
  tableName: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export {DisplayDateValueTable};
