import React from 'react';
import PropTypes from 'prop-types';
import {useFormContext} from 'react-hook-form';

const DropDownList = ({list, title, name}) => {
  const {register} = useFormContext();
  return (
    <div>
      <h4>{title}</h4>
      <label htmlFor={name}></label>
      <select {...register(name)} id={title} name={name}>
        {list.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

DropDownList.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  name: PropTypes.string,
};

DropDownList.displayName = 'DropDownList';

export default DropDownList;
