import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import FitbitParticipantOverview from '../fitbit/participant/FitbitParticipantOverview';
import OmronParticipantOverview from '../omron/participant/OmronParticipantOverview';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {getLocationState} from '../../services/URL/urlLocation';
import TrialInfo from '../trials/TrialInfo';

import './ParticipantOverview.css';

const ParticipantOverview = () => {
  const [errors, setErrors] = useState('');
  const [participant, setParticipant] = useState('');
  const location = useLocation();

  // Get participant's info from location
  useEffect(() => {
    try {
      const res = getLocationState(location, 'participant');
      if (res.data) {
        setParticipant(res.data.participant);
      }
    } catch (error) {
      console.error(error);
      setErrors("Something went wrong! We could not find participant's info.");
    }
  }, [location]);

  const displayParticipantOverviewData = (participant) => {
    let idx = 0;
    return (
      <div>
        <div>ID: {participant['id']}</div>
        <div>Email: {participant['email']}</div>
        {participant.auth_devices &&
          participant.auth_devices.map((item) => {
            if (item.device === 'Fitbit') {
              return (
                <FitbitParticipantOverview
                  participant={participant}
                  key={idx++}
                />
              );
            } else if (item.device === 'Omron') {
              return (
                <OmronParticipantOverview
                  participant={participant}
                  key={idx++}
                />
              );
            }
          })}
      </div>
    );
  };

  return (
    <div className="ParticipantOverviewContainer">
      <TrialInfo />
      <h2>Participant Overview</h2>
      {/* {loading ? <div className='spinnerModal'></div> : ''} */}
      {participant && displayParticipantOverviewData(participant)}
      {errors && <DisplayErrors errors={errors} />}
    </div>
  );
};

export default ParticipantOverview;
