import React, {useState} from 'react';
import {IoIosArrowUp} from 'react-icons/io';
import {IoIosArrowDown} from 'react-icons/io';
import PropTypes from 'prop-types';
import {TiArrowSortedDown, TiArrowSortedUp} from 'react-icons/ti';
import {FaEquals} from 'react-icons/fa';

import {DisplayDateValueChart} from './DisplayDateValueChart';
import {DisplayDateValueTable} from './DisplayDateValueTable';

const DisplayDataChartTable = ({
  deviceName = null,
  subject = null,
  content,
}) => {
  const [displayTable, setDisplayTable] = useState(false);

  const showMoreClicked = () => {
    setDisplayTable(!displayTable);
  };

  const DisplayStatistics = ({info}) => {
    return (
      <div className="statistics-flex-container">
        {info.trend === 'same' ? (
          <FaEquals
            className="trend-icon statistics-flex-item"
            id="trend-same-icon"
          />
        ) : info.trend === 'below' ? (
          <TiArrowSortedDown
            className="trend-icon statistics-flex-item"
            id="trend-below-icon"
          />
        ) : info.trend === 'over' ? (
          <TiArrowSortedUp
            className="trend-icon statistics-flex-item"
            id="trend-over-icon"
          />
        ) : (
          ''
        )}
        <p className="statistics-info statistics-flex-item">{info.text}</p>
      </div>
    );
  };

  const displayData = () => {
    let idx = 0;
    try {
      if (content) {
        return (
          <div key={content.subject}>
            {content.data && (
              <div>
                <div>
                  <DisplayDateValueChart
                    key={idx++}
                    content={content}
                    displayTable={false}
                    chartType={content.chartType}
                  />
                  <hr />
                  <div className="statistics-info">
                    {content.last7daysInfo && (
                      <DisplayStatistics info={content.last7daysInfo} />
                    )}
                  </div>
                  <div>
                    <button onClick={showMoreClicked} className="show-more-btn">
                      {displayTable ? (
                        <IoIosArrowUp className="show-less" />
                      ) : (
                        <IoIosArrowDown className="show-more" />
                      )}
                      {displayTable ? 'Show Less' : 'Show More'}
                    </button>
                  </div>
                </div>
                <div>
                  {displayTable && (
                    <DisplayDateValueTable
                      deviceName={deviceName}
                      tableName={subject}
                      key={idx++}
                      content={content}
                      displayTable={false}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  return <>{content && displayData(content)}</>;
};

DisplayDataChartTable.propTypes = {
  deviceName: PropTypes.string,
  subject: PropTypes.string,
  content: PropTypes.object,
  info: PropTypes.object,
};

export default DisplayDataChartTable;
