import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import './FitbitParticipantOverview.css';

import DisplayParticipantOverviewData from '../displayData/DisplayParticipantOverviewData';

const FitbitParticipantOverview = ({participant, idx}) => {
  return (
    <>
      <hr />
      <div className="title-flex-container">
        <h2 className="title">Fitbit</h2>
        <Link
          className="link-text"
          to="/FitbitParticipantDataPage"
          state={{participant: participant}}
        >
          More Fitbit Data
        </Link>
      </div>
      <DisplayParticipantOverviewData participant={participant} key={idx} />
    </>
  );
};

FitbitParticipantOverview.propTypes = {
  participant: PropTypes.object,
  idx: PropTypes.number,
};

export default FitbitParticipantOverview;
