import React, {useState} from 'react';
import {normalizeResponseErrors} from '../../errorHandling/NormalizeErrors';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';
import {useTrialInfo} from '../../trials/TrialInfoContext';
import SimpleYesNoConfirmForm from '../../common/SimpleYesNoConfirmForm';
// import {OmronApi} from '../api/OmronApi';

const OmronManageParticipantToken = ({participant}) => {
  const {id: participantId, email: participantEmail} = participant;
  let navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [message, setMsg] = useState(null);
  const {getCurrentTrialInfo} = useTrialInfo();
  const [displayRevokeMassage, setDisplayRevokeMassage] = useState(false);
  // const [bloodPressure, setBloodPressure] = useState([]);

  // TODO: add refresh token code when token is expired
  // TODO: this function needs code optimization - move some code to make it more generic
  // TODO: add error display to the user
  // Get participant's data based on the specific information in the query's string
  // const OmronGetParticipantData = async () => {
  //   try {
  //     let res = await OmronApi.GetParticipantDataApi(id);
  //     res = await normalizeResponseErrors(res);
  //     let result = await res.json();
  //     const bloodPressure = result['bloodPressure'];
  //     if (bloodPressure) {
  //       console.log(`data: ${bloodPressure}`);
  //       setBloodPressure(bloodPressure);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const RefreshToken = async () => {
    try {
      // Clear messages
      setErrors(null);
      setMsg(null);
      let res = await fetch(`/omronRefreshToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: participantId,
        }),
      });

      res = await normalizeResponseErrors(res);
      const data = await res.json();
      // console.log(data);
      if (data.code == 200) {
        setMsg(`Refresh was successful!`);
      }
    } catch (error) {
      console.error(error);
      if (error && error.displayToUser && error.message) {
        setErrors(`Error:  ${error.message}!`);
      } else {
        setErrors(
          'Something went wrong! We could not refresh the access token.'
        );
      }
    }
  };

  const handleRevokeAnswer = async (answer) => {
    setDisplayRevokeMassage(false);
    // Close the message modal
    if (answer === true) {
      try {
        let res = await fetch(`/omronRevokeToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: participantId,
          }),
        });
        res = await normalizeResponseErrors(res);
        const data = await res.json();
        if (data.code === 200) {
          console.log(`Revoke access token OK`);
          // TODO: instead of going back to current trail page
          // stay on the current page and disable related buttons
          const trialInfo = getCurrentTrialInfo();
          navigate('/TrialPage', {
            state: trialInfo,
          });
        } else {
          throw new Error(
            'Something went wrong! We could not revoke the access token.'
          );
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setErrors(`Error:  ${error.message}!`);
        } else {
          setErrors(
            'Something went wrong! We could not revoke the access token.'
          );
        }
      }
    }
  };

  const handleCancelRevoke = () => {
    setDisplayRevokeMassage(false);
  };

  const RevokeToken = async () => {
    // Clear messages
    setErrors(null);
    setMsg(null);
    setDisplayRevokeMassage(true);
  };

  return (
    <div>
      {/* <button type='button' className='btn' onClick={OmronGetParticipantData}>
        Get Data
      </button> */}
      <h3>Access Permission!</h3>
      {message && <div className="success-message">{message}</div>}
      {errors && <DisplayErrors errors={errors} />}
      {displayRevokeMassage && (
        <SimpleYesNoConfirmForm
          title={`Revoke Omron access of: ${participantEmail}?`}
          text={`If you select Submit, you will need to request the participant's authorization again in order to view the data.`}
          returnAnswerCallback={handleRevokeAnswer}
          cancelCallback={handleCancelRevoke}
        />
      )}
      <button type="button" className="btn" onClick={RefreshToken}>
        Refresh Access
      </button>
      <button type="button" className="btn" onClick={RevokeToken}>
        Revoke Access
      </button>
      {/* {bloodPressure.length > 0 && DisplayBloodPressure(bloodPressure)} */}
    </div>
  );
};

OmronManageParticipantToken.propTypes = {
  participant: PropTypes.object,
};

export default OmronManageParticipantToken;
