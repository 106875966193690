import React from 'react';

import useApi from '../api/useApi';
import Trials from '../trials/Trials';

import './Dashboard.css';

const Dashboard = () => {
  // Get current user
  const {data: user} = useApi(`/auth/login/`);
  // Remove info about current trial from localStorage and sessionStorage
  localStorage.removeItem('trialInfo');
  sessionStorage.removeItem('currentParticipantsTableLastPage');
  sessionStorage.removeItem('currentParticipantsTableGlobalFilter');
  sessionStorage.removeItem('currentParticipantsTableSortBy');

  return (
    <>
      <h1>Dashboard</h1>
      <div className="dashboard-container">
        {/* Display all the trials of the current user */}
        {user && (
          <div className="tableContainer">
            <Trials user={user} />
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
