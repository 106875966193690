import {normalizeResponseErrors} from '../errorHandling/NormalizeErrors';

const fetchApiGet = async (url) => {
  // Make the call to the server
  let res = await fetch(url, {
    method: 'GET',
  });
  res = await normalizeResponseErrors(res);
  let result = await res.json(res);
  return result;
};

const fetchApiSet = async (url, data) => {
  // Make the call to the server
  let res = await fetch(url, data);
  res = await normalizeResponseErrors(res);
  let result = await res.json(res);
  return result;
};

export {fetchApiGet, fetchApiSet};
