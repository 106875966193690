import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {useAuth} from '../authorization/AuthContext';
import {useTrialInfo} from './TrialInfoContext';
import {getLocationState} from '../../services/URL/urlLocation';
import ParticipantsPage from '../participant/ParticipantsPage';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';

import './TrialPage.css';

const TrialPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [trialInfo, setTrialInfo] = useState({});
  const {setCurrentTrialInfo} = useTrialInfo();

  // Get trial's info from location
  useEffect(() => {
    const setTrialInformation = () => {
      try {
        setLoading(true);
        const locationState = getLocationState(location, 'trial');
        setTrialInfo(locationState.data);
        // Update current trial info in the context
        setCurrentTrialInfo(locationState.data);
        // If there is any old information about
        // the previous trial stored in localStorage, it will be removed
        // and a new one will be set.
        if (localStorage.getItem('trialInfo')) {
          localStorage.removeItem('trialInfo');
        }
        localStorage.setItem('trialInfo', JSON.stringify(locationState.data));
      } catch (error) {
        console.error(error);
        setErrors("Something went wrong! We could not find trial's info.");
      } finally {
        setLoading(false);
      }
    };

    if (location) {
      setTrialInformation();
    }
  }, [location, setCurrentTrialInfo]);

  const {userRole} = useAuth(); // Get user's role

  if (loading /*|| localLoading*/) {
    return <div className="spinnerModal"></div>;
  }
  return (
    <>
      {errors && <DisplayErrors errors={errors} />}
      <div className="trial-container">
        <div className="title-flex-container">
          <h1 className="title-flex-item">{`Trial: #${trialInfo.trialNumber} - ${trialInfo.trialName}`}</h1>
          {(userRole === 'Sys_Director' || userRole === 'Admin') && (
            <Link
              to="/TrialUsers"
              state={{
                trialInfo,
              }}
              className="title-flex-item"
              id="manage-users-title_link"
            >
              Manage Users
            </Link>
          )}
        </div>
        {trialInfo && <ParticipantsPage trialInfo={trialInfo} />}
      </div>
    </>
  );
};

export default TrialPage;
