import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {useForm, FormProvider, Controller} from 'react-hook-form';
import {DateTime} from 'luxon';
import ReactTooltip from 'react-tooltip';
import {GrRefresh} from 'react-icons/gr';
import {FcComboChart} from 'react-icons/fc';

import RadioButtonsList from '../../radioButtonMenu/RadioButtonsList';
import {parseISO} from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';
import './FitbitParticipant.css';
import './FitbitParticipantDataForm.css';

// TODO: Simplify this code!!! Shlomi 09/01/2022
function FitbitParticipantDataForm({
  getDataCallback,
  displayDataType,
  initialDataRequest,
}) {
  if (!displayDataType) {
    displayDataType = 'displayRefinedData';
  }

  const [displayRefreshButton, setDisplayRefreshButton] = useState(false);
  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);

  let pickerEndDate = DateTime.now().toJSDate();
  let pickerStartDate = DateTime.now().minus({days: 1}).toJSDate();

  // In case of request from the parent component to display initial data
  // set externalDataRequest to the initialDataRequest otherwise set some initial parameters
  const [externalDataRequest /*, setInitDataRequest*/] = useState(
    initialDataRequest
      ? {
          ...initialDataRequest,
          startDate: initialDataRequest.startDate
            ? initialDataRequest.startDate
            : pickerStartDate,
          endDate: initialDataRequest.endDate
            ? initialDataRequest.endDate
            : pickerEndDate,
        }
      : {startDate: pickerStartDate, endDate: pickerEndDate}
  );

  const [activitiesInputsState /*, setActivitiesInputsState*/] = useState({
    category: 'activities',
    activityResourcePath: 'distance',
    period: '7d',
  });
  const [bodyInputsState /*, setBodyInputsState*/] = useState({
    category: 'body',
    bodyResourcePath: 'weight',
    period: '7d',
  });
  const [heartInputsState /*, setHeartInputsState*/] = useState({
    category: 'heart',
    heartOptions: 'restingHeartRate',
    period: '7d',
  });
  const [sleepInputsState /*, setSleepInputsState*/] = useState({
    category: 'sleep',
    sleepOptions: 'duration',
    period: '7d',
  });

  // react-hook-form initialization
  const methods = useForm({
    mode: 'onSubmit',
  });
  const {handleSubmit, control, register, watch, setValue, getValues, reset} =
    methods;

  // Watch some fields to detect changes in values
  const category = watch('category');
  const activityResourcePath = watch('activityResourcePath');
  const bodyResourcePath = watch('bodyResourcePath');
  startDate = watch('startDate');
  endDate = watch('endDate');
  const period = watch('period');
  // const heartOptions = watch('heartOptions');
  // const sleepOptions = watch('sleepOptions');
  const includeTime = watch('includeTime');

  // In case of external data request, perform a manual submit of the form
  // with the requested data
  useEffect(() => {
    reset(externalDataRequest);
    if (externalDataRequest && externalDataRequest.subject !== 'Overview') {
      handleSubmit(onSubmit(externalDataRequest));
    }
    // I had no other option but to disable the eslint warning in case of onSubmit
  }, [externalDataRequest, handleSubmit]); // eslint-disable-line react-hooks/exhaustive-deps

  // Upon every change, perform a manual submit of the form
  // with the requested data
  useEffect(() => {
    const subscription = watch((data, {name}) => {
      if (
        // name === 'category' ||
        name === 'activityResourcePath' ||
        name === 'bodyResourcePath' ||
        name === 'heartOptions' ||
        name === 'sleepOptions' ||
        name === 'period'
      ) {
        handleSubmit(onSubmit(data));
      }
    });
    return () => subscription.unsubscribe();
    // I had no other option but to disable the eslint warning in case of onSubmit
  }, [handleSubmit, watch]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFields = () => {
    reset({
      ...getValues(),
      category: '',
      activityResourcePath: '',
      bodyResourcePath: '',
      heartOptions: '',
      sleepOptions: '',
      period: '',
    });
  };

  const handleRadioButtonClicked = () => {};

  const handleRadioButtonChanged = (evt) => {
    console.log(`handleRadioButtonChanged: ${evt.currentTarget.name}`);
    switch (evt.currentTarget.name) {
      case 'category':
        // On every change in category reset all the fields and
        // set only the relevant fields to the current selection
        resetFields();
        if (evt.currentTarget.value === 'activities') {
          Object.entries(activitiesInputsState).map((item) => {
            setValue(item[0], item[1]);
          });
        }
        if (evt.currentTarget.value === 'body') {
          Object.entries(bodyInputsState).map((item) => {
            setValue(item[0], item[1]);
          });
        }

        if (evt.currentTarget.value === 'heart') {
          Object.entries(heartInputsState).map((item) => {
            setValue(item[0], item[1]);
          });
        }

        if (evt.currentTarget.value === 'sleep') {
          Object.entries(sleepInputsState).map((item) => {
            setValue(item[0], item[1]);
          });
        }
        break;
      // On every change in resource update the state -
      // i.e. save the last selection of the current category
      // Not in use at this point
      // case 'activityResourcePath':
      //   setActivitiesInputsState({
      //     ...activitiesInputsState,
      //     activityResourcePath: evt.currentTarget.value,
      //   });
      //   break;
      // case 'bodyResourcePath':
      //   setBodyInputsState({
      //     ...bodyInputsState,
      //     bodyResourcePath: evt.currentTarget.value,
      //   });
      //   break;
      // case 'heartOptions':
      //   setHeartInputsState({
      //     ...heartInputsState,
      //     sleepOptions: evt.currentTarget.value,
      //   });
      //   break;
      // case 'sleepOptions':
      //   setSleepInputsState({
      //     ...sleepInputsState,
      //     sleepOptions: evt.currentTarget.value,
      //   });
      //   break;
    }
  };

  // Display to the user the data according to the selected category
  const handleCategorySelected = () => {
    switch (category) {
      case 'activities':
        return displayActivityFields();
      case 'body':
        return displayBodyFields();
      case 'heart':
        return displayHeartFields();
      case 'sleep':
        return displaySleepFields();
      default:
        return '';
    }
  };

  // Selection lists
  const categoriesList = [
    {label: 'Activities', value: 'activities'},
    {label: 'Body & Weight', value: 'body'},
    {label: 'Heart', value: 'heart'},
    {label: 'Sleep', value: 'sleep'},
  ];

  let activityResourcePathList = [];
  if (displayDataType === 'refinedData') {
    activityResourcePathList = [
      {
        label: 'Distance',
        value: 'distance',
        name: 'distance',
      },
      {label: 'Steps', value: 'steps', name: 'Steps'},
      {label: 'Floors', value: 'floors'},
      {label: 'Calories', value: 'calories'},
      // {label: 'Elevation', value: 'elevation'},
      {
        label: 'Total Tracker Active Minutes',
        value: 'totalTrackerActiveMinutes',
      },
      {label: 'Minutes Sedentary', value: 'minutesSedentary'},
      // {label: 'Minutes Lightly Active', value: 'minutesLightlyActive'},
      // {label: 'Minutes Fairly Active', value: 'minutesFairlyActive'},
      // {label: 'Minutes Very Active', value: 'minutesVeryActive'},
    ];
  } else {
    activityResourcePathList = [
      {label: 'Daily Summary', value: 'dailySummary'},
      {label: 'Logs List', value: 'logsList'},
      {label: 'Calories', value: 'calories'},
      {label: 'CaloriesBMR', value: 'caloriesBMR'},
      {label: 'Steps', value: 'steps'},
      {label: 'Distance', value: 'distance'},
      {label: 'Floors', value: 'floors'},
      {label: 'Elevation', value: 'elevation'},
      {label: 'Minutes Sedentary', value: 'minutesSedentary'},
      {label: 'Minutes Lightly Active', value: 'minutesLightlyActive'},
      {label: 'Minutes Fairly Active', value: 'minutesFairlyActive'},
      {label: 'Minutes Very Active', value: 'minutesVeryActive'},
      {label: 'Activity Calories', value: 'activityCalories'},
      {label: 'Tracker-Calories', value: 'tracker/calories'},
      {label: 'Tracker-Steps', value: 'tracker/steps'},
      {label: 'Tracker-Distance', value: 'tracker/distance'},
      {label: 'Tracker-Floors', value: 'tracker/floors'},
      {label: 'Tracker-Elevation', value: 'tracker/elevation'},
      {label: 'Tracker-MinutesSedentary', value: 'tracker/minutesSedentary'},
      {
        label: 'Tracker-Minutes Lightly Active',
        value: 'tracker/minutesLightlyActive',
      },
      {
        label: 'Tracker-Minutes Fairly Active',
        value: 'tracker/minutesFairlyActive',
      },
      {
        label: 'Tracker-Minutes Very Active',
        value: 'tracker/minutesVeryActive',
      },
      {label: 'Tracker-Activity Calories', value: 'tracker/activityCalories'},
    ];
  }

  const maxActivityLogsList = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '10', value: 10},
    {label: '14', value: 14},
    {label: '20', value: 20},
  ];

  let bodyResourcePathList = [];
  if (displayDataType === 'refinedData') {
    bodyResourcePathList = [
      {label: 'Weight', value: 'weight', name: 'weight'},
      {label: 'BMI', value: 'bmi'},
      {label: 'Fat', value: 'fat'},
    ];
  } else {
    bodyResourcePathList = [
      {label: 'BMI', value: 'bmi'},
      {label: 'Fat', value: 'fat'},
      {label: 'Weight', value: 'weight'},
      {label: 'Weight Logs', value: 'log/weight'},
      {label: 'Fat Logs', value: 'log/fat'},
      {label: 'Weight Goal', value: 'log/weight/goal'},
      {label: 'Fat Goal', value: 'log/fat/goal'},
    ];
  }

  let heartOptionsList = [];
  if (displayDataType === 'refinedData') {
    heartOptionsList = [
      {
        label: 'Resting Heart Rate',
        value: 'restingHeartRate',
        name: 'restingHeartRate',
      },
    ];
  } else {
    heartOptionsList = [
      {label: 'Resting Heart Rate', value: 'restingHeartRate'},
    ];
  }

  let sleepOptionsList = [];
  if (displayDataType === 'refinedData') {
    sleepOptionsList = [
      {
        label: 'Sleep Duration',
        value: 'duration',
        name: 'duration',
      },
    ];
  } else {
    sleepOptionsList = [
      {label: 'Sleep Duration', value: 'duration'},
      {label: 'Logs', value: 'logs'},
      {label: 'Logs List', value: 'logsList'},
      {label: 'Log Sleep', value: 'logSleep'},
    ];
  }

  // const maxSleepLogsList = [
  //   {label: '10', value: defaultValues.maxSleepLogs},
  //   {label: '20', value: 20},
  //   {label: '30', value: 30},
  //   {label: '40', value: 40},
  //   {label: '50', value: 50},
  //   {label: '60', value: 60},
  //   {label: '70', value: 70},
  //   {label: '80', value: 80},
  //   {label: '90', value: 90},
  //   {label: '100', value: 100},
  // ];

  const activityPeriodList = [
    {label: 'Today', value: '1d'},
    {label: 'Week', value: '7d'},
    {label: 'Month', value: '1m'},
    {label: '3 Months', value: '3m'},
    {label: '6 Months', value: '6m'},
    {label: 'Year', value: '1y'},
    {
      label: 'Custom',
      value: 'customDates',
    },
  ];

  const bodyPeriodList = [
    {label: 'Today', value: '1d'},
    {label: 'Week', value: '7d'},
    {label: 'Month', value: '1m'},
    {label: '3 Months', value: '3m'},
    {label: '6 Months', value: '6m'},
    {label: 'Year', value: '1y'},
    {
      label: 'Custom',
      value: 'customDates',
    },
  ];

  const heartPeriodList = [
    {label: 'Today', value: '1d'},
    {label: 'Week', value: '7d'},
    {label: 'Month', value: '1m'},
    {label: '3 Months', value: '3m'},
    {label: '6 Months', value: '6m'},
    {label: 'Year', value: '1y'},
    {
      label: 'Custom',
      value: 'customDates',
    },
  ];

  // const bodyHeartPeriodList = [
  //   {
  //     label: 'Click to Select an Option or Clear',
  //     value: defaultValues.bodyHeartPeriod,
  //   },
  //   {label: '1 day', value: '1d'},
  //   {label: '7 day', value: '7d'},
  //   {label: '30 day', value: '30d'},
  //   {label: '1 month', value: '1m'},
  //   {label: '3 month', value: '3m'},
  // ];

  const sleepPeriodList = [
    {label: 'Today', value: '1d'},
    {label: 'Week', value: '7d'},
    {label: 'Month', value: '1m'},
    {label: '3 Months', value: '3m'},
    {
      label: 'Custom',
      value: 'customDates',
    },
  ];

  // const heartDetailLevelList = [
  //   {
  //     label: 'Click to Select an Option or Clear',
  //     value: defaultValues.heartDetailLevel,
  //   },
  //   {label: '1sec', value: '1sec'},
  //   {label: '1min', value: '1min'},
  // ];

  const displayActivityFields = () => {
    return (
      <div>
        <RadioButtonsList
          list={activityResourcePathList}
          title={'Resource'}
          name={'activityResourcePath'}
          btnType={'normal'}
          radioButtonClickedCallback={handleRadioButtonClicked}
          radioButtonChangedCallback={handleRadioButtonChanged}
        />
        {DisplayActivityResourceFields()}
      </div>
    );
  };

  const DisplayActivityResourceFields = () => {
    switch (activityResourcePath) {
      case 'dailySummary':
        return <div>{displayStartDate('Date')}</div>;
      case 'logsList':
        return (
          <div>
            <RadioButtonsList
              list={maxActivityLogsList}
              title={'Max Activity Logs'}
              name={'maxActivityLogs'}
              btnType={'normal'}
              radioButtonClickedCallback={handleRadioButtonClicked}
              radioButtonChangedCallback={handleRadioButtonChanged}
            />
            {!endDate && displayStartDate('After Date')}
            {!startDate && displayEndDate('Before Date')}
          </div>
        );
      default:
        // All other cases
        return (
          <div>
            {/*We'll need this only when I'll be able to display
               the 'Get Activity Intraday Time Series' not available now*/}
            {activityResourcePath === 'ActivityIntraday' ? (
              <div>
                <label htmlFor="includeTime">Include Time?</label>
                <input
                  title={'Date & Time options'}
                  type="checkbox"
                  name="includeTime"
                  {...register('includeTime')}
                />
              </div>
            ) : (
              ''
            )}
            {activityResourcePath && displayPeriod(activityPeriodList)}
            {activityResourcePath && displayCalendarDateRange()}
          </div>
        );
    }
  };

  const displayBodyFields = () => {
    return (
      <div>
        <RadioButtonsList
          list={bodyResourcePathList}
          title={'Resource'}
          name={'bodyResourcePath'}
          btnType={'normal'}
          radioButtonClickedCallback={handleRadioButtonClicked}
          radioButtonChangedCallback={handleRadioButtonChanged}
        />
        {bodyResourcePath && displayBodyResourceFields()}
      </div>
    );
  };

  const displayBodyResourceFields = () => {
    switch (bodyResourcePath) {
      case 'log/weight/goal':
      case 'log/fat/goal':
        return '';
      case 'log/weight':
      case 'log/fat':
        return (
          <div>
            {!startDate && !period ? (
              <div>Please select a Period or a Start Date</div>
            ) : (
              ''
            )}
            {!startDate && displayPeriod(bodyPeriodList)}
            {!period && displayStartDate()}
            {displayEndDate()}
          </div>
        );
      default:
        // All other cases
        return (
          <div>
            {!startDate && !period ? (
              <div>Please select a Period or a Start Date</div>
            ) : (
              ''
            )}
            {displayPeriod(bodyPeriodList)}
            {displayCalendarDateRange()}
          </div>
        );
    }
  };
  const displayHeartFields = () => {
    return (
      <div>
        <RadioButtonsList
          list={heartOptionsList}
          title={'Heart Options'}
          name={'heartOptions'}
          btnType={'normal'}
          radioButtonClickedCallback={handleRadioButtonClicked}
          radioButtonChangedCallback={handleRadioButtonChanged}
        />
        {displayHeartFieldsOptions()}
      </div>
    );
  };

  const displayHeartFieldsOptions = () => {
    return (
      <div>
        {displayPeriod(heartPeriodList)}
        {displayCalendarDateRange()}
      </div>
    );
  };

  const displaySleepFields = () => {
    return (
      <div>
        <RadioButtonsList
          list={sleepOptionsList}
          title={'Sleep Options'}
          name={'sleepOptions'}
          btnType={'normal'}
          radioButtonClickedCallback={handleRadioButtonClicked}
          radioButtonChangedCallback={handleRadioButtonChanged}
        />
        {displayPeriod(sleepPeriodList)}
        {displayCalendarDateRange()}
      </div>
    );
  };

  // const displaySleepFieldsOptions = () => {
  //   switch (sleepOptions) {
  //     case 'duration':
  //       return (
  //         <div>
  //           {displayPeriod(sleepPeriodList)}
  //           {displayCalendarDateRange()}
  //         </div>
  //       );
  //     case 'logs':
  //       return (
  //         <div>
  //           {displayStartDate()}
  //           {displayEndDate()}
  //         </div>
  //       );
  //     case 'logSleep':
  //       return (
  //         <div>
  //           <div>
  //             <label htmlFor="includeTime">Include Time?</label>
  //             <input
  //               title={'Date & Time options'}
  //               type="checkbox"
  //               name="includeTime"
  //               {...register('includeTime')}
  //             />
  //           </div>
  //           {displayStartDate()}
  //         </div>
  //       );
  //     case 'logsList':
  //       return (
  //         <div>
  //           {!endDate && displayStartDate('After Date')}
  //           {!startDate && displayEndDate('Before Date')}
  //           {sleepOptions === 'logsList' && (
  //             <RadioButtonsList
  //               list={maxSleepLogsList}
  //               title={'Max number of sleep logs'}
  //               name={'maxSleepLogs'}
  //               radioButtonClickedCallback={handleRadioButtonClicked}
  //               radioButtonChangedCallback={handleRadioButtonChanged}
  //             />
  //           )}
  //         </div>
  //       );
  //     default:
  //       return '';
  //   }
  // };

  // const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

  // const openDatePicker = () => {
  //   // if (!datePickerIsOpen) {
  //   setDatePickerIsOpen(true);
  //   // }
  // };

  // const closeDatePicker = () => {
  //   // if (datePickerIsOpen) {
  //   setDatePickerIsOpen(false);
  //   // }
  // };

  // const toggleRangeDatePicker = () => {
  //   setDatePickerIsOpen(!datePickerIsOpen);
  //   console.log(`datePickerIsOpen: ${datePickerIsOpen}`);
  // };

  const handelDatePickerClicked = () => {};

  const handelDatePickerChanged = () => {
    setValue('period', 'customDates');
  };

  const displayCalendarDateRange = () => {
    return (
      <div className="calendar">
        <Controller
          control={control}
          name="startDate"
          render={({field: {onChange}}) => (
            <div className="datePicker">
              <label htmlFor="startDate">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    onChange(date);
                    handelDatePickerChanged();
                  }}
                  onFocus={handelDatePickerClicked}
                  className="input"
                  placeholderText="Start Date"
                  dateFormat="MM/dd/yyyy"
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  /*If endDate, Set maxDate according to endDate
                    else set it to today*/
                  maxDate={
                    endDate
                      ? parseISO(endDate.toISOString())
                      : parseISO(new Date().toISOString())
                  }
                  showTimeSelect={includeTime === true ? true : false}
                  // showYearDropdown
                  showMonthDropdown
                  isClearable={true}
                />
              </label>
            </div>
          )}
        />
        <Controller
          control={control}
          name="endDate"
          render={({field: {onChange}}) => (
            <div className="datePicker">
              <label htmlFor="endDate">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    onChange(date);
                    handelDatePickerChanged();
                  }}
                  onFocus={handelDatePickerClicked}
                  className="input"
                  placeholderText="End Date"
                  dateFormat="MM/dd/yyyy"
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  /*If startDate, set minDate according to startDate*/
                  minDate={startDate && parseISO(startDate.toISOString())}
                  maxDate={parseISO(new Date().toISOString())}
                  // showYearDropdown
                  showMonthDropdown
                  isClearable={true}
                />
              </label>
            </div>
          )}
        />
      </div>
    );
  };

  const displayPeriod = (periodList) => {
    if (!periodList) {
      console.error(`displayPeriod: list is missing`);
      return '';
    }
    return (
      <RadioButtonsList
        list={periodList}
        title={'Period'}
        name={'period'}
        btnType={'small'}
        radioButtonClickedCallback={handleRadioButtonClicked}
        radioButtonChangedCallback={handleRadioButtonChanged}
      />
    );
  };

  const displayStartDate = (title) => {
    let displayTitle = 'Start Date';
    if (title) {
      displayTitle = title;
    }
    // For DatePicker (a third party library) that uses a controlled input
    // We need to wrap it with Controller to be able
    // to use it with react-hook-form library
    return (
      <div className="datePicker">
        <h4>{displayTitle}</h4>
        <Controller
          control={control}
          name="startDate"
          render={({field: {onChange, value}}) => (
            <div>
              <label htmlFor="startDate"></label>
              <DatePicker
                selected={value}
                onChange={onChange}
                className="input"
                placeholderText="Select date"
                dateFormat="MM/dd/yyyy"
                /*If endDate, Set maxDate according to endDate
                else set it to today*/
                maxDate={
                  endDate
                    ? parseISO(endDate.toISOString())
                    : parseISO(new Date().toISOString())
                }
                showTimeSelect={includeTime === true ? true : false}
                showMonthDropdown
                isClearable={true}
              />
            </div>
          )}
        />
      </div>
    );
  };

  const displayEndDate = (title) => {
    let displayTitle = 'End Date';
    if (title) {
      displayTitle = title;
    }
    return (
      <div className="datePicker">
        <h4>{displayTitle}</h4>
        <Controller
          control={control}
          name="endDate"
          render={({field: {onChange, value}}) => (
            <div>
              <label htmlFor="endDate"></label>
              <DatePicker
                selected={value}
                onChange={onChange}
                className="input"
                placeholderText="Select date"
                dateFormat="MM/dd/yyyy"
                /*If startDate, set minDate according to startDate*/
                minDate={startDate && parseISO(startDate.toISOString())}
                maxDate={parseISO(new Date().toISOString())}
                showTimeSelect={includeTime === true ? true : false}
                showMonthDropdown
                isClearable={true}
              />
            </div>
          )}
        />
      </div>
    );
  };

  // Find the related label of the current resource value
  const getLabel = (data) => {
    let index = null;
    let label = '';
    if (data) {
      switch (data.category) {
        case 'activities':
          index = activityResourcePathList.findIndex((object) => {
            return object.value === data.activityResourcePath;
          });
          if (index >= 0) {
            label = activityResourcePathList[index].label;
          }
          break;
        case 'body':
          index = bodyResourcePathList.findIndex((object) => {
            return object.value === data.bodyResourcePath;
          });
          if (index >= 0) {
            label = bodyResourcePathList[index].label;
          }
          break;
        case 'heart':
          index = heartOptionsList.findIndex((object) => {
            return object.value === data.heartOptions;
          });
          if (index >= 0) {
            label = heartOptionsList[index].label;
          }
          break;
        case 'sleep':
          index = sleepOptionsList.findIndex((object) => {
            return object.value === data.sleepOptions;
          });
          if (index >= 0) {
            label = sleepOptionsList[index].label;
          }
          break;
      }
    }
    return label;
  };

  const handleOverviewButtonClicked = (event) => {
    event.preventDefault();
    setDisplayRefreshButton(false);
    resetFields();
    getDataCallback('Overview');
  };

  const onSubmit = (data) => {
    // Upon success return data to the parent component
    // Find the related label and use it as a subject
    setDisplayRefreshButton(true);
    const label = getLabel(data);
    data.subject = label;
    getDataCallback(data);
  };

  return (
    <div className="fitbit-participant-form">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <legend></legend>
            <button
              className=""
              onClick={(data) => handleOverviewButtonClicked(data)}
            >
              <FcComboChart
                className="device-category-icon overview-icon"
                data-tip
                data-for="overview"
              />
              <ReactTooltip
                id="overview"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Overview
              </ReactTooltip>
            </button>
            {displayRefreshButton && (
              <button type="submit" className="form-btn">
                <GrRefresh
                  className="refresh-icon"
                  data-tip
                  data-for="form-refresh"
                />
                <ReactTooltip
                  id="form-refresh"
                  place="bottom"
                  aria-haspopup="true"
                  effect="float"
                  className="custom-tooltip"
                >
                  Refresh Data
                </ReactTooltip>
              </button>
            )}
            {/* {category && <div>{handleCategorySelected()}</div>} */}
            <RadioButtonsList
              list={categoriesList}
              title={'Category'}
              name={'category'}
              btnType={'normal'}
              radioButtonClickedCallback={handleRadioButtonClicked}
              radioButtonChangedCallback={handleRadioButtonChanged}
            />
            {/* If option was selected display it*/}
            {category && <div>{handleCategorySelected()}</div>}
          </fieldset>
        </form>
      </FormProvider>
    </div>
  );
}

FitbitParticipantDataForm.propTypes = {
  getDataCallback: PropTypes.func,
  displayDataType: PropTypes.string,
  initialDataRequest: PropTypes.object,
};

export default FitbitParticipantDataForm;
