import React from 'react';

import DexcomParticipantAuthAccess from './DexcomParticipantAuthAccess';
// import DexcomSendAuthRequest from './DexcomSendAuthRequest';

// import './DexcomParticipantConsent.css';

const DexcomParticipantConsent = () => {
  return (
    <>
      <h3>Consent!</h3>
      <div className="dexcom-consent-container">
        <DexcomParticipantAuthAccess />
        {/* <DexcomSendAuthRequest /> */}
      </div>
    </>
  );
};

export default DexcomParticipantConsent;
