import React from 'react';
import {Link} from 'react-router-dom';

// Note: This is a participant sign-up page not user!!!

// import SignupForm from './ParticipantSignupForm';
// import './ParticipantForms.css';

import SignupForm from './ParticipantSignupForm';

const SignupPage = () => {
  // If participant is logged in redirect to the dashboard
  //   if (props.loggedIn) {
  //     return <Navigate to='/' />;
  //   }
  return (
    <div className="signup">
      <div className="centered-container centered-text content">
        <h1>Welcome to CDConnect</h1>
      </div>
      <SignupForm />
      <div className="centered-text">
        <p>
          Already Signed up?
          <Link to="/ParticipantLoginPage"> Log In</Link>
        </p>
      </div>
    </div>
  );
};

export default SignupPage;
