import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AiOutlineEdit} from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import {useAuth} from '../authorization/AuthContext';
import Modal from '../modal/Modal';
import EditParticipantForm from './EditParticipantForm';
import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import PhiWarning from '../SecurityAndComplianceWarnings/PhiWarning';

const EditParticipantPage = ({participant, editCallback}) => {
  const [editParticipantModal, setEditParticipantModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSubmitEditParticipant = async (participant) => {
    let response = {data: null, errors: null};
    let url = `/participant`;
    try {
      setLoading(true);
      response = await fetchApiSet(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({participant}),
      });
      if (response.errors) {
        console.error(response.errors);
        throw new Error(
          `Error: Editing participant failed! - ${response.errors[0].errorType}`
        );
      } else {
        // If everything is ok close modal and send participant info back to parent component
        setEditParticipantModal(false);
        editCallback(participant);
      }
    } catch (error) {
      console.error(error.message);
      // TODO: decide what kind of errors to display here
      if (error && error.displayToUser && error.message) {
        setErrors(`Error:  ${error.message}!`);
      } else {
        setErrors('Error: editing participant failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelEditParticipant = () => {
    setEditParticipantModal(false);
  };

  const closeModal = () => {
    setEditParticipantModal(false);
  };

  const handleEditParticipant = async () => {
    setErrors(null);
    setEditParticipantModal(true);
  };

  const {userRole} = useAuth(); // Get user's role

  return (
    <>
      {loading ? <div className="spinnerModal"></div> : ''}
      {(userRole === 'Sys_Director' ||
        userRole === 'Admin' ||
        userRole === 'User') && (
        <button
          className="icon-btn-no-background"
          id="edit-participant-btn"
          onClick={handleEditParticipant}
        >
          <AiOutlineEdit data-tip data-for="edit_participant"></AiOutlineEdit>
          <ReactTooltip
            id="edit_participant"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Edit Participant
          </ReactTooltip>
        </button>
      )}
      {editParticipantModal && (
        <Modal
          title={'Edit Participant'}
          text={<PhiWarning />}
          closeModalCallback={closeModal}
        >
          <div className="centered-text">
            {<DisplayErrors errors={errors} />}
          </div>
          <EditParticipantForm
            participant={participant}
            editParticipantCallback={handleSubmitEditParticipant}
            cancelEditCallback={cancelEditParticipant}
          />
        </Modal>
      )}
    </>
  );
};

EditParticipantPage.propTypes = {
  participant: PropTypes.object,
  editCallback: PropTypes.func,
};

export default EditParticipantPage;
