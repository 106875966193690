import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';

import {useYupValidationResolver} from '../forms/YupValidationResolver';
import {fetchApiSet} from '../api/fetchApi';
import {onFormSubmitRecaptchaVerification} from '../common/googleRecaptcha';

import './ContactUsForm.css';

// Yup form validation rules
const validationSchema = yup.object({
  firstName: yup
    .string()
    .required('First Name is required')
    .min(2)
    .max(50)
    .label('First Name'),
  lastName: yup
    .string()
    .required('Last name is required')
    .min(2)
    .max(50)
    .label('Last Name'),
  email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid')
    .label('Email'),
  subject: yup
    .string()
    .required('Subject is required')
    .min(3)
    .max(50)
    .label('First Name')
    .label('Subject'),
  textMessage: yup
    .string()
    .required('Message is required')
    .min(10)
    .max(300)
    .label('Message'),
});

const ContactUsForm = () => {
  const resolver = useYupValidationResolver(validationSchema);
  const [loading, setLoading] = useState(false);
  const [regErrors, setRegErrors] = useState('');
  const [successMsg, setSuccessMsg] = useState(null);
  const [messageCharCount, setMessageCharCount] = useState(0);

  const handleTextChange = (evt) => {
    evt.preventDefault();
    setMessageCharCount(evt.target.value.length);
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = async (data) => {
    let res = null;
    await window.grecaptcha.ready(async () => {
      try {
        res = await onFormSubmitRecaptchaVerification();
        if (res.errors) {
          // If reCAPTCHA version 3 failed, display an error
          throw new Error(res.errors);
        } else {
          // If reCAPTCHA Version 3 verification was successful submit the data
          submitData(data);
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setRegErrors(error.message);
        } else {
          setRegErrors(`Message could not be sent (V3)!`);
        }
      }
    });
  };

  const submitData = async (data) => {
    try {
      setLoading(true);

      const url = '/contactUs';
      const res = await fetchApiSet(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          subject: data.subject,
          message: data.textMessage,
        }),
      });
      if (res.errors) {
        console.error(res.errors);
        throw new Error(`Message could not be sent!`);
      } else {
        // If everything is ok, display message to the user
        setSuccessMsg('Message sent. Thank you!');
        // Hide message after 5000 milliseconds
        setTimeout(() => {
          // Reset the form upon successful submit
          reset({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
          });

          setSuccessMsg('');
        }, 5000);
      }
    } catch (error) {
      console.error(error);
      if (error && error.displayToUser && error.message) {
        setRegErrors(error.message);
      } else {
        setRegErrors(`Something went wrong message could not be sent!`);
      }
    } finally {
      setLoading(false);
    }
  };

  const clearRegErrorMessages = () => {
    setRegErrors('');
  };

  return (
    <>
      <h1 className="centered-text">Contact Us</h1>
      <h2 className="centered-text">
        Please get in touch with us if you have any questions or suggestions.
      </h2>
      <form
        className="contact-us-form centered-container narrow"
        onSubmit={handleSubmit(onSubmit)}
      >
        {loading ? <div className="spinnerModal"></div> : ''}
        <fieldset>
          <label>First Name</label>
          <div className="form-error" aria-live="polite">
            {errors.firstName?.message}
          </div>
          <input
            name="firstName"
            type="text"
            {...register('firstName')}
            onFocus={clearRegErrorMessages}
            className={`form-input ${errors.firstName ? 'is-invalid' : ''}`}
          />
          <label>Last Name</label>
          <div className="form-error" aria-live="polite">
            {errors.lastName?.message}
          </div>
          <input
            name="lastName"
            type="text"
            {...register('lastName')}
            onFocus={clearRegErrorMessages}
            className={`form-input ${errors.lastName ? 'is-invalid' : ''}`}
          />
          <label>Email</label>
          <div className="form-error" aria-live="polite">
            {errors.email?.message}
          </div>
          <input
            name="email"
            type="text"
            {...register('email')}
            onFocus={clearRegErrorMessages}
            className={`form-input ${errors.email ? 'is-invalid' : ''}`}
          />
          <label>Subject</label>
          <div className="form-error" aria-live="polite">
            {errors.subject?.message}
          </div>
          <input
            name="subject"
            type="text"
            {...register('subject')}
            onFocus={clearRegErrorMessages}
            className={`form-input ${errors.subject ? 'is-invalid' : ''}`}
          />
          <label>Message</label>
          <div className="form-error" aria-live="polite">
            {errors.textMessage?.message}
          </div>
          <textarea
            name="textMessage"
            type="text"
            {...register('textMessage')}
            onFocus={clearRegErrorMessages}
            onChange={(evt) => handleTextChange(evt)}
            maxLength={300}
            className={`form-input ${errors.textMessage ? 'is-invalid' : ''}`}
          />
          <p>{messageCharCount}/300</p>
        </fieldset>
        {regErrors ? (
          <div className="form-error centered-text">{regErrors}</div>
        ) : successMsg ? (
          <div className="success-message centered-text">{successMsg}</div>
        ) : (
          ''
        )}
        <div className="">
          <button type="submit" className="btn" id="contact-us-btn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactUsForm;
