import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import './ProfileMenu.css';

const ProfileMenu = ({itemClickedCallback, userLoginInfo}) => {
  const ProfileMenuItems = [
    {
      title: 'Change Password',
      path: '/ChangePwdReqPage',
    },
    {
      title: 'Logout',
      path: '',
    },
  ];

  return (
    <div className="profile-menu">
      {userLoginInfo && (
        <h4>
          <div>
            Hello:
            {userLoginInfo.firstName} {userLoginInfo.lastName}
          </div>
          <br></br>
          <div>
            Logged In as:
            <div>{userLoginInfo.userName}</div>
            <div>Team: {userLoginInfo.teamName}</div>
            <div>Role: {userLoginInfo.role}</div>
          </div>
        </h4>
      )}
      <div>
        {ProfileMenuItems.map((item, key) => {
          return (
            <Link
              key={key}
              className="profile-menu-item"
              to={item.path}
              onClick={itemClickedCallback}
            >
              {item.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

ProfileMenu.propTypes = {
  itemClickedCallback: PropTypes.func,
  userLoginInfo: PropTypes.object,
};

export default ProfileMenu;
