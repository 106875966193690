import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import {useYupValidationResolver} from '../../forms/YupValidationResolver';

// Yup form validation rules
const validationSchema = yup.object({});

function OmronParticipantConsentForm({
  participantId,
  participantDataCallback,
  cancelAuthCallback,
  error,
}) {
  const resolver = useYupValidationResolver(validationSchema);

  const {handleSubmit, register, reset} = useForm({mode: 'onSubmit', resolver});

  // Insert current values to form's fields
  useEffect(() => {
    reset({
      participantId: participantId,
    });
  }, [reset, participantId]);

  const onSubmit = (data) => {
    // Upon success return participant's data to the parent
    participantDataCallback(data);
  };

  const handleCancelButtonCLicked = () => {
    cancelAuthCallback();
  };

  return (
    <form
      className="login-form centered-container narrow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset>
        <input type="hidden" {...register(`participantId`)} />
      </fieldset>

      <div className="form-group">
        {!error && (
          <>
            <h3 className="centered-text">
              Please click the Authorize Access button below to complete the
              authorization process
            </h3>
          </>
        )}
        <div className="form-button-container">
          {!error && (
            <button type="submit" className="btn btn-primary">
              Authorize Access
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleCancelButtonCLicked}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
}

OmronParticipantConsentForm.propTypes = {
  participantId: PropTypes.string,
  participantDataCallback: PropTypes.func,
  cancelAuthCallback: PropTypes.func,
  error: PropTypes.string,
};

export default OmronParticipantConsentForm;
