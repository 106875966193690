import React from 'react';

import FitbitParticipantAuthAccess from '../../fitbit/participant/FitbitParticipantAuthAccess';

const PAFitbitConsentPage = () => {
  let participantId = null;
  const current_url = window.location.href;
  if (current_url.includes('id')) {
    // Get the search part of the url
    const searchStr = window.location.search;
    // Parse the search string parameters
    const searchParams = new URLSearchParams(searchStr);
    participantId = searchParams.get('id');
  } else {
    console.error(`No ID in url`);
  }
  let emailMassage = '';
  if (current_url.includes('email')) {
    // Get the search part of the url
    const searchStr = window.location.search;
    // Parse the search string parameters
    const searchParams = new URLSearchParams(searchStr);
    // Extract the auth code from the parameters
    const email = searchParams.get('email');
    emailMassage = `*When prompted, please enter the following email address: ${email}`;
  } else {
    console.error(`No Email in url`);
  }
  return (
    <>
      <h3>Consent!</h3>
      <p>
        To be able to use your data in our clinical trial we will need to get
        your authorization.
      </p>
      <p>To authorize please click on the button below.</p>
      <p>
        For more information please refer to the links to our Terms and
        Conditions of use and our Privacy Policy at the bottom of this page.
      </p>
      <p>{emailMassage}</p>
      <div className="">
        <FitbitParticipantAuthAccess participantId={participantId} />
      </div>
    </>
  );
};

export default PAFitbitConsentPage;
