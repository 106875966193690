import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import {useYupValidationResolver} from '../../forms/YupValidationResolver';

// Yup form validation rules
const validationSchema = yup.object({
  email: yup.string().required('Email is required').email('Email is invalid'),
});

function DexcomParticipantConsentForm({participantDataCallback}) {
  const resolver = useYupValidationResolver(validationSchema);
  const [regError, setRegError] = useState('');

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = (data) => {
    // Upon success return participant's data to the parent
    participantDataCallback(data);
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  return (
    <form
      className="login-form centered-container narrow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-error centered-text">{regError}</div>
      <fieldset>
        <label>*Email (Username)</label>
        <div className="form-error" aria-live="polite">
          {errors.email?.message}
        </div>
        <input
          name="email"
          type="text"
          //placeholder='email'
          {...register('email')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.email ? 'is-invalid' : ''}`}
        />
      </fieldset>

      <div className="form-group">
        <button type="submit" className="btn btn-primary">
          Authorize Access
        </button>
      </div>
    </form>
  );
}

DexcomParticipantConsentForm.propTypes = {
  participantDataCallback: PropTypes.func,
};

export default DexcomParticipantConsentForm;
