import React, {useState, useEffect, useCallback} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';

import {normalizeResponseErrors} from '../../errorHandling/NormalizeErrors';
import FitbitParticipantConsentForm from './FitbitParticipantConsentForm';
import {useTrialInfo} from '../../trials/TrialInfoContext';
import {useAuth} from '../../authorization/AuthContext';

const FitbitCallback = () => {
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);
  const [authCode, setAuthCode] = useState(null);
  const [participantId, setParticipantId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [regError, setRegError] = useState(null);
  const [canceled, setCanceled] = useState(false);
  const {getCurrentTrialInfo} = useTrialInfo();

  let currentTrialInfo = null;
  const {currentUser} = useAuth();
  try {
    // Get trial info
    currentTrialInfo = getCurrentTrialInfo();
  } catch (error) {
    console.error(
      'Fitbit Callback - Reading Trial Info from localStorage failed',
      error
    );
  }

  // Exchange the authorization code we just received for an access and refresh token
  const getToken = useCallback(
    (participantData) => {
      const {participantId} = participantData;
      setLoading(true);
      fetch(`/fitbit-callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({authCode, participantId}),
      })
        .then((result) => normalizeResponseErrors(result))
        .then(() => {
          setAuthorized(true);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error', error);
          setLoading(false);
          setRegError(
            'Authorization failed! Please click Cancel and try again if needed'
          );
        });
    },
    [authCode]
  );

  const navigateToMainTrialPage = () => {
    if (currentTrialInfo) {
      navigate('/TrialPage', {
        state: currentTrialInfo,
      });
    } else {
      return <Navigate to="/" />;
    }
  };

  const handelCancelAuthButtonClicked = () => {
    // In case that a user is logged in and have a currentTrialInfo go back to the main trial page
    if (currentUser) {
      navigateToMainTrialPage();
    } else {
      setCanceled(true);
    }
  };

  useEffect(() => {
    // Extract the code we got back from Fitbit from the url
    //console.log(`URL:${window.location.href}`);
    const current_url = window.location.href;
    if (current_url.includes('code')) {
      // Get the search part of the url
      const searchStr = window.location.search;
      // Parse the search string parameters
      const searchParams = new URLSearchParams(searchStr);
      // Extract the auth code from the parameters
      const authCode = searchParams.get('code');
      const state = searchParams.get('state');
      setParticipantId(state);
      setAuthCode(authCode);
    } else {
      console.error(`No Code`);
      setRegError(
        `Authorization failed - Please click Cancel and try again if needed!`
      );
    }
  }, []);

  function deviceAuthorized() {
    if (currentUser) {
      return navigateToMainTrialPage();
    } else {
      return (
        <div className="success-message centered-text">
          <div>The authorization process has been completed successfully!</div>
          <div> You may now close this page.</div>
        </div>
      );
    }
  }

  return (
    <div>
      <h1 className="centered-text">Fitbit Authorization</h1>
      {loading ? <div className="spinnerModal"></div> : ''}
      {authorized ? (
        deviceAuthorized()
      ) : canceled ? (
        <div className="error-message centered-text">
          The authorization has been canceled. You may now close this page.
        </div>
      ) : (
        <>
          {regError && (
            <div className="error-message centered-text">{regError}</div>
          )}
          <FitbitParticipantConsentForm
            participantId={participantId}
            participantDataCallback={getToken}
            cancelAuthorizationCallback={handelCancelAuthButtonClicked}
            error={regError}
          />
        </>
      )}
    </div>
  );
};

export default FitbitCallback;
