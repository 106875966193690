import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {useForm, FormProvider, Controller} from 'react-hook-form';

import RadioButtonsList from '../../radioButtonMenu/RadioButtonsList';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';

import 'react-datepicker/dist/react-datepicker.css';
import './OmronParticipantDataForm.css';

function OmronParticipantDataForm({getDataCallback, displayDataType}) {
  let [startDate, setStartDate] = useState('');
  const [errors, setErrors] = useState(null);

  if (!displayDataType) {
    displayDataType = 'displayRefinedData';
  }

  // react-hook-form initialization
  const methods = useForm({
    mode: 'onSubmit',
  });
  const {handleSubmit, control, setValue} = methods;

  // Select lists
  const categoriesList = [
    {
      label: 'Blood Pressure',
      value: 'bloodPressure',
      id: 'bloodPressure',
      checked: true,
    },
    {label: 'Weight', value: 'weight', id: 'weight'},
  ];

  const resultsLimitList = [
    {label: 'All', value: 0, id: 'All', checked: true},
    {label: '240', value: 240, id: '240'},
    {label: '120', value: 120, id: '120'},
    {label: '30', value: 30, id: '30'},
    {label: '10', value: 10, id: '10'},
  ];

  const handelDatePickerChanged = () => {
    setValue('period', 'customDates');
  };

  const handelDatePickerClicked = () => {};

  const displayStartDateCalendar = () => {
    let displayTitle = 'Start Date';
    // For DatePicker (a third party library) that uses a controlled input
    // We need to wrap it with Controller to be able
    // to use it with react-hook-form library
    return (
      <div className="calendar">
        <h4>{displayTitle}</h4>
        <Controller
          control={control}
          name="startDate"
          render={({field: {onChange}}) => (
            <div>
              <label htmlFor="startDate"></label>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  onChange(date);
                  handelDatePickerChanged();
                }}
                onFocus={handelDatePickerClicked}
                className="input"
                placeholderText="Select date"
                dateFormat="MM/dd/yyyy"
                maxDate={new Date()}
                showTimeSelect={false}
                showMonthDropdown
                isClearable={true}
              />
            </div>
          )}
        />
      </div>
    );
  };

  // Find the related label of the current resource value
  const getLabel = (data) => {
    let index = null;
    let label = '';
    if (data) {
      index = categoriesList.findIndex((object) => {
        return object.value === data.category;
      });
      label = categoriesList[index].label;
      return label;
    }
  };

  const onSubmit = (data) => {
    // Upon success return data to the parent component
    // Find the related label and use it as a subject
    if (data.category === undefined || data.category === null) {
      setErrors('Please select a Category');
    } else if (data.startDate === undefined || data.startDate === null) {
      setErrors('Please select a Start Date');
    } else if (data.resultsLimit === undefined || data.resultsLimit === null) {
      setErrors('Please select an option from the Results Limit');
    } else {
      setErrors('');
      const label = getLabel(data);
      data.subject = label;
      getDataCallback(data);
    }
  };

  return (
    <div className="omron-participant-form">
      {errors && <DisplayErrors errors={errors} />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <legend></legend>
            <RadioButtonsList
              list={categoriesList}
              title={'Category'}
              name={'category'}
              btnType={'normal'}
              className={'dropdown-list'}
            />
            <div>{displayStartDateCalendar({startDate})}</div>
            <RadioButtonsList
              list={resultsLimitList}
              title={'Results Limit'}
              name={'resultsLimit'}
              btnType={'small'}
              className={'dropdown-list'}
            />
          </fieldset>
          {
            <button type="submit" className="btn">
              Get Data
            </button>
          }
        </form>
      </FormProvider>
    </div>
  );
}

OmronParticipantDataForm.propTypes = {
  getDataCallback: PropTypes.func,
  displayDataType: PropTypes.string,
};

export default OmronParticipantDataForm;
