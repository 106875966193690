import React from 'react';
import {useNavigate} from 'react-router-dom';

const NotAuthorizedPage = () => {
  const navigate = useNavigate();

  // Function to handle the "Go Back" button click
  const handleGoBack = () => {
    navigate('/'); // This will navigate back to the previous page
  };

  return (
    <div className="centered-container centered-text">
      <h1>403 Error - Not Authorized</h1>
      <h3>You do not have permission to access this page.</h3>
      <button
        className="btn"
        onClick={() => {
          handleGoBack();
        }}
      >
        Go to home page!
      </button>
    </div>
  );
};

export default NotAuthorizedPage;
