import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {GiBattery100} from 'react-icons/gi';
import {GiBattery75} from 'react-icons/gi';
import {GiBattery50} from 'react-icons/gi';
import {GiBattery25} from 'react-icons/gi';
import {GiBattery0} from 'react-icons/gi';
import {IoFootsteps} from 'react-icons/io5';
import {IoFlash} from 'react-icons/io5';
import {FaHeartbeat} from 'react-icons/fa';
import {GiNightSleep} from 'react-icons/gi';
import {MdLocationOn} from 'react-icons/md';
import {FcComboChart} from 'react-icons/fc';
import {GiWeightScale} from 'react-icons/gi';

import {FitbitQueryData} from '../queryBuilder/FitbitQueryBuilder';

const FitbitDeviceInfo = ({value, index, participant}) => {
  {
    let navigate = useNavigate();
    const handleDeviceCategoryCLicked = (participant, deviceType, category) => {
      const queryData = new FitbitQueryData();
      // In this case we always display data from last 7 days
      queryData.period = '7d';
      switch (category) {
        case 'overView':
          queryData.category = '';
          queryData.subject = 'Overview';
          queryData.activityResourcePath = '';
          return navigate('/FitbitParticipantDataPage', {
            state: {participant: participant, query: queryData},
          });
        case 'steps':
          queryData.category = 'activities';
          queryData.subject = 'Steps';
          queryData.activityResourcePath = 'steps';
          return navigate('/FitbitParticipantDataPage', {
            state: {participant: participant, query: queryData},
          });
        case 'activeMinutes':
          queryData.category = 'activities';
          queryData.subject = 'Total Tracker Active Minutes';
          queryData.activityResourcePath = 'totalTrackerActiveMinutes';
          return navigate('/FitbitParticipantDataPage', {
            state: {participant: participant, query: queryData},
          });
        case 'sleepDuration':
          queryData.category = 'sleep';
          queryData.subject = 'Sleep Duration';
          queryData.sleepOptions = 'duration';
          return navigate('/FitbitParticipantDataPage', {
            state: {participant: participant, query: queryData},
          });
        case 'restingHeartRate':
          queryData.category = 'heart';
          queryData.subject = 'Resting Heart Rate';
          queryData.heartOptions = 'restingHeartRate';
          return navigate('/FitbitParticipantDataPage', {
            state: {participant: participant, query: queryData},
          });
        case 'distance':
          queryData.category = 'activities';
          queryData.subject = 'Distance';
          queryData.activityResourcePath = 'distance';
          return navigate('/FitbitParticipantDataPage', {
            state: {participant: participant, query: queryData},
          });
        case 'weight':
          queryData.category = 'body';
          queryData.bodyResourcePath = 'weight';
          queryData.subject = 'Weight';
          return navigate('/FitbitParticipantDataPage', {
            state: {participant: participant, query: queryData},
          });
        default:
          return;
      }
    };

    // Display info about Fitbit devices authorized by the participant
    return (
      <div className="devices-info" key={index}>
        {index > 0 && <hr></hr>}
        <div className="devices-info-flex">
          {value.device && (
            <div className="devices-basic-info-name">{value.device}</div>
          )}
          {value.batteryLevel !== undefined && value.batteryLevel !== null ? (
            <>
              <div>
                {value.batteryLevel > 90 ? (
                  <GiBattery100 className="battery-icon high-level" />
                ) : value.batteryLevel > 50 ? (
                  <GiBattery75 className="battery-icon med-level" />
                ) : value.batteryLevel > 25 ? (
                  <GiBattery50 className="battery-icon low-level" />
                ) : value.batteryLevel > 0 ? (
                  <GiBattery25 className="battery-icon very-low-level" />
                ) : (
                  <GiBattery0 className="battery-icon empty-level" />
                )}
              </div>
              <div>{value.batteryLevel}%</div>
            </>
          ) : (
            <div></div>
          )}
        </div>
        {value.deviceVersion && <div>Version: {value.deviceVersion}</div>}
        {value.deviceType && <div>Type: {value.deviceType}</div>}
        {value.lastSyncTime && <div>Last Sync: {value.lastSyncTime}</div>}
        <br />
        {value.deviceType === 'TRACKER' ? (
          <div className="category-icons">
            {/* <div>Fitbit Data:</div> */}
            <button
              className="device-category-btn"
              onClick={() =>
                handleDeviceCategoryCLicked(participant, 'Fitbit', 'overView')
              }
            >
              <FcComboChart
                className="distance-icon device-category-icon"
                data-tip
                data-for="overview"
              />
              <ReactTooltip
                id="overview"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Fitbit Data
              </ReactTooltip>
            </button>
            {/* <div>Quick Access:</div> */}
            {/* Steps */}
            <button
              className="device-category-btn"
              onClick={() =>
                handleDeviceCategoryCLicked(participant, 'Fitbit', 'steps')
              }
            >
              <IoFootsteps
                className="steps-icon device-category-icon"
                data-tip
                data-for="steps"
              />
              <ReactTooltip
                id="steps"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Steps
              </ReactTooltip>
            </button>
            {/* Active Minutes */}
            <button
              className="device-category-btn"
              onClick={() =>
                handleDeviceCategoryCLicked(
                  participant,
                  'Fitbit',
                  'activeMinutes'
                )
              }
            >
              <IoFlash
                className="active-minutes-icon device-category-icon"
                data-tip
                data-for="active-minutes"
              />
              <ReactTooltip
                id="active-minutes"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Active Minutes
              </ReactTooltip>
            </button>
            {/* Sleep Duration */}
            <button
              className="device-category-btn"
              onClick={() =>
                handleDeviceCategoryCLicked(
                  participant,
                  'Fitbit',
                  'sleepDuration'
                )
              }
            >
              <GiNightSleep
                className="sleep-duration-icon device-category-icon"
                data-tip
                data-for="sleep-duration"
              />
              <ReactTooltip
                id="sleep-duration"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Sleep Duration
              </ReactTooltip>
            </button>
            {/* Heart Rate */}
            <button
              className="device-category-btn"
              onClick={() =>
                handleDeviceCategoryCLicked(
                  participant,
                  'Fitbit',
                  'restingHeartRate'
                )
              }
            >
              <FaHeartbeat
                className="resting-heart-rate-icon device-category-icon"
                data-tip
                data-for="resting-heart-rate"
              />
              <ReactTooltip
                id="resting-heart-rate"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Resting Heart Rate
              </ReactTooltip>
            </button>
            {/* Distance */}
            <button
              className="device-category-btn"
              onClick={() =>
                handleDeviceCategoryCLicked(participant, 'Fitbit', 'distance')
              }
            >
              <MdLocationOn
                className="distance-icon device-category-icon"
                data-tip
                data-for="distance"
              />
              <ReactTooltip
                id="distance"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Distance
              </ReactTooltip>
            </button>
          </div>
        ) : value.deviceType === 'SCALE' ? (
          <div>
            <button
              className="device-category-btn"
              onClick={() =>
                handleDeviceCategoryCLicked(participant, 'Fitbit', 'weight')
              }
            >
              <GiWeightScale
                className="distance-icon device-category-icon"
                data-tip
                data-for="weight"
              />
              <ReactTooltip
                id="weight"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Weight
              </ReactTooltip>
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
};

FitbitDeviceInfo.propTypes = {
  value: PropTypes.object,
  index: PropTypes.number,
  participant: PropTypes.object,
};

export default FitbitDeviceInfo;
