import React from 'react';
import PropTypes from 'prop-types';

import './Mailto.css';

const Mailto = ({email, subject, body, ...rest}) => {
  return (
    <button type="button" className="btn authorize-access-btn">
      <a href={`mailto:${email}?subject=${subject || ''}&body=${body || ''}`}>
        {rest.children}
      </a>
    </button>
  );
};

Mailto.propTypes = {
  email: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.string,
};

export default Mailto;
