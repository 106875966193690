import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AiOutlineDelete} from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import {useAuth} from '../authorization/AuthContext';
import Modal from '../modal/Modal';
import DeleteParticipantForm from './DeleteParticipantForm';
import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {useTrialInfo} from '../trials/TrialInfoContext';

const DeleteParticipantPage = ({participant, deleteCallback}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {getCurrentTrialInfo} = useTrialInfo();
  const [deleteParticipantModal, setDeleteParticipantModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSubmitDeleteParticipant = async () => {
    const id = participant.id;
    let response = {data: null, errors: null};
    let url = `/participant`;
    try {
      setLoading(true);
      response = await fetchApiSet(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id}),
      });
      if (response.errors) {
        console.error(response.errors);
        throw new Error(
          `Error: Deleting participant failed! - ${response.errors[0].errorType}`
        );
      } else {
        // If everything is ok close modal
        setDeleteParticipantModal(false);
        // If in TrialPage send participant info back to parent component
        // else go back to TrialPage
        if (location.pathname === '/TrialPage') {
          deleteCallback(participant);
        } else {
          const currentTrialInfo = getCurrentTrialInfo();
          navigate('/TrialPage', {
            state: currentTrialInfo,
          });
        }
      }
    } catch (error) {
      console.error(error.message);
      // TODO: decide what kind of errors to display here
      if (error && error.displayToUser && error.message) {
        setErrors(`Error:  ${error.message}!`);
      } else {
        setErrors('Error: deleting participant failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelDeleteParticipant = () => {
    setDeleteParticipantModal(false);
  };

  const closeModal = () => {
    setDeleteParticipantModal(false);
  };

  const handleDeleteParticipant = async () => {
    setErrors(null);
    setDeleteParticipantModal(true);
  };

  const {userRole} = useAuth(); // Get user's role
  return (
    <>
      {loading ? <div className="spinnerModal"></div> : ''}
      {(userRole === 'Sys_Director' ||
        userRole === 'Admin' ||
        userRole === 'User') && (
        <button
          className="icon-btn-no-background"
          id="delete-participant-btn"
          onClick={handleDeleteParticipant}
        >
          <AiOutlineDelete
            data-tip
            data-for="delete_participant"
          ></AiOutlineDelete>
          <ReactTooltip
            id="delete_participant"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Delete Participant
          </ReactTooltip>
        </button>
      )}
      {deleteParticipantModal && (
        <Modal
          title={'Delete Participant?'}
          text={
            'If the participant has authorized devices, they will also all be removed!'
          }
          closeModalCallback={closeModal}
        >
          <div className="centered-text">
            {<DisplayErrors errors={errors} />}
          </div>
          <DeleteParticipantForm
            participant={participant}
            deleteParticipantCallback={handleSubmitDeleteParticipant}
            cancelDeleteCallback={cancelDeleteParticipant}
          />
        </Modal>
      )}
    </>
  );
};

DeleteParticipantPage.propTypes = {
  participant: PropTypes.object,
  deleteCallback: PropTypes.func,
};

export default DeleteParticipantPage;
