import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {IoIosArrowBack} from 'react-icons/io';
import {IoIosArrowForward} from 'react-icons/io';

import './Sidebar.css';

const Sidebar = ({width, height, sidebarToggledCallback, children}) => {
  const [xPosition, setXPosition] = useState(-width);

  const toggleSidebar = () => {
    xPosition < 0 ? setXPosition(0) : setXPosition(-width);
    sidebarToggledCallback();
  };

  useEffect(() => {
    setXPosition(0);
  }, []);

  return (
    <>
      <div
        className="sidebar"
        style={{
          transform: `translatex(${xPosition}px)`,
          width: width,
          minHeight: height,
        }}
      >
        <button
          onClick={() => toggleSidebar()}
          className="toggle-btn"
          style={{
            transform: `translate(${width}px, 0vh)`,
          }}
        >
          {xPosition === 0 ? (
            <IoIosArrowBack className="arrow" />
          ) : (
            <IoIosArrowForward className="arrow" />
          )}
        </button>
        <div className="content">{children}</div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.string,
  children: PropTypes.node,
  sidebarToggledCallback: PropTypes.func,
};

export default Sidebar;
