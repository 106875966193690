import React from 'react';
import PropTypes from 'prop-types';

const DisplayErrors = ({errors}) => {
  // At this point we handle only one error === string.
  // In the future we should handle an array of errors
  return errors ? <div className="error-message">{errors}</div> : <div></div>;
};

DisplayErrors.propTypes = {
  errors: PropTypes.string,
};

export {DisplayErrors};
