import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import FitbitParticipantDataForm from './FitbitParticipantDataForm';
import {FitbitQueryBuilder} from '../queryBuilder/FitbitQueryBuilder';
import {DisplayRefinedData} from '../displayData/DisplayRefinedData';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';
import Sidebar from '../../sidebar/Sidebar';
import {getTrackerActiveMinutes} from './FitbitActiveMinutes';
import {GetParticipantDataFromFitbit} from './FitbitParticipantData';
import DisplayParticipantOverviewData from '../displayData/DisplayParticipantOverviewData';
import TrialInfo from '../../trials/TrialInfo';

import './FitbitParticipantDataPage.css';

const FitbitParticipantDataPage = () => {
  let location = useLocation();
  const [queryInfo, setQueryInfo] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [participantInfo, setParticipantInfo] = useState(null);
  const [initialDataRequest, setInitialDataRequest] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(true);

  useEffect(() => {
    try {
      const data = location.state;
      const participant = data.participant;
      const query = data.query;
      // Code to store the info in Session Storage not in use from 03/02/2022
      // const res = getLocationState(location, 'participant');
      // if (res.data) {
      //   setParticipantInfo(res.data.participant);
      // }
      if (participant) {
        setParticipantInfo(data.participant);
      }
      // If a query was sent from the other page display the data here
      if (query) {
        setInitialDataRequest(query);
      }
    } catch (error) {
      console.error(error);
      setErrors(
        "Something went wrong! We could not find participant's info for Fitbit device."
      );
    }
  }, [location]);

  const handleGetDataRequest = async (queryData) => {
    let queryStr = '';
    let queryResults = null;
    try {
      // Clear results and errors from previous query
      setData(null);
      setErrors(null);
      setQueryInfo(null);
      setLoading(true);
      setInitialDataRequest(null);

      if (queryData === 'Overview') {
        setQueryInfo('Overview');
        return;
      }

      // Build a query based on the form's data
      const res = FitbitQueryBuilder(queryData);
      if (res) {
        if (res.error) {
          console.error(res.error);
          throw new Error(res.error);
        } else if (res.data) {
          queryStr = res.data;
          // Get the actual data from Fitbit
          if (queryData.activityResourcePath === 'totalTrackerActiveMinutes') {
            queryResults = await getTrackerActiveMinutes(
              queryData,
              participantInfo
            );
          } else {
            queryResults = await GetParticipantDataFromFitbit(
              participantInfo,
              queryStr
            );
          }
          if (queryResults.errors) {
            // console.log(errors);
            if (queryResults.errors === 'Too Many Requests') {
              setErrors('Too Many Requests!');
            } else {
              setErrors(queryResults.errors);
            }
          } else if (queryResults.data) {
            setData(queryResults.data);
          }

          setQueryInfo(queryData);
        } else {
          setErrors('Error: Fitbit data request - some parameters are missing');
        }
      } else {
        setErrors('Error: Fitbit request - bad data request');
      }
    } catch (error) {
      if (error && error.message) {
        setErrors(error.message);
      } else {
        setErrors('Error: Fitbit request failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  const sidebarToggled = () => {
    setOpenSidebar(!openSidebar);
  };

  const displayData = () => {
    if (
      (initialDataRequest && initialDataRequest.subject === 'Overview') ||
      queryInfo === 'Overview'
    ) {
      return (
        <DisplayParticipantOverviewData participant={participantInfo} key={0} />
      );
    } else if (data && queryInfo) {
      return (
        <DisplayRefinedData
          deviceName="Fitbit"
          subject={queryInfo.subject ? queryInfo.subject : null}
          data={data}
          queryInfo={queryInfo}
          participantInfo={participantInfo}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <Sidebar
        width={320}
        height={'100vh'}
        sidebarToggledCallback={sidebarToggled}
      >
        {participantInfo && (
          <>
            <FitbitParticipantDataForm
              getDataCallback={handleGetDataRequest}
              displayDataType={'refinedData'}
              initialDataRequest={initialDataRequest}
            />
          </>
        )}
      </Sidebar>
      <div className={openSidebar ? 'open-sidebar' : 'closed-sidebar'}>
        {loading ? <div className="spinnerModal"></div> : ''}
        <TrialInfo />
        <div className="participant-data-flex-container">
          <h3 className="device-name participant-data-flex-item">
            Participant Data - Fitbit
          </h3>
          {/* <h4>participant</h4> */}
          {participantInfo && (
            <>
              <div className="participant-data-flex-item">
                <span className="bold-text">ID:</span> {participantInfo.id}
              </div>
              <div className="participant-data-flex-item">
                <span className="bold-text">Email:</span>{' '}
                {participantInfo.email}
              </div>
            </>
          )}
        </div>
        {errors && <DisplayErrors errors={errors} />}
        {participantInfo && displayData()}
      </div>
    </div>
  );
};

FitbitParticipantDataPage.propTypes = {
  participant: PropTypes.object,
};

export default FitbitParticipantDataPage;
