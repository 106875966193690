import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import ChangePwdReqForm from './ChangePwdReqForm';
import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {useAuth} from '../authorization/AuthContext';

import './AuthForms.css';

const ChangePwdReqPage = () => {
  let navigate = useNavigate();
  const {logout} = useAuth();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSubmitPwdReq = async (data) => {
    try {
      setLoading(true);
      let res = await fetchApiSet('/auth/changePwdReq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
          newConfirmPassword: data.confirmNewPassword,
        }),
      });
      setLoading(false);
      if (res.errors) {
        throw new Error(`Error: Could not change password!`);
      } else {
        // TODO: send email to the user to notify that the password was changed
        // If everything is ok logout the user
        logout();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error && error.displayToUser) {
        if (error.errors) {
          if (error.errors[0].path && error.errors[0].msg) {
            setErrors(`${error.errors[0].path}: ${error.errors[0].msg}`);
          }
        } else if (error.message) {
          setErrors(error.message);
        }
      } else {
        setErrors('Error: change password request failed!');
      }
    }
  };

  const handleCancelBtnClicked = () => {
    navigate('/');
  };

  return (
    <>
      {loading ? <div className="spinnerModal"></div> : ''}
      <div className="centered-text">
        <h2>Change Password Request</h2>
        <h3>
          After a successful password change you will be logged out from your
          account!
        </h3>
        {errors && <DisplayErrors errors={errors} />}
      </div>
      <ChangePwdReqForm
        getDataCallback={handleSubmitPwdReq}
        cancelBtnClickedCallback={handleCancelBtnClicked}
      />
      <p className="centered-text">
        <Link to="/ResetPwdReqPage">Forgot Password?</Link>
      </p>
    </>
  );
};

export default ChangePwdReqPage;
