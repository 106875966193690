import {DateTime} from 'luxon';

import {GetParticipantDataFromFitbit} from './FitbitParticipantData';

const getTrackerActiveMinutes = async (queryData, participantInfo) => {
  let response = {data: null, errors: null};

  try {
    let queryResults = null;
    // In this case we need to collect data from several queries and put them together
    let lightlyActiveMinutes = null;
    let fairlyActiveMinutes = null;
    let veryActiveMinutes = null;
    let startDate = null;
    let endDate = null;
    if (queryData.period && queryData.period !== 'customDates') {
      startDate = queryData.period;
      endDate = DateTime.now().toISODate();
    } else if (queryData.startDate && queryData.endDate) {
      startDate = queryData.startDate;
      endDate = queryData.endDate;
    } else {
      throw new Error(`Please select a period`);
    }
    queryResults = await GetParticipantDataFromFitbit(
      participantInfo,
      `/activities/tracker/minutesLightlyActive/date/${endDate}/${startDate}.json`
    );
    if (queryResults.data) {
      lightlyActiveMinutes =
        queryResults.data['activities-tracker-minutesLightlyActive'];
    }
    queryResults = await GetParticipantDataFromFitbit(
      participantInfo,
      `/activities/tracker/minutesFairlyActive/date/${endDate}/${startDate}.json`
    );
    if (queryResults.data) {
      fairlyActiveMinutes =
        queryResults.data['activities-tracker-minutesFairlyActive'];
    }
    queryResults = await GetParticipantDataFromFitbit(
      participantInfo,
      `/activities/tracker/minutesVeryActive/date/${endDate}/${startDate}.json`
    );
    if (queryResults.data) {
      veryActiveMinutes =
        queryResults.data['activities-tracker-minutesVeryActive'];
    }
    response.data = lightlyActiveMinutes.map((item, index) => {
      let lightlyActive = Number(lightlyActiveMinutes[index].value);
      let fairlyActive = Number(fairlyActiveMinutes[index].value);
      let veryActive = Number(veryActiveMinutes[index].value);
      // Calc total of fairlyActive and veryActive only
      let totalFairlyVeryMins = fairlyActive + veryActive;
      return {
        dateTime: lightlyActiveMinutes[index].dateTime,
        lightlyActive: lightlyActive,
        fairlyActive: fairlyActive,
        veryActive: veryActive,
        totalFairlyVeryMins: totalFairlyVeryMins,
      };
    });
  } catch (error) {
    response.data = null;
    response.errors = 'Fitbit active minutes request failed!';
  }

  return response;
};

export {getTrackerActiveMinutes};
