import React from 'react';

import FitbitDevicesInfo from '../fitbit/participant/FitbitDeviceInfo';
import OmronDevicesInfo from '../omron/participant/OmronDeviceInfo';

import './DevicesInfo.css';

const DevicesInfo = ({participant}) => {
  {
    return Object.values(participant.auth_devices).map((value, key) => {
      switch (value.device) {
        case 'Fitbit':
          return (
            <FitbitDevicesInfo
              value={value}
              index={key}
              participant={participant}
              key={key}
            />
          );
        case 'Omron':
          return (
            <OmronDevicesInfo
              value={value}
              index={key}
              participant={participant}
              key={key}
            />
          );
        default:
          <></>;
          break;
      }
    });
  }
};

export default DevicesInfo;
