import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import {OmronApi} from '../api/OmronApi';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';
import Sidebar from '../../sidebar/Sidebar';
import OmronDisplayRefinedData from '../displayData/OmronDisplayRefinedData';
import OmronParticipantDataForm from './OmronParticipantDataForm';
import {DateToISODateOnlyShort} from '../../../services/date&time/DateTimeUtils';
import {safelyGetValueNestedObj} from '../../../services/objects/objectAccess';
import TrialInfo from '../../trials/TrialInfo';

const OmronParticipantDataPage = () => {
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [participantInfo, setParticipantInfo] = useState(null);
  const [data, setData] = useState(null);
  const [queryInfo, setQueryInfo] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [initialDataRequest, setInitialDataRequest] = useState(null);

  useEffect(() => {
    try {
      const data = location.state;
      const participant = data.participant;
      const query = data.query;

      if (participant) {
        setParticipantInfo(data.participant);
      }
      // If a query was sent from the other page display the data here
      if (query) {
        setInitialDataRequest(query);
      }
    } catch (error) {
      console.error(error);
      setErrors(
        "Something went wrong! We could not find participant's info for Omron device."
      );
    }
  }, [location]);

  const handleGetDataRequest = async (queryData) => {
    try {
      // Clear results and errors from previous query
      setData(null);
      setErrors(null);
      setQueryInfo(null);
      let queryResults = null;
      if (!queryData.category || queryData.category.length === 0) {
        throw new Error('Please select a Category');
      } else if (!queryData.startDate) {
        throw new Error('Please select a Start Date');
      }
      // Convert date to locale
      const date = DateToISODateOnlyShort(queryData.startDate);
      const sortOrder = queryData.sortOrder ? queryData.sortOrder : 'asc';
      const category = queryData.category ? queryData.category : '';
      // Note: due to an issue with Omron we don't send data type (category).
      // At this point even if we send data type we are
      // getting data of all types or getting errors. 03/14/2022
      const queryStr = {
        category: category,
        since: date,
        sortOrder: sortOrder,
      };
      if (queryData.resultsLimit > 0) {
        queryStr.limit = queryData.resultsLimit;
      }
      setLoading(true);
      queryResults = await OmronApi.GetParticipantDataApi(
        participantInfo.id,
        queryStr
      );
      if (queryResults.errors) {
        throw new Error(queryResults.errors);
      }
      // If everything is ok set the data state to be able to display it
      // Note: In some cases we are getting from Omron data for multiple activities
      // regardless of what we are asking for, we need to display only the data according to the query
      switch (queryData.category) {
        case 'bloodPressure':
          queryResults = safelyGetValueNestedObj(
            () => queryResults.data.bloodPressure,
            []
          );
          break;
        case 'weight':
          queryResults = safelyGetValueNestedObj(
            () => queryResults.data.weight,
            []
          );
          break;
      }

      if (queryResults) {
        setData(queryResults);
      }
      setQueryInfo(queryData);
    } catch (error) {
      console.error(error.message);
      // TODO: decide what kind of errors to display here
      if (error && error.message) {
        setErrors(error.message);
      } else {
        setErrors('Error: getting data from Omron server failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  // If there is an initial request from the previous page
  // to display data here, implement it and clear the flag
  // to make sure that it will happen only once
  if (initialDataRequest) {
    handleGetDataRequest(initialDataRequest);
    setInitialDataRequest(null);
  }

  const sidebarToggled = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <>
      {loading ? <div className="spinnerModal"></div> : ''}
      <Sidebar
        width={320}
        height={'100vh'}
        sidebarToggledCallback={sidebarToggled}
      >
        {participantInfo && (
          <>
            <h4>participant</h4>
            <p>ID: {participantInfo.id}</p>
            <p>Email: {participantInfo.email}</p>
          </>
        )}
        <OmronParticipantDataForm
          getDataCallback={handleGetDataRequest}
          displayDataType={'refinedData'}
        />
      </Sidebar>
      <div className={openSidebar ? 'open-sidebar' : 'closed-sidebar'}>
        {/* {loading ? <div className="spinnerModal"></div> : ''} */}
        <TrialInfo />
        <h2>Participant Data - Omron </h2>
        {errors && <DisplayErrors errors={errors} />}
        {data && queryInfo && (
          <OmronDisplayRefinedData
            deviceName="Omron"
            subject={queryInfo.subject ? queryInfo.subject : null}
            data={data}
            queryInfo={queryInfo}
          />
        )}
      </div>
    </>
  );
};

OmronParticipantDataPage.propTypes = {
  participant: PropTypes.object,
};

export default OmronParticipantDataPage;
