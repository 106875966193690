import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {IoEyeOffOutline} from 'react-icons/io5';
import {IoEyeOutline} from 'react-icons/io5';

import {useYupValidationResolver} from '../forms/YupValidationResolver';
import {UserAuthApi} from './UserAuthApi';
import {useAuth} from '../authorization/AuthContext';
import {onFormSubmitRecaptchaVerification} from '../common/googleRecaptcha';

import './LoginForm.css';

// Yup form validation rules
const validationSchema = yup.object({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

function LoginForm() {
  const resolver = useYupValidationResolver(validationSchema);
  const [loading, setLoading] = useState(false);
  const [regError, setRegError] = useState('');
  const [currentPasswordType, setCurrentPasswordType] = useState('password');
  const [displayCurrentPassword, setDisplayCurrentPassword] = useState(false);

  // Destructuring login from AuthContext
  const {login, logout} = useAuth();

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = async (data) => {
    await window.grecaptcha.ready(async () => {
      try {
        const captchaRes = await onFormSubmitRecaptchaVerification();
        if (captchaRes.errors) {
          // If reCAPTCHA version 3 failed, display an error
          throw new Error(captchaRes.errors);
        } else {
          try {
            setLoading(true);
            const res = await UserAuthApi.LoginApi(data);
            if (res) {
              if (res != null && res.user != null && res.user.role != null) {
                // Upon success send an api request to login the user
                console.log('Login successful');
                // Use context for login the user
                login(res.message, res.user.role);
              } else {
                let errMsg = 'Login error: ';
                if (res == null) {
                  errMsg += 'No response from server.';
                } else if (res.user == null) {
                  errMsg += 'User information is missing.';
                } else if (res.user.role == null) {
                  errMsg += 'User role is missing.';
                }
                throw new Error(errMsg);
              }
            }
          } catch (error) {
            console.error(`Login error: ${error}`);
            let errMsg = 'Login error';
            if (error && error.message) {
              errMsg = `${error.message}`;
            }
            // Always log out if errors occur, as the login might still be successful even with issues.
            logout();
            setRegError(errMsg);
          } finally {
            setLoading(false);
          }
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setRegError(error.message);
        } else {
          setRegError(`reCAPTCHA V3 verification failed!`);
        }
      }
    });
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  const handleToggleDisplayCurrentPassword = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDisplayCurrentPassword(!displayCurrentPassword);
    if (!displayCurrentPassword) {
      setCurrentPasswordType('text');
    } else {
      setCurrentPasswordType('password');
    }
  };

  return (
    <form
      className="login-form centered-container narrow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="centered-text">Log In to your account</h2>
      <div className="form-error centered-text">{regError}</div>
      {loading ? <div className="spinnerModal"></div> : ''}
      <fieldset>
        <label>*Username</label>
        <div className="form-error" aria-live="polite">
          {errors.username?.message}
        </div>
        <input
          name="username"
          type="text"
          //placeholder='username'
          {...register('username')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.username ? 'is-invalid' : ''}`}
        />

        <div className="flex-password-icon">
          <label className="flex-password-icon-item">*Password</label>
          <span
            className="form-btn flex-password-icon-item"
            onClick={handleToggleDisplayCurrentPassword}
          >
            {displayCurrentPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
          </span>
        </div>
        <div className="form-error" aria-live="polite">
          {errors.password?.message}
        </div>
        <input
          name="password"
          type={currentPasswordType}
          autoComplete="off"
          //placeholder='password'
          {...register('password')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.password ? 'is-invalid' : ''}`}
        />
      </fieldset>

      <div className="form-group centered-container">
        <button type="submit" className="btn btn-primary">
          Log In
        </button>
      </div>
    </form>
  );
}

export default LoginForm;
