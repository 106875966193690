import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';

import {useYupValidationResolver} from '../forms/YupValidationResolver';
import useApi from '../api/useApi';

import './AuthForms.css';

// Yup form validation rules
const validationSchema = yup.object({
  email: yup.string().required('Email is required').email('Email is invalid'),
  teamId: yup.string().required('Team is required'),
});

const SignUpApprovalForm = ({getDataCallback, cancelBtnClickedCallback}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const [regError, setRegError] = useState('');
  const [teams, setTeams] = useState([]);

  // Retrieve all existing teams from database.
  const {
    data: teamsData,
    loading: apiLoading,
    error: apiError,
  } = useApi(`/team`);

  useEffect(() => {
    if (teamsData) {
      setTeams(teamsData);
    }
  }, [teamsData]);

  const {
    handleSubmit,
    register,
    formState: {errors},
    setValue,
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = (data) => {
    // Upon success send data back to the parent
    getDataCallback(data);
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  return (
    <>
      <form
        className="signup-approval-form centered-container narrow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="signup-approval-form-msg">
          Enter the email of the user that needs to be approved.
        </div>
        <div className="form-error centered-text">{regError}</div>
        {apiError && <div className="form-error centered-text">{apiError}</div>}
        {apiLoading ? <div className="spinnerModal"></div> : ''}
        <fieldset>
          <label>*Email</label>
          <div className="form-error" aria-live="polite">
            {errors.email?.message}
          </div>
          <input
            name="email"
            type="text"
            {...register('email')}
            onFocus={setRegErrorMessage}
            className={`form-input ${errors.email ? 'is-invalid' : ''}`}
          />
          <div className="form-item">
            <div>
              <label>Role</label>
            </div>
            <div className="form-error" aria-live="polite">
              {errors.role?.message}
            </div>
            <div>
              <select
                name="role"
                type="text"
                className="signup-form-select"
                {...register('role')}
              >
                <option value="Viewer">Viewer</option>
                <option value="User">User</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
          </div>
          <div className="form-item">
            <label>Team</label>
            <div className="form-error" aria-live="polite">
              {errors.team?.message}
            </div>
            <div>
              <select
                className="signup-form-select"
                name="team"
                type="text"
                onChange={(e) => {
                  const [id, name] = e.target.value.split('|');
                  setValue('teamId', id);
                  setValue('teamName', name);
                }}
              >
                <option value="">Select a team</option>
                {teams &&
                  teams.map((team) => (
                    <option
                      key={team.id}
                      value={`${team.id}|${team.team_name}`}
                    >
                      {team.team_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </fieldset>

        <div className="form-group centered-text">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          <button
            type="button"
            className="btn cancel-btn"
            onClick={() => cancelBtnClickedCallback()}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

SignUpApprovalForm.propTypes = {
  getDataCallback: PropTypes.func,
  cancelBtnClickedCallback: PropTypes.func,
};
export default SignUpApprovalForm;
