import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import CustomToolTip from './CustomTooltip';

const BarChartDraw = ({content}) => {
  const {data, chartInfo, xAxisKey, displayStyle, subject} = content;

  const {
    stacked = null,
    height = 300,
    customTooltip = false,
  } = displayStyle ? displayStyle : '';

  return (
    <ResponsiveContainer width="98%" height={height} debounce={1}>
      <BarChart data={data}>
        {xAxisKey.map((item, key) => {
          return (
            <XAxis
              key={key}
              xAxisId={key}
              dataKey={item}
              fontSize={12}
              dy={5} /*distance between label text and axis*/
            />
          );
        })}
        <YAxis type="number" fontSize={12} />
        {customTooltip ? (
          <Tooltip
            cursor={false}
            content={<CustomToolTip chartInfo={chartInfo} subject={subject} />}
            position={{y: 0}}
          />
        ) : (
          <Tooltip cursor={false} />
        )}
        {chartInfo.map((item, key) => {
          return (
            <Bar
              key={key}
              stackId={stacked}
              dataKey={item.valueName}
              name={item.valueName}
              fill={item.valueColor}
              unit={item.units}
            ></Bar>
          );
        })}
        <Legend />
      </BarChart>
    </ResponsiveContainer>
  );
};

BarChartDraw.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default BarChartDraw;
