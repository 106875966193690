import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Icon} from '@iconify/react';
import {GiWeightScale} from 'react-icons/gi';
import ReactTooltip from 'react-tooltip';

const OmronDeviceInfo = ({value, index, participant}) => {
  {
    let navigate = useNavigate();

    class OmronQueryData {
      constructor() {
        this.category = null;
        this.startDate = null;
        this.limit = 0; // Display all results
        this.sortOrder = 'asc';
      }
    }

    const handleDeviceCategoryCLicked = (participant, deviceType, category) => {
      const queryData = new OmronQueryData();
      // In this case we always display data from last 7 days
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      queryData.startDate = startDate;
      switch (category) {
        case 'bloodPressure':
          queryData.category = 'bloodPressure';
          queryData.subject = 'Blood Pressure';
          return navigate('/OmronParticipantDataPage', {
            state: {participant: participant, query: queryData},
          });
        case 'weight':
          queryData.category = 'weight';
          queryData.subject = 'Weight';
          return navigate('/OmronParticipantDataPage', {
            state: {participant: participant, query: queryData},
          });
      }
    };

    // Display info about Omron devices authorized by the participant
    return (
      <div className="devices-info" key={index}>
        {index > 0 && <hr></hr>}
        <div className="devices-info-flex">
          {value.device && (
            <div className="devices-basic-info-name">{value.device}</div>
          )}
        </div>
        <br />
        <div className="devices-icons-flex">
          {/* Blood Pressure */}
          <button
            className="device-category-btn"
            onClick={() =>
              handleDeviceCategoryCLicked(participant, 'Omron', 'bloodPressure')
            }
          >
            <div data-tip data-for="blood-pressure">
              <Icon
                className="blood-pressure-icon device-category-icon"
                icon="healthicons:blood-pressure-2"
              />
            </div>
            <ReactTooltip
              id="blood-pressure"
              aria-haspopup="true"
              effect="float"
              className="custom-tooltip"
            >
              Blood Pressure
            </ReactTooltip>
          </button>
          <button
            className="device-category-btn"
            onClick={() =>
              handleDeviceCategoryCLicked(participant, 'Omron', 'weight')
            }
          >
            <GiWeightScale
              className="heart-rate-icon device-category-icon"
              data-tip
              data-for="weight"
            />
            <ReactTooltip
              id="weight"
              aria-haspopup="true"
              effect="float"
              className="custom-tooltip"
            >
              Weight
            </ReactTooltip>
          </button>
        </div>
      </div>
    );
  }
};

OmronDeviceInfo.propTypes = {
  index: PropTypes.number,
  value: PropTypes.object,
  participant: PropTypes.object,
};

export default OmronDeviceInfo;
