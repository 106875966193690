import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  // Calc text location
  const radius = innerRadius + (outerRadius - innerRadius) * 1.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="red"
      textAnchor="middle"
      dominantBaseline="central"
      points="top"
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

const CustomTooltip = ({active, payload}) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'white',
          padding: '5px',
          border: '1px solid light-gray',
        }}
      >
        <label>{`${payload[0].name}`}</label>
      </div>
    );
  }

  return null;
};

const PieChartDraw = ({content}) => {
  const {data, pieChartInfo, displayStyle} = content;
  const {height = 300} = displayStyle ? displayStyle : '';

  // Create an array of objects with the name and value of the data that we want to display
  // according to the pieChartInfo
  let payload = [];
  pieChartInfo.map((info) => {
    Object.entries(data[0]).map((item) => {
      if (item[0] === info.valueName) {
        payload.push({name: item[0], value: item[1]});
      }
    });
  });

  // Create and array of colors according to the pieChartInfo
  const COLORS = pieChartInfo.map((item) => {
    return item.valueColor;
  });

  return (
    <ResponsiveContainer width="98%" height={height} debounce={1}>
      <PieChart>
        <Pie
          data={payload}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          labelLine={true}
          label={renderCustomizedLabel}
          outerRadius={'70%'}
          fill="#8884d8"
        >
          {payload.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {/* <Legend layout="vertical" verticalAlign="middle" align="right" /> */}
      </PieChart>
    </ResponsiveContainer>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
  percent: PropTypes.number,
};

PieChartDraw.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default PieChartDraw;
