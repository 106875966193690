import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import {FitbitApi} from '../api/FitbitApi';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';
import {normalizeResponseErrors} from '../../errorHandling/NormalizeErrors';
import {useTrialInfo} from '../../trials/TrialInfoContext';
import SimpleYesNoConfirmForm from '../../common/SimpleYesNoConfirmForm';

const FitbitManageParticipantToken = ({participant}) => {
  const {id: participantId, email: participantEmail} = participant;
  let navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [message, setMsg] = useState(null);
  const [displayRevokeMassage, setDisplayRevokeMassage] = useState(false);
  const {getCurrentTrialInfo} = useTrialInfo();

  // TODO: display errors and/or other messages to users
  const RefreshToken = async () => {
    try {
      // Clear messages
      setErrors(null);
      setMsg(null);
      let res = await FitbitApi.RefreshTokenApi(participantId);
      res = await normalizeResponseErrors(res);
      const data = await res.json();
      if (data.message === 'ok') {
        console.log(`Refresh Token OK`);
        setMsg(`Refresh was successful!`);
      } else {
        throw new Error(
          'Something went wrong! We could not refresh the access token.'
        );
      }
    } catch (error) {
      console.error(error);
      if (error && error.displayToUser && error.message) {
        setErrors(`Error:  ${error.message}!`);
      } else {
        setErrors(
          'Something went wrong! We could not refresh the access token.'
        );
      }
    }
  };

  const handleRevokeAnswer = async (answer) => {
    // Close the message modal
    setDisplayRevokeMassage(false);
    if (answer === true) {
      try {
        let res = await FitbitApi.RevokeAccessTokenApi(participantId);
        res = await normalizeResponseErrors(res);
        const data = await res.json();
        if (data.message === 'ok') {
          console.log(`Revoke access token OK`);
          // TODO: instead of going back to current trial page
          // stay on the current page and disable related buttons
          const trialInfo = getCurrentTrialInfo();
          navigate('/TrialPage', {
            state: trialInfo,
          });
        } else {
          throw new Error(
            'Something went wrong! We could not revoke the access token.'
          );
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setErrors(`Error:  ${error.message}!`);
        } else {
          setErrors(
            'Something went wrong! We could not revoke the access token.'
          );
        }
      }
    }
  };

  const handleCancelRevoke = () => {
    setDisplayRevokeMassage(false);
  };

  const RevokeAccessToken = async () => {
    // Clear messages
    setErrors(null);
    setMsg(null);
    setDisplayRevokeMassage(true);
  };

  return (
    <div className="">
      <div>
        <h3>Access Permission!</h3>
        {message && <div className="success-message">{message}</div>}
        {errors && <DisplayErrors errors={errors} />}
        {displayRevokeMassage && (
          <SimpleYesNoConfirmForm
            title={`Revoke Fitbit access of: ${participantEmail}?`}
            text={`If you select Submit, you will need to request the participant's authorization again in order to view the data.`}
            returnAnswerCallback={handleRevokeAnswer}
            cancelCallback={handleCancelRevoke}
          />
        )}
        <button type="button" className="btn" onClick={RefreshToken}>
          Refresh Access
        </button>
        <button type="button" className="btn" onClick={RevokeAccessToken}>
          Revoke Access
        </button>
      </div>
    </div>
  );
};

FitbitManageParticipantToken.propTypes = {
  participant: PropTypes.object,
};

export default FitbitManageParticipantToken;
