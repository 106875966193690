import React from 'react';
import PropTypes from 'prop-types';
import {Link, Navigate} from 'react-router-dom';

import SignupForm from './SignupForm';
import {useAuth} from '../authorization/AuthContext';

import './AuthForms.css';

const SignupPage = ({email, role, teamId, teamName}) => {
  const {currentUser} = useAuth();
  // If user is already logged in redirect to the dashboard
  if (currentUser) {
    return <Navigate to="/" />;
  }
  return (
    <div className="signup">
      <div className="centered-container centered-text content">
        <h1>Welcome to CDConnect™</h1>
      </div>
      <SignupForm
        email={email}
        role={role}
        teamId={teamId}
        teamName={teamName}
      />
      <div className="centered-text">
        <p>
          Already Signed up?
          <Link to="/LoginPage"> Log In</Link>
        </p>
      </div>
    </div>
  );
};

SignupPage.propTypes = {
  email: PropTypes.string,
  role: PropTypes.string,
  teamId: PropTypes.string,
  teamName: PropTypes.string,
};

export default SignupPage;
