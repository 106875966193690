import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';

import {OmronApi} from '../api/OmronApi';
import {
  OmronPrepareBloodPressure,
  OmronPrepareWeight,
} from '../displayData/OmronPrepareDataForDisplay';
import GraphicDisplayTile from '../../tiles/GraphicDisplayTile';
import DisplayDataChartTable from '../../displayData/DisplayDataChartTable';
import {safelyGetValueNestedObj} from '../../../services/objects/objectAccess';

const OmronParticipantOverview = (props) => {
  const participant = props.participant;
  //   const [bloodPressure, setBloodPressure] = useState([]);
  const [participantData, setParticipantData] = useState(null);
  const [loading, setLoading] = useState(false);
  //   const [errors, setErrors] = useState('');

  // Gather participant's data from the server and prepare it for display
  useEffect(() => {
    const GetParticipantData = async (participant) => {
      try {
        let results = [];
        let obj = {};

        setLoading(true);
        // Get the data from Omron
        // Display all the results from the last 7 days
        let startDate = DateTime.local().minus({days: 7}).toISODate();
        const queryStr = {since: startDate, sortOrder: 'asc'};
        let result = await OmronApi.GetParticipantDataApi(
          participant.id,
          queryStr
        );
        if (result.errors) {
          throw new Error(result.errors);
        }
        // If everything is ok set the data state to be able to display it
        // Blood Pressure
        const bloodPressure = safelyGetValueNestedObj(
          () => result.data.bloodPressure,
          []
        );
        obj = {};
        obj.subject = 'Blood Pressure';
        if (bloodPressure && bloodPressure.length > 0) {
          obj.data = bloodPressure;
          obj.chartType = 'LineChart';
          obj = OmronPrepareBloodPressure(obj);
        } else {
          obj.errors = 'No data to display';
        }
        results.push(obj);

        // Weight
        const weight = safelyGetValueNestedObj(() => result.data.weight, []);
        obj = {};
        obj.subject = 'Weight';
        if (weight && weight.length > 0) {
          obj.data = weight;
          obj.chartType = 'LineChart';
          obj = OmronPrepareWeight(obj);
        } else {
          obj.errors = 'No data to display';
        }
        results.push(obj);
        setParticipantData(results);
      } catch (error) {
        console.error(error.message);
        // TODO: decide what kind of errors to display here
        if (error) {
          // setErrors(error.message);
        } else {
          //   setErrors('Error: getting data from Omron server failed!');
        }
      } finally {
        setLoading(false);
      }
    };

    if (participant) {
      GetParticipantData(participant);
    }
  }, [participant]);

  return (
    <>
      <hr />
      <h2>Omron</h2>
      {loading ? <div className="spinnerModal"></div> : ''}
      <Link
        className="link-text"
        to="/OmronParticipantDataPage"
        state={{participant: participant}}
      >
        More Omron Data
      </Link>
      <div className="flex-container">
        {participantData &&
          participantData.length > 0 &&
          Object.values(participantData).map((item, key) => {
            return (
              <GraphicDisplayTile info={item} key={key} className="">
                <DisplayDataChartTable
                  deviceName={'Omron'}
                  subject={item.subject}
                  content={item}
                />
              </GraphicDisplayTile>
            );
          })}
      </div>
    </>
  );
};

OmronParticipantOverview.propTypes = {
  participant: PropTypes.object,
};

export default OmronParticipantOverview;
