import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  /*AiOutlineEdit,*/ AiOutlineDelete,
  AiOutlineClose,
} from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import useApi from '../api/useApi';
import {fetchApiSet} from '../api/fetchApi';
import Table from '../table/Table';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import Modal from '../modal/Modal';
import NewTrialUsersForm from './NewTrialUsersForm';
// import EditTrialUsersForm from './EditTrialUsersForm';
import DeleteTrialUsersForm from './DeleteTrialUsersForm';

import './TrialUsersPage.css';

const TrialUsersPage = ({trialInfo}) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [trialUsersAction, setTrialUsersAction] = useState(null);
  const [error, setError] = useState('');
  const [trialUsersInfo, setTrialUsersInfo] = useState({});
  const [trialUsersData, setTrialUsersData] = useState([]);

  const {trialId, trialOwnerEmail} = trialInfo;

  // Get a list of all the users of this trial
  const {
    data: existingUsersData,
    loading,
    error: apiErrors,
  } = useApi(`/trialUsers/${trialId}`);

  useEffect(() => {
    if (existingUsersData) {
      setTrialUsersData(existingUsersData);
    }
  }, [existingUsersData]);

  const setStateAddUser = (data) => {
    try {
      const {userId, userEmail} = data;
      const updatedTrial = [
        ...trialUsersData,
        {
          id: userId,
          email: userEmail,
        },
      ];
      setTrialUsersData(updatedTrial);
    } catch (error) {
      setError(
        'The user has been added, but the display is wrong. Please refresh the page!'
      );
    }
  };

  const handleSubmitDataAddUser = async (data) => {
    // Upon form submit send an api request to add the user to the db of the current trial
    try {
      setTrialUsersAction(null);
      setLocalLoading(true);
      let url = `/trialUsers`;
      let res = await fetchApiSet(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({data}),
      });
      if (res.errors) {
        throw new Error('Adding the user to the trial failed');
      } else {
        setStateAddUser(res.data);
      }
    } catch (error) {
      if (error && error.displayToUser && error.message) {
        setError(`Error:  ${error.message}!`);
      } else {
        if (error && error.displayToUser && error.message) {
          setError(`Error:  ${error.message}!`);
        } else {
          setError('Adding the user to the trial failed!');
        }
      }
    } finally {
      setLocalLoading(false);
    }
  };

  const handleAddNewUser = () => {
    setTrialUsersAction('addUser');
  };

  // const setStateUpdateTrialUsers = (data) => {
  //   const updatedTrialUser = {
  //     id: data.id,
  //     emil: data.email,
  //   };
  //   const updatedTrialUsers = trialUsersData.map((user) => {
  //     return user.id === data.id ? updatedTrialUser : user;
  //   });

  //   trialUsersData(updatedTrialUsers);
  // };

  // const handleSubmitDataEditTrialUser = async (data) => {
  //   // Upon form submit send an api request to update the db
  //   try {
  //     setTrialUsersAction(null);
  //     setLocalLoading(true);
  //     let url = `/trialUsers`;
  //     let res = await fetchApiSet(url, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({data}),
  //     });
  //     if (res.errors) {
  //       throw new Error(res.errors);
  //     }
  //     setStateUpdateTrialUsers(data);
  //   } catch (error) {
  //     if (error && error.displayToUser && error.message) {
  //       setError(`Error:  ${error.message}!`);
  //     } else {
  //       setError('Updating user failed');
  //     }
  //   } finally {
  //     setLocalLoading(false);
  //   }
  // };

  // const handleEditUser = (row) => {
  //   setTrialUsersInfo({
  //     userId: row.id,
  //     userEmail: row.email,
  //   });
  //   setTrialUsersAction('editUser');
  // };

  const setStateDeleteTrialUser = (data) => {
    try {
      // Delete the from the current trial
      const updatedTrials = trialUsersData.filter((user) => {
        return user.id !== data.userId;
      });
      setTrialUsersData(updatedTrials);
    } catch (error) {
      setError(
        'The user has been deleted, but the display is wrong. Please refresh the page!'
      );
    }
  };

  const handleSubmitDeleteTrialUser = async (data) => {
    // Upon form submit send an api request to delete the trial from the db
    try {
      setTrialUsersAction(null);
      setLocalLoading(true);
      // Add the trial id to the data
      const newData = {...data, trialId: trialInfo.trialId};
      let url = `/trialUsers`;
      let res = await fetchApiSet(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({data: newData}),
      });
      if (res.errors) {
        if (res.errors === 'Unauthorized') {
          throw new Error('Unauthorized');
        }
        throw new Error('Deleting user failed');
      }
      setStateDeleteTrialUser(newData);
    } catch (error) {
      if (error && error.displayToUser && error.message) {
        setError(`Error:  ${error.message}!`);
      } else {
        setError('Deleting user from trial failed');
      }
    } finally {
      setLocalLoading(false);
    }
  };

  const handleDeleteTrialUser = (row) => {
    setTrialUsersInfo({
      userId: row.id,
      userEmail: row.email,
    });
    setTrialUsersAction('deleteUser');
  };

  const handleCancelTrialUsersEdit = () => {
    setTrialUsersAction(null);
  };

  const closeModal = () => {
    setTrialUsersAction(null);
  };

  const handleTrialUsersAction = () => {
    switch (trialUsersAction) {
      case 'addUser':
        return (
          <Modal title={'Add User'} text={''} closeModalCallback={closeModal}>
            <NewTrialUsersForm
              trialInfo={trialInfo}
              getTrialUsersDataCallback={handleSubmitDataAddUser}
              cancelTrialUsersEditCallback={handleCancelTrialUsersEdit}
            />
          </Modal>
        );
      // case 'editUser':
      //   return (
      //     <Modal title={'Edit User'} text={''} closeModalCallback={closeModal}>
      //       <EditTrialUsersForm
      //         trialUserInfo={trialUsersInfo}
      //         getTrialUsersDataCallback={handleSubmitDataEditTrialUser}
      //         cancelTrialUsersEditCallback={handleCancelTrialUsersEdit}
      //       />
      //     </Modal>
      //   );
      case 'deleteUser':
        return (
          <Modal
            title={'Delete User?'}
            text={''}
            closeModalCallback={closeModal}
          >
            <DeleteTrialUsersForm
              trialUserInfo={trialUsersInfo}
              getTrialUsersDataCallback={handleSubmitDeleteTrialUser}
              cancelTrialUsersDeleteCallback={handleCancelTrialUsersEdit}
            />
          </Modal>
        );
    }
  };

  const handleDeleteErrorClicked = () => {
    setError('');
  };

  const trialUsersColumns = [
    {Header: 'Id', accessor: 'id', disableFilters: true},
    {Header: 'Email', accessor: 'email', disableFilters: true},
    {
      Header: '',
      accessor: 'links',
      Cell: function Cell(row) {
        let userRow = row.row.original;
        return (
          <div>
            <div>
              {/* <button
                type="button"
                className="trial-users-flex-item icon-btn-no-background"
                onClick={() => handleEditUser(userRow)}
              >
                <AiOutlineEdit
                  className="edit-trial-users-icon"
                  data-tip
                  data-for="edit-trial-user"
                />
                <ReactTooltip
                  id="edit-trial-user"
                  aria-haspopup="true"
                  effect="float"
                  className="custom-tooltip"
                >
                  Edit User
                </ReactTooltip>
              </button> */}
              {/* In case that user is the owner of the trial don't give the option to delete the row
              just display Trial Owner instead*/}
              {userRow.email !== trialOwnerEmail ? (
                <button
                  type="button"
                  className="trial-users-flex-item icon-btn-no-background"
                  onClick={() => handleDeleteTrialUser(userRow)}
                >
                  <AiOutlineDelete
                    className="delete-trial-users-icon"
                    data-tip
                    data-for="delete-trial-user"
                  />
                  <ReactTooltip
                    id="delete-trial-user"
                    aria-haspopup="true"
                    effect="float"
                    className="custom-tooltip"
                  >
                    Delete User
                  </ReactTooltip>
                </button>
              ) : (
                <div>Trial Owner</div>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {loading || localLoading ? <div className="spinnerModal"></div> : ''}
      {apiErrors && <DisplayErrors errors={apiErrors} />}
      {error && (
        <div className="trial-users-flex-container">
          <button
            className="trial-users-flex-item"
            id="trial-users-close-err-btn"
            onClick={() => handleDeleteErrorClicked()}
          >
            <AiOutlineClose data-tip data-for="close-error-msg" />
            <ReactTooltip
              id="close-error-msg"
              aria-haspopup="true"
              effect="float"
              className="custom-tooltip"
            >
              Close
            </ReactTooltip>
          </button>
          <DisplayErrors errors={error} className="trial-users-flex-item" />
        </div>
      )}
      {trialUsersAction && handleTrialUsersAction()}
      <div className="flex-title">
        <h2 className="trial_users_flex-item">Users</h2>
        <button
          className="trial_users_flex-item new-trial-users-btn clickable-title"
          onClick={handleAddNewUser}
        >
          Add User
        </button>
      </div>
      {trialUsersData && trialUsersData.length > 0 && (
        <div>
          <Table
            data={trialUsersData}
            columns={trialUsersColumns}
            dataCallback={''}
            features={{
              singleRowSelection: false,
              multipleRowsSelection: false,
              maxNumOfSelectedRows: null,
              displayGlobalFilter: false,
            }}
          />
        </div>
      )}
    </div>
  );
};

TrialUsersPage.propTypes = {
  trialInfo: PropTypes.object,
};

export default TrialUsersPage;
