import {safelyGetValueNestedObj} from '../../../services/objects/objectAccess';
import {kgsToLbs} from '../../../services/units/unitsConversion';
import {DateTime} from 'luxon';

const OmronPrepareBloodPressure = (content) => {
  const data = content.data;

  content.data = data.map((item) => {
    let localDateTime = safelyGetValueNestedObj(() => item.dateTimeLocal, '');
    // Convert date to short localized date and time with seconds
    localDateTime = DateTime.fromISO(localDateTime).toFormat('f');
    let systolic = Number(safelyGetValueNestedObj(() => item.systolic, 0));
    let diastolic = Number(safelyGetValueNestedObj(() => item.diastolic, 0));
    let pulse = Number(safelyGetValueNestedObj(() => item.pulse, 0));
    return {
      date: localDateTime,
      systolic: systolic,
      diastolic: diastolic,
      pulse: pulse,
    };
  });

  // Add info of how to display the data in the chart
  content.chartInfo = [
    {valueName: 'systolic', valueColor: '#041a94', units: ' mmHg'},
    {valueName: 'diastolic', valueColor: '#07e843', units: ' mmHg'},
    {valueName: 'pulse', valueColor: '#f21d56', units: ' BPM'},
  ];
  content.xAxisKey = ['date'];
  // Add info of how to display the data in the table
  content.columns = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Systolic (mmHg)',
      accessor: 'systolic',
    },
    {
      Header: 'Diastolic (mmHg)',
      accessor: 'diastolic',
    },
    {
      Header: 'Pulse (BPM)',
      accessor: 'pulse',
    },
  ];
  return content;
};

const OmronPrepareWeight = (content) => {
  const data = content.data;

  content.data = data.map((item) => {
    let localDateTime = safelyGetValueNestedObj(() => item.dateTimeLocal, '');
    // Convert date to short localized date and time with seconds
    localDateTime = DateTime.fromISO(localDateTime).toFormat('f');
    let weight = Number(safelyGetValueNestedObj(() => item.weight, 0));
    // Convert to Lbs
    weight = kgsToLbs(weight);
    return {
      date: localDateTime,
      weight: weight,
    };
  });

  // Add info of how to display the data in the chart
  content.chartInfo = [
    {valueName: 'weight', valueColor: '#041a94', units: ' Lbs'},
  ];
  content.xAxisKey = ['date'];
  // Add info of how to display the data in the table
  content.columns = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Weight (Lbs)',
      accessor: 'weight',
    },
  ];
  return content;
};

export {OmronPrepareBloodPressure, OmronPrepareWeight};
