import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';

import {useYupValidationResolver} from '../forms/YupValidationResolver';
import {ParticipantAuthApi} from './ParticipantAuthApi';

// Yup form validation rules
const validationSchema = yup.object({
  email: yup.string().required('Email is required').email('Email is invalid'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password can not be less than 8 characters!')
    .max(20, 'Password can not be more than 20 characters!')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\\!#$%()*+,-./:;=?@[\]^_`{|}~])[A-Za-z\d\\!#$%()*+,-./:;=?@[\]^_`{|}~]+$/,
      'Must have at least One Uppercase, One Lowercase, One Number, and One special character  \\!#$%()*+,-./:;=?@[]^_`{|}~'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  acceptTerms: yup.bool().oneOf([true], 'Accept Terms is required'),
  acceptPrivacy: yup.bool().oneOf([true], 'Accept Privacy Policy is required'),
});

function SignupForm() {
  const resolver = useYupValidationResolver(validationSchema);
  const [loading, setLoading] = useState(false);
  const [regError, setRegError] = useState('');
  let navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = (data) => {
    setLoading(true);
    // Upon success send an api request to signup the participant
    // username is the email address!
    data.username = data.email;
    ParticipantAuthApi.SignupApi(data)
      .then((res) => {
        setLoading(false);
        if (!res) {
          setRegError('Signup failed');
        }
        // console.log('Signup successful');
        navigate('/LoginPage');
      })
      .catch((error) => {
        setRegError(`Signup unsuccessful: ${error}`);
        setLoading(false);
      });
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  return (
    <form
      className="signup-form centered-container narrow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="centered-text">Please Sign Up</h2>
      <div className="form-error centered-text">{regError}</div>
      {loading ? <div className="spinnerModal"></div> : ''}
      <fieldset>
        <p>Only items marked with (*) are required.</p>
        <label>*Email (Username)</label>
        <div className="form-error" aria-live="polite">
          {errors.email?.message}
        </div>
        <input
          name="email"
          type="text"
          //placeholder='email'
          {...register('email')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.email ? 'is-invalid' : ''}`}
        />
        <label>*Password</label>
        <div className="form-error" aria-live="polite">
          {errors.password?.message}
        </div>
        <input
          name="password"
          type="password"
          autoComplete="off"
          //placeholder='password'
          {...register('password')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.password ? 'is-invalid' : ''}`}
        />
        <label>*Confirm Password</label>
        <div className="form-error" aria-live="polite">
          {errors.confirmPassword?.message}
        </div>
        <input
          name="confirmPassword"
          type="password"
          autoComplete="off"
          //placeholder='Confirm Password'
          {...register('confirmPassword')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.confirmPassword ? 'is-invalid' : ''}`}
        />
        <div>
          <input
            name="acceptTerms"
            type="checkbox"
            {...register('acceptTerms')}
            id="acceptTerms"
            className={`form-check-input'${
              errors.acceptTerms ? 'is-invalid' : ''
            }`}
          />
          <label htmlFor="acceptTerms" className="">
            *Accept Terms & Conditions
          </label>
          <div className="form-error" aria-live="polite">
            {errors.acceptTerms?.message}
          </div>
        </div>
        <div>
          <input
            name="acceptPrivacy"
            type="checkbox"
            {...register('acceptPrivacy')}
            id="acceptPrivacy"
            className={`form-check-input'${
              errors.acceptPrivacy ? 'is-invalid' : ''
            }`}
          />
          <label htmlFor="acceptPrivacy" className="">
            *Accept Privacy Policy
          </label>
          <div className="form-error" aria-live="polite">
            {errors.acceptPrivacy?.message}
          </div>
        </div>
      </fieldset>

      <div className="form-group">
        <button type="submit" className="btn btn-primary">
          Sign Up
        </button>
      </div>
    </form>
  );
}

export default SignupForm;
