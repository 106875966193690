import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import ReactTooltip from 'react-tooltip';

import {useYupValidationResolver} from '../forms/YupValidationResolver';
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';

import '../modal/ModalForm.css';
import './TrialForm.css';

// Yup form validation rules
const validationSchema = yup.object({
  trialNumber: yup.string().required('Trial Number is required'),
  trialName: yup.string().required('Trial Name is required'),
});

function EditTrialForm({
  trial,
  getTrialsDataCallback,
  cancelTrialsEditCallback,
}) {
  const resolver = useYupValidationResolver(validationSchema);
  const [regError, setRegError] = useState('');
  const [messageCharCount, setMessageCharCount] = useState(0);

  const handleTextChange = (evt) => {
    evt.preventDefault();
    setMessageCharCount(evt.target.value.length);
  };

  const {trialId, trialNumber, trialName, trialNotes} = trial;

  const {
    handleSubmit,
    register,
    reset,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = (data) => {
    // Upon success return data to the parent component
    getTrialsDataCallback(data);
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  const handleCancelTrialsEdit = () => {
    cancelTrialsEditCallback();
  };

  // Insert current values to form's fields
  useEffect(() => {
    reset({
      trialId: trialId,
      trialNumber: trialNumber,
      trialName: trialName,
      trialNotes: trialNotes,
    });
  }, [reset, trialId, trialNumber, trialName, trialNotes]);

  // Display the current number of chars after the form is loaded
  useEffect(() => {
    setMessageCharCount(trialNotes.length);
  }, [trialNotes]);

  return (
    <form className="modal-form trials-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-error centered-text">{regError}</div>
      <fieldset>
        <p>Only items marked with (*) are required.</p>
        <input type="hidden" {...register(`trialId`)} />
        <div className="form-item">
          <div>
            <label>*Trial Number</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.trialNumber?.message}
          </div>
          <div>
            <input
              name="trialNumber"
              type="input"
              {...register('trialNumber')}
              onFocus={setRegErrorMessage}
              className={`form-input ${errors.trialNumber ? 'is-invalid' : ''}`}
            />
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>*Trial Name</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.trialName?.message}
          </div>
          <div>
            <input
              name="trialName"
              type="input"
              {...register('trialName')}
              onFocus={setRegErrorMessage}
              className={`form-input ${errors.trialName ? 'is-invalid' : ''}`}
            />
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>Notes</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.trialNotes?.message}
          </div>
          <div>
            <textarea
              name="trialNotes"
              type="input"
              {...register('trialNotes')}
              onFocus={setRegErrorMessage}
              onChange={(evt) => handleTextChange(evt)}
              maxLength={300}
              className={`form-input ${errors.trialNotes ? 'is-invalid' : ''}`}
            />
            <p>{messageCharCount}/300</p>
          </div>
        </div>
      </fieldset>
      <div className="form-Id icons-flex-container">
        <button type="submit" className="submit-btn">
          <AiOutlineCheck
            className="submit-icon"
            data-tip
            data-for="submit-edit"
          />
          <ReactTooltip
            id="submit-edit"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Submit
          </ReactTooltip>
        </button>
        <button
          type="button"
          className="cancel-btn"
          onClick={handleCancelTrialsEdit}
        >
          <AiOutlineClose
            className="cancel-icon"
            data-tip
            data-for="cancel-edit"
          />
          <ReactTooltip
            id="cancel-edit"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Cancel
          </ReactTooltip>
        </button>
      </div>
    </form>
  );
}

EditTrialForm.propTypes = {
  trial: PropTypes.object,
  getTrialsDataCallback: PropTypes.func,
  cancelTrialsEditCallback: PropTypes.func,
};

export default EditTrialForm;
