import React from 'react';
import {RadialBarChart, RadialBar, Legend} from 'recharts';
const data = [
  {
    name: '18-24',
    uv: 31,
    pv: 2400,
    fill: '#8884d8',
  },
  {
    name: '25-29',
    uv: 26,
    pv: 4567,
    fill: '#83a6ed',
  },
  {
    name: '30-34',
    uv: 17,
    pv: 1398,
    fill: '#8dd1e1',
  },
];
const style = {
  top: 0,
  left: 350,
  lineHeight: '24px',
};
const RadialBarChartDraw = () => {
  return (
    <div className="App">
      <RadialBarChart
        width={500}
        height={300}
        cx={150}
        cy={150}
        innerRadius={20}
        outerRadius={140}
        barSize={10}
        data={data}
      >
        <RadialBar
          minAngle={15}
          label={{position: 'insideStart', fill: '#fff'}}
          background
          clockWise
          dataKey="uv"
        />
        <Legend
          iconSize={10}
          width={120}
          height={140}
          layout="vertical"
          verticalAlign="middle"
          wrapperStyle={style}
        />
      </RadialBarChart>
    </div>
  );
};

export default RadialBarChartDraw;
