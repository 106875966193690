// We are using the oauth2 Authorization Code Grant Flow to obtain consent from Fitbit
// For more information refer to: https://dev.fitbit.com/build/reference/web-api/oauth2/
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {fetchApiGet} from '../../api/fetchApi';
import {REACT_APP_FITBIT_OAUTH_URL} from '../../../config';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';

const FitbitParticipantAuthAccess = ({participantId}) => {
  const [errors, setErrors] = useState(null);

  // Send the authorization request to Fitbit
  const fitbitAuthorization = async () => {
    var auth_url = new URL(REACT_APP_FITBIT_OAUTH_URL);
    var search_params = auth_url.searchParams;

    let clientId = '';
    try {
      const res = await fetchApiGet('/fitbitClientId');
      if (res) {
        clientId = res.FITBIT_CLIENT_ID;
      } else {
        throw new Error(`Error: missing parameter, can't authorize!`);
      }

      // Append parameters to 'auth_url'
      search_params.append('response_type', 'code');
      search_params.append('client_id', clientId);
      search_params.append(
        'scope',
        'activity heartrate location nutrition profile settings sleep social weight'
      );
      search_params.append(
        'redirect_uri',
        `${process.env.REACT_APP_FITBIT_OAUTH_CALLBACK_URL}`
      );
      search_params.append('prompt', '');
      search_params.append('state', participantId);

      // Add the search properties to the url
      auth_url.search = search_params.toString();
      window.location.href = auth_url;
    } catch (error) {
      console.error(error);
      if (error && error.displayToUser && error.message) {
        setErrors(`Error:  ${error.message}!`);
      } else {
        setErrors('Something went wrong! authorization failed.');
      }
    }
  };

  return (
    <>
      {errors && <DisplayErrors errors={errors} />}
      <button
        type="button"
        className="btn authorize-access-btn"
        onClick={fitbitAuthorization}
      >
        Authorize Access
      </button>
    </>
  );
};

FitbitParticipantAuthAccess.propTypes = {
  participantId: PropTypes.string,
};
export default FitbitParticipantAuthAccess;
