import React, {useState, useEffect, useCallback} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';

import {normalizeResponseErrors} from '../../errorHandling/NormalizeErrors';
import OmronParticipantConsentForm from './OmronParticipantConsentForm';
import {useTrialInfo} from '../../trials/TrialInfoContext';

const OmronCallback = () => {
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);
  const [authCode, setAuthCode] = useState(null);
  const [participantId, setParticipantId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [regError, setRegError] = useState('');
  const {getCurrentTrialInfo} = useTrialInfo();

  let currentTrialInfo = null;
  try {
    // Get trial info
    currentTrialInfo = getCurrentTrialInfo();
  } catch (error) {
    console.error(
      'Fitbit Callback - Reading Trial Info from localStorage failed'
    );
  }

  // Exchange the authorization code we've just received for an access and refresh token
  const getToken = useCallback(
    async (participantData) => {
      try {
        setLoading(true);
        let res = await fetch(`/omron-callback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({authCode, participantData}),
        });
        // console.log(res);
        res = await normalizeResponseErrors(res);
        if (res.status === 200) {
          setAuthorized(true);
        } else {
          throw new Error('Error: Could not get tokens from Omron server!');
        }
      } catch (error) {
        console.error('Error', error);
        setRegError(
          'Authorization failed! Please click Cancel and try again if needed'
        );
      } finally {
        setLoading(false);
      }
    },
    [authCode]
  );

  const navigateToMainTrialPage = () => {
    if (currentTrialInfo) {
      navigate('/TrialPage', {
        state: currentTrialInfo,
      });
    } else {
      return <Navigate to="/" />;
    }
  };

  const handelCancelAuthButtonClicked = () => {
    navigateToMainTrialPage();
  };

  useEffect(() => {
    // Extract the code we got back from Omron from the url
    // console.log(`URL:${window.location.href}`);
    const current_url = window.location.href;
    if (current_url.includes('code')) {
      // Get the search part of the url
      const searchStr = window.location.search;
      // Parse the search string parameters
      const searchParams = new URLSearchParams(searchStr);
      // Extract the auth code from the parameters
      const authCode = searchParams.get('code');
      const state = searchParams.get('state');
      setParticipantId(state);
      setAuthCode(authCode);
    } else if (current_url.includes('error')) {
      // Get the search part of the url
      let searchStr = window.location.search;
      // Parse the search string parameters
      const searchParams = new URLSearchParams(searchStr);
      // Extract the error message
      const error = searchParams.get('code');
      // TODO: decide how to display this error
      console.error(`Error: ${error}`);
    } else {
      console.error(`Error: No Code`);
      setRegError(
        `Authorization failed - Please click Cancel and try again if needed!`
      );
    }
  }, []);

  if (authorized) {
    navigateToMainTrialPage();
  }

  return (
    <div>
      <h1 className="centered-text">Omron Authorization</h1>
      {loading ? <div className="spinnerModal"></div> : ''}
      {regError && (
        <div className="error-message centered-text">{regError}</div>
      )}
      <OmronParticipantConsentForm
        participantId={participantId}
        participantDataCallback={getToken}
        cancelAuthCallback={handelCancelAuthButtonClicked}
        error={regError}
      />
    </div>
  );
};

export default OmronCallback;
