import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import PropTypes from 'prop-types';

import DropDownList from '../dropDownMenu/DropDownList';

function ParticipantSelectDeviceForm({selectionCallback}) {
  // react-hook-form initialization
  const methods = useForm({
    mode: 'onChange',
  });
  const {handleSubmit} = methods;

  const onChange = (selectedDevice) => {
    selectionCallback(selectedDevice);
  };

  // Select Device list
  const devicesList = [
    {
      label: 'Click to Select an Option or Clear',
      value: 'None',
    },
    {label: 'Fitbit', value: 'fitbit'},
    // {label: 'Omron', value: 'omron'},
  ];

  return (
    <FormProvider {...methods}>
      <form className="device-form" onChange={handleSubmit(onChange)}>
        {/* <div className='form-error centered-text'>{regError}</div> */}
        <fieldset>
          <legend></legend>
          <DropDownList
            list={devicesList}
            title={'Select Device'}
            name={'device'}
          />
        </fieldset>
      </form>
    </FormProvider>
  );
}

ParticipantSelectDeviceForm.propTypes = {
  selectionCallback: PropTypes.func,
};

export default ParticipantSelectDeviceForm;
