import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {useForm} from 'react-hook-form';
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';

import Modal from '../modal/Modal';

const SimpleYesNoConfirmForm = ({
  title,
  text,
  returnAnswerCallback,
  cancelCallback,
}) => {
  const {handleSubmit} = useForm({mode: 'onSubmit'});

  const onSubmit = () => {
    // Upon success return data to the parent component
    returnAnswerCallback(true);
  };

  const handleCancel = () => {
    returnAnswerCallback(false);
  };

  const closeModal = () => {
    cancelCallback();
  };

  return (
    <>
      <Modal title={title} text={text} closeModalCallback={closeModal}>
        <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <button type="submit" className="submit-btn">
              <AiOutlineCheck
                className="submit-icon"
                data-tip
                data-for="submit-delete"
              />
              <ReactTooltip
                id="submit-delete"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Submit
              </ReactTooltip>
            </button>
            <button type="button" className="cancel-btn" onClick={handleCancel}>
              <AiOutlineClose
                className="cancel-icon"
                data-tip
                data-for="cancel-delete"
              />
              <ReactTooltip
                id="cancel-delete"
                aria-haspopup="true"
                effect="float"
                className="custom-tooltip"
              >
                Cancel
              </ReactTooltip>
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

SimpleYesNoConfirmForm.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  returnAnswerCallback: PropTypes.func,
  cancelCallback: PropTypes.string,
};

export default SimpleYesNoConfirmForm;
