import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import ReactTooltip from 'react-tooltip';
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';

import {useYupValidationResolver} from '../../forms/YupValidationResolver';

import '../../modal/ModalForm.css';
import './AddParticipantForm.css';

// Yup form validation rules
const validationSchema = yup.object({
  email: yup.string().required('Email is required').email('Email is invalid'),
  age: yup
    .string()
    .notRequired()
    .test('greaterThanZero', 'Value must be greater than zero', (value) =>
      value ? value > 0 : ' '
    )
    .max(3, 'Age cannot be more than 3 digits')
    .matches(/^[0-9]+$|^$/, 'Age must be only digits'), // Age can also be empty!
});

function AddParticipantForm({getDataCallback, cancelBtnClickedCallback}) {
  const resolver = useYupValidationResolver(validationSchema);
  const [regError, setRegError] = useState('');
  const [messageCharCount, setMessageCharCount] = useState(0);

  const handleTextChange = (evt) => {
    evt.preventDefault();
    setMessageCharCount(evt.target.value.length);
  };

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = async (data) => {
    // Upon success send data back to the parent
    getDataCallback(data);
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  const handleClickCancelButton = () => {
    // If user clicked on cancel button go back to the current trial page
    cancelBtnClickedCallback();
  };

  return (
    <form
      className="modal-form add-participant-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-error centered-text">{regError}</div>
      <fieldset>
        <p>Only items marked with (*) are required.</p>
        <div className="form-item">
          <label>*Email</label>
          <div className="form-error" aria-live="polite">
            {errors.email?.message}
          </div>
          <input
            name="email"
            type="text"
            {...register('email')}
            onFocus={setRegErrorMessage}
            className={`form-input ${errors.email ? 'is-invalid' : ''}`}
          />
        </div>
        <div className="form-item">
          <label>Gender</label>
          <div className="form-error" aria-live="polite">
            {errors.Gender?.message}
          </div>
          <select
            name="Gender"
            {...register('gender')}
            className={`form-input ${errors.gender ? 'is-invalid' : ''}`}
          >
            <option value="">None</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="form-item">
          <label>Age</label>
          <div className="form-error" aria-live="polite">
            {errors.age?.message}
          </div>
          <input
            name="age"
            type="number"
            {...register('age')}
            onFocus={setRegErrorMessage}
            className={`form-input ${errors.age ? 'is-invalid' : ''}`}
          />
        </div>

        <div className="form-item">
          <label>Notes</label>
          <div className="form-error" aria-live="polite">
            {errors.notes?.message}
          </div>
          <textarea
            name="notes"
            type="text"
            {...register('notes')}
            onFocus={setRegErrorMessage}
            onChange={(evt) => handleTextChange(evt)}
            maxLength={300}
            className={`form-input ${errors.notes ? 'is-invalid' : ''}`}
          />
          <p>{messageCharCount}/300</p>
        </div>
      </fieldset>
      <div className="form-group">
        <button type="submit" className="submit-btn">
          <AiOutlineCheck
            className="submit-icon"
            data-tip
            data-for="submit-edit"
          />
          <ReactTooltip
            id="submit-edit"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Submit
          </ReactTooltip>
        </button>
        <button
          type="button"
          className="cancel-btn"
          onClick={handleClickCancelButton}
        >
          <AiOutlineClose
            className="cancel-icon"
            data-tip
            data-for="cancel-edit"
          />
          <ReactTooltip
            id="cancel-edit"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Cancel
          </ReactTooltip>
        </button>
      </div>
    </form>
  );
}

AddParticipantForm.propTypes = {
  getDataCallback: PropTypes.oneOfType([PropTypes.func]),
  cancelBtnClickedCallback: PropTypes.oneOfType([PropTypes.func]),
};
export default AddParticipantForm;
