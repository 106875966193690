import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import SFASendCodeViaMsgForm from './SFASendCodeViaMsgForm';
import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {useAuth} from '../authorization/AuthContext';

const SFASendCodeViaMsgPage = () => {
  // Destructuring logout and sfaValidate from AuthContext
  const {logout, sfaValidate} = useAuth();

  const [loading, setLoading] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isAlreadyValidated, setIsAlreadyValidated] = useState(null);
  const [badAttempts, setBadAttempts] = useState(0);

  const sendEmailTOTP = async () => {
    try {
      setLoading(true);
      const results = await fetchApiSet('/auth/generateSendEmailTOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: '',
      });

      // console.log(results.token);
      if (results.success) {
        if (results.message === 'SFA already validated') {
          setIsAlreadyValidated(true);
        } else {
          setDisplayForm(true);
        }
      } else {
        setErrors('Sending code by email failed!');
      }
    } catch (error) {
      setErrors('Sending code by email failed!');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    sendEmailTOTP();
  }, []);

  // Logout the user in case of 5 failed attempts to validate the SFA code
  useEffect(() => {
    if (badAttempts >= 5) {
      logout();
    }
  }, [badAttempts, logout]);

  const handleValidateSFATokenReq = async (data) => {
    try {
      setLoading(true);
      let res = await fetchApiSet('/auth/validateTOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sfaToken: data.sfaToken,
          skipSFA: data.skipSFA || false,
        }),
      });

      if (res.errors) {
        throw new Error(`Error: Could not validate sfa token!`);
      } else {
        if (res.success === true) {
          console.log('Validating sfa token was successful');
          sfaValidate('Authorized');
        } else {
          throw new Error(`Error: Validating sfa token failed!`);
        }
      }
    } catch (error) {
      console.error(error);
      setBadAttempts(badAttempts + 1);
      if (error && error.displayToUser && error.message) {
        setErrors(error.message);
      } else {
        setErrors('Error: validating sfa token request failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendCodeClicked = () => {
    sendEmailTOTP();
  };

  const handleSwitchToAuthenticatorClicked = async () => {
    try {
      setLoading(true);
      let res = await fetchApiSet('/auth/resetSFA', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: '',
      });
      if (res.errors) {
        throw new Error(`Error: Could not reset sfa!`);
      } else {
        if (res.success === true) {
          console.log('Resetting sfa was successful');
          sfaValidate('SFAIsNotSet');
        } else {
          throw new Error(`Error: Resetting sfa token failed!`);
        }
      }
    } catch (error) {
      console.error(error);
      if (error && error.displayToUser && error.message) {
        setErrors(error.message);
      } else {
        setErrors('Error: resetting sfa failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="centered-text">
      {loading ? <div className="spinnerModal"></div> : ''}
      <h1>Second-Factor Authentication</h1>
      {errors && <DisplayErrors errors={errors} />}
      {!isAlreadyValidated && displayForm && (
        <>
          <p>A one-time code has been sent to your email address,</p>
          <p>please enter it below.</p>
          <SFASendCodeViaMsgForm getDataCallback={handleValidateSFATokenReq} />
          <div>
            <button
              type="button"
              className="simple-btn"
              onClick={() => handleResendCodeClicked()}
            >
              Re-send code
            </button>
            <button
              type="button"
              className="simple-btn"
              onClick={() => handleSwitchToAuthenticatorClicked()}
            >
              Use Authenticator
            </button>
          </div>
        </>
      )}{' '}
      {isAlreadyValidated && (
        <div>
          {' '}
          <p>
            Your second-factor authentication has already been validated!
          </p>{' '}
        </div>
      )}
    </div>
  );
};

SFASendCodeViaMsgPage.propTypes = {
  changeToAnotherOptionCallback: PropTypes.func,
};

export default SFASendCodeViaMsgPage;
