import React from 'react';
import PropTypes from 'prop-types';

import LineChartDraw from '../charts/LineChart';
import BarChartDraw from '../charts/BarChart';
import ComposedBarLineChartDraw from '../charts/ComposedChart';
import RadialBarChartDraw from '../charts/RadialBarChart';
import PieChartDraw from '../charts/PieChart';

const DisplayDateValueChart = (props) => {
  const {content, chartType} = props;

  const displayResults = () => {
    switch (chartType) {
      case 'LineChart':
        return <LineChartDraw content={content} />;
      case 'BarChart':
        return <BarChartDraw content={content} />;
      case 'RadialBarChart':
        return <RadialBarChartDraw />;
      case 'PieChart':
        return <PieChartDraw content={content} />;
      case 'ComposedBarLineChart':
        return <ComposedBarLineChartDraw content={content} />;
      default:
        console.error('Error!');
        break;
    }
  };

  // Data is ready for display as date-value in a chart.
  return <>{content && <div>{displayResults()}</div>}</>;
};

DisplayDateValueChart.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  chartType: PropTypes.string,
};

export {DisplayDateValueChart};
