const DateToISODateOnlyShort = (date) => {
  try {
    date = date.toISOString().slice(0, 10);
    return date;
  } catch (error) {
    console.error(error);
  }
};

const ShortDateTimeToTimeOnly = (date) => {
  try {
    // let time = date.slice(10, 19);
    let time = date.split(',')[1];
    // console.log(time);
    return time;
  } catch (error) {
    console.error(error);
  }
};

function DateToTimeOnlyShort(date) {
  try {
    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    return `${hours}:${minutes}`;
  } catch (error) {
    console.error(error);
  }
}

function MillisToMinutesAndSeconds(millis) {
  try {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  } catch (error) {
    console.error(error);
  }
}

const minutesToHoursMinutes = (minutes) => {
  try {
    const num = minutes;
    const hours = num / 60;
    const rhours = Math.floor(hours);
    const mins = (hours - rhours) * 60;
    const rmins = Math.round(mins);
    const total = rhours + 'h ' + rmins + 'm';
    return total;
  } catch (error) {
    console.error(error);
  }
};

export {
  DateToISODateOnlyShort,
  ShortDateTimeToTimeOnly,
  DateToTimeOnlyShort,
  MillisToMinutesAndSeconds,
  minutesToHoursMinutes,
};
