import React from 'react';
import PropTypes from 'prop-types';

import Mailto from '../../email/Mailto';
import {REACT_APP_DOMAIN} from '../../../config';

const FitbitSendAuthRequest = ({participant}) => {
  const domain = `${REACT_APP_DOMAIN}`;
  const url = `${domain}PAFitbitConsentPage?id=${participant.id}`;
  const emailAddress = participant.email;
  const subject = 'CDConnect™ - Fitbit data authorization request.';
  const body = `In order to incorporate your data into our clinical trial, we require your authorization.%0D%0A\
To begin the authorization process, please click on the link provided below or copy and paste it into your web browser.%0D%0A\
Please click on the link provided below or copy and paste it into your web browser.%0D%0A%0D%0A${url}%0D%0A%0D%0AThis link will redirect you to a page with detailed instructions on how to proceed.`;

  return (
    <Mailto email={emailAddress} subject={subject} body={body}>
      Send Auth Request
    </Mailto>
  );
};

FitbitSendAuthRequest.propTypes = {
  participant: PropTypes.object,
};

export default FitbitSendAuthRequest;
