import React from 'react';
import {useNavigate} from 'react-router-dom';

const PageNotFound = () => {
  let navigate = useNavigate();

  const handleRedirectToDashboard = async () => {
    return navigate('/');
  };

  return (
    <div className="centered-container centered-text">
      <h1>404 Error - page not found</h1>
      <h3>
        We are sorry, it looks like the page you are looking for does not exist.
      </h3>
      <button
        className="btn"
        id="redirect-to-dashboard"
        onClick={handleRedirectToDashboard}
      >
        Go to home page!
      </button>
    </div>
  );
};

export default PageNotFound;
