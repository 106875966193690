import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import GraphicDisplayTile from '../../tiles/GraphicDisplayTile';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';
import {getLocationState} from '../../../services/URL/urlLocation';
import {DisplayRefinedData} from '../displayData/DisplayRefinedData';
import FitbitParticipantDataForm from '../participant/FitbitParticipantDataForm';
import {FitbitQueryBuilder} from '../queryBuilder/FitbitQueryBuilder';
import {GetParticipantDataFromFitbit} from '../participant/FitbitParticipantData';
import {getTrackerActiveMinutes} from '../participant/FitbitActiveMinutes';
import Sidebar from '../../sidebar/Sidebar';
import TrialInfo from '../../trials/TrialInfo';

import './FitbitParticipantsDataPage.css';

const FitbitParticipantsDataPage = () => {
  const location = useLocation();
  const [participants, setParticipants] = useState(null);
  const [participantsData, setParticipantsData] = useState(null);
  const [queryStr, setQueryStr] = useState(null);
  const [queryData, setQueryData] = useState(null);
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);

  // Get participants' info from location
  useEffect(() => {
    try {
      const res = getLocationState(location, 'multipleParticipantsData');
      if (res.data) {
        setParticipants(res.data.participants);
      }
    } catch (error) {
      console.error(error);
      setErrors('Something went wrong! We could not find participants info.');
    }
  }, [location]);

  // Get data of a single participant from Fitbit based on the information in the query's string
  const getFitbitParticipantData = async (participant, queryStr, queryData) => {
    let participantData = {info: {}, data: null, errors: null};
    // Get the data from Fitbit server
    let result = null;
    if (queryData.activityResourcePath === 'totalTrackerActiveMinutes') {
      result = await getTrackerActiveMinutes(queryData, participant);
    } else {
      result = await GetParticipantDataFromFitbit(participant, queryStr);
    }
    // Add data and error to participantData
    if (result.data) {
      participantData.data = result.data;
    } else if (result.errors) {
      participantData.errors = result.errors;
    }
    // Add participant's info to participantData
    if (participant.id) {
      participantData.info['id'] = participant.id;
    } else {
      participantData.info['id'] = '';
    }
    if (participant.email) {
      participantData.info['email'] = participant.email;
    } else {
      participantData.info['email'] = '';
    }
    return participantData;
  };

  // Gather all participants' data from the server
  useEffect(() => {
    try {
      let results = [];
      let result = null;
      let idx = 0;
      const GetParticipantsData = async (participants) => {
        setLoading(true);
        results[idx++] = await Promise.all(
          Object.values(participants).map(async (participant) => {
            result = await getFitbitParticipantData(
              participant,
              queryStr,
              queryData
            );
            return result;
          })
        );
        setParticipantsData(results);
        setLoading(false);
      };

      if (participants && queryStr && queryData) {
        GetParticipantsData(participants);
      }
    } catch (error) {
      setLoading(false);
      setErrors('Error: Fitbit participants data request failed!');
    }
  }, [participants, queryStr, queryData]);

  const DisplayData = (data) => {
    data = data[0];
    try {
      if (data) {
        // Loop through all participants
        return Object.values(data).map((item, key) => {
          let tileInfo = {};
          let displayInfo = {style: 'overview', displayTable: 'false'};
          tileInfo.upperInfoLine1 = `ID: ${item.info.id}`;
          tileInfo.upperInfoLine2 = `Email: ${item.info.email}`;
          if (item.errors) {
            tileInfo.errors = item.errors;
          }
          return (
            <GraphicDisplayTile
              info={tileInfo}
              key={key}
              className="participant-flex-item"
            >
              <DisplayRefinedData
                deviceName="Fitbit"
                subject={queryData.subject}
                data={item.data}
                queryInfo={queryData}
                participantInfo={item.info}
                displayInfo={displayInfo}
              />
            </GraphicDisplayTile>
          );
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDataRequest = async (queryData) => {
    // Clear results and errors from previous query
    setParticipantsData(null);
    setQueryStr(null);
    setQueryData(null);
    setErrors(null);
    // Build a query based on the form's data
    const res = FitbitQueryBuilder(queryData);
    if (res) {
      if (res.error) {
        console.error(res.error);
        setErrors(res.error);
      } else {
        setQueryData(queryData);
        setQueryStr(res.data);
      }
    }
  };

  const sidebarToggled = () => {
    setOpenSidebar(!openSidebar);
  };

  const [openSidebar, setOpenSidebar] = useState(true);

  return (
    <>
      {loading ? <div className="spinnerModal"></div> : ''}
      <Sidebar
        width={320}
        height={'100vh'}
        sidebarToggledCallback={sidebarToggled}
      >
        <FitbitParticipantDataForm
          getDataCallback={handleGetDataRequest}
          displayDataType={'refinedData'}
        />
      </Sidebar>
      <div className={openSidebar ? 'open-sidebar' : 'closed-sidebar'}>
        <TrialInfo />
        <h3 className="participants-data-title centered-text">
          Participants Data - Fitbit
        </h3>
        {errors && <DisplayErrors errors={errors} />}
        <div className="participants-data-container flex-container">
          {participantsData &&
            participantsData.length > 0 &&
            DisplayData(participantsData)}
        </div>
      </div>
    </>
  );
};

export default FitbitParticipantsDataPage;
