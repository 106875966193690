import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import SFAVerifyPage from './SFAVerifyPage';
import {fetchApiGet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {useAuth} from '../authorization/AuthContext';

const SFASetupPage = () => {
  const {sfaValidate} = useAuth();
  const navigate = useNavigate();
  const [qrImage, setQrImage] = useState(null);
  const [sfaKey, setSFAKey] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    const DisplayQR = async () => {
      try {
        const res = await fetchApiGet('/auth/secondFactorSetup');
        if (res) {
          setQrImage(<img src={res.qrImage} />);
          setSFAKey(res.key);
        } else {
          throw new Error(`Error: missing parameter, can't authorize!`);
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setErrors(error.message);
        } else {
          setErrors('Error: SFA error!');
        }
      }
    };
    DisplayQR();
  }, []);

  const DisplaySFAAuth = () => {
    return (
      <>
        <h1>Second-Factor Authentication</h1>
        <hr />

        <h3>1. Scan or Enter Code</h3>
        <div>
          {qrImage && (
            <div>
              <p>
                Please use your preferred authenticator app to scan the QR code
                provided or manually enter the code below.
              </p>
              <div>{qrImage}</div>
            </div>
          )}
          {sfaKey && (
            <div>
              <div>{sfaKey}</div>
            </div>
          )}
          <hr />
          <h3>2. Verify the code</h3>
          {qrImage && <SFAVerifyPage />}
        </div>
        <button
          type="button"
          className="simple-btn"
          onClick={() => handleSendSFACodeByEmailClicked()}
        >
          Send code by email
        </button>
      </>
    );
  };

  const handleSendSFACodeByEmailClicked = async () => {
    sfaValidate('SFAValidateEmailCodeNeeded');
    navigate('/SFASendCodeViaMsgPage');
  };

  return (
    <div className="centered-text">
      {errors && <DisplayErrors errors={errors} />}
      <DisplaySFAAuth qrImage={qrImage} sfaKey={sfaKey} />
    </div>
  );
};

export default SFASetupPage;
