import React, {useState, useEffect} from 'react';

import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import SignupPage from './SignupPage';

const SignupValidation = () => {
  const [errors, setErrors] = useState(null);
  const [token, setToken] = useState(null);
  const [authorizedUserEmail, setAuthorizedUserEmail] = useState(null);
  const [authorizedUserRole, setAuthorizedUserRole] = useState(null);
  const [authorizedUserTeamId, setAuthorizedUserTeamId] = useState(null);
  const [authorizedUserTeam, setAuthorizedUserTeam] = useState(null);

  useEffect(() => {
    // Extract the token we got from the url
    const current_url = window.location.href;
    if (current_url.includes('token')) {
      // Get the search part of the url
      const searchStr = window.location.search;
      // Parse the search string parameters
      const searchParams = new URLSearchParams(searchStr);
      // Extract the auth token from the parameters
      const token = searchParams.get('token');
      setToken(token);
    } else {
      setErrors(`Error: key is missing!`);
    }
  }, []);

  useEffect(() => {
    const checkSignupToken = async () => {
      try {
        let url = `/userSignUpPermission/signupRequestValidation`;
        let res = await fetchApiSet(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({token}),
        });
        if (res.errors) {
          throw new Error(`Error: sign-up request validation unsuccessful!`);
        } else if (res.email) {
          // If everything is ok, display the sign up page
          setAuthorizedUserEmail(res.email);
          if (res.role) {
            setAuthorizedUserRole(res.role);
          }
          if (res.teamId) {
            setAuthorizedUserTeamId(res.teamId);
          }
          if (res.teamName) {
            setAuthorizedUserTeam(res.teamName);
          }
        } else {
          throw new Error(`Error: sign-up request validation unsuccessful!`);
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setErrors(error.message);
        } else {
          setErrors('Error: sign-up request validation failed!');
        }
      }
    };

    if (token) {
      checkSignupToken();
    }
  }, [token]);

  return (
    <>
      <div className="centered-text">
        {errors && <DisplayErrors errors={errors} />}
      </div>
      {authorizedUserEmail && (
        <SignupPage
          email={authorizedUserEmail}
          role={authorizedUserRole}
          teamId={authorizedUserTeamId}
          teamName={authorizedUserTeam}
        />
      )}
    </>
  );
};

export default SignupValidation;
