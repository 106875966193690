// We are using the oauth2 Authorization Code Grant Flow to obtain consent from Dexcom
import React from 'react';

import {fetchApiGet} from '../../api/fetchApi';
import {REACT_APP_DEXCOM_OAUTH_URL} from '../../../config';

const DexcomParticipantAuthAccess = () => {
  // URL for Dexcom oauth2 authorization

  // Send the authorization request to Dexcom
  const dexcomAuthorization = async () => {
    var auth_url = new URL(REACT_APP_DEXCOM_OAUTH_URL);
    var search_params = auth_url.searchParams;

    let clientId = '';
    try {
      const res = await fetchApiGet('/dexcomClientId');
      if (res) {
        clientId = res.DEXCOM_CLIENT_ID;
      } else {
        throw new Error(`Error: missing parameter, can't authorize!`);
      }

      // Append parameters to 'auth_url'
      search_params.append('client_id', clientId);
      search_params.append(
        'redirect_uri',
        `${process.env.REACT_APP_DEXCOM_OAUTH_CALLBACK_URL}`
      );
      search_params.append('response_type', 'code');
      search_params.append('scope', 'offline_access');
      search_params.append('state', '');

      // Add the search properties to the url
      auth_url.search = search_params.toString();
      window.location.href = auth_url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn authorize-access-btn"
        onClick={dexcomAuthorization}
      >
        Authorize Access
      </button>
    </>
  );
};

export default DexcomParticipantAuthAccess;
