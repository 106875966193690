import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';

import {useYupValidationResolver} from '../forms/YupValidationResolver';

// Yup form validation rules
const validationSchema = yup.object({
  sfaToken: yup
    .string()
    .min(6, 'Code must be exactly 6 digits')
    .max(6, 'Code must be exactly 6 digits')
    .matches(/^[0-9]+$/, 'Code must be only digits')
    .required('SFA Code is required'),
});

function SFAVerifyForm({getDataCallback}) {
  const resolver = useYupValidationResolver(validationSchema);
  const [regError, setRegError] = useState('');

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = (data) => {
    // Upon success send data back to the parent
    getDataCallback(data);
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  return (
    <form
      className="centered-container narrow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-error centered-text">{regError}</div>
      <p>
        Enter the verification code from the authenticator app in the box below
        and then click Verify.
      </p>
      <fieldset>
        <label hidden>Verification Code</label>
        <div className="form-error" aria-live="polite">
          {errors.sfaToken?.message}
        </div>
        <input
          name="sfaToken"
          type="text"
          autoComplete="off"
          placeholder="Enter code from the authenticator app"
          {...register('sfaToken')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.sfaToken ? 'is-invalid' : ''}`}
        />
      </fieldset>
      <div className="form-group">
        <button type="submit" className="btn btn-primary">
          Verify
        </button>
      </div>
    </form>
  );
}

SFAVerifyForm.propTypes = {
  getDataCallback: PropTypes.func,
};

export default SFAVerifyForm;
