import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {IoEyeOffOutline} from 'react-icons/io5';
import {IoEyeOutline} from 'react-icons/io5';

import {useYupValidationResolver} from '../forms/YupValidationResolver';
import {UserAuthApi} from './UserAuthApi';
import {onFormSubmitRecaptchaVerification} from '../common/googleRecaptcha';

import './SignupForm.css';

// Yup form validation rules
const validationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required').email('Email is invalid'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password can not be less than 8 characters')
    .max(20, 'Password can not be more than 20 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\\!#$%()*+,-./:;=?@[\]^_`{|}~])[A-Za-z\d\\!#$%()*+,-./:;=?@[\]^_`{|}~]+$/,
      'Password must have at least One Uppercase, One Lowercase, One Number, and One special character  \\!#$%()*+,-./:;=?@[]^_`{|}~'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  acceptTerms: yup.bool().oneOf([true], 'Accept Terms is required'),
  acceptPrivacy: yup.bool().oneOf([true], 'Accept Privacy Policy is required'),
});

const SignupForm = ({email, role, teamId, teamName}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const [loading, setLoading] = useState(false);
  const [regError, setRegError] = useState('');
  const [newPasswordType, setNewPasswordType] = useState('password');
  const [displayNewPassword, setDisplayNewPassword] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);
  let navigate = useNavigate();

  const {
    handleSubmit,
    register,
    reset,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  // Insert current values to form's fields
  useEffect(() => {
    reset({
      email: email,
      role: role,
      teamName: teamName,
      teamId: teamId,
    });
  }, [reset, email, role, teamId, teamName]);

  const onSubmit = async (data) => {
    await window.grecaptcha.ready(async () => {
      try {
        const reCAPTCHAResults = await onFormSubmitRecaptchaVerification();
        if (reCAPTCHAResults.errors) {
          // If reCAPTCHA version 3 failed, display an error
          throw new Error(reCAPTCHAResults.errors);
        } else {
          try {
            setLoading(true);
            // Upon success send an api request to signup the user
            // Note: username is the email address!
            data.username = data.email;
            const res = await UserAuthApi.SignupApi(data);
            if (!res) {
              throw new Error('Signup failed');
            }
            // console.log('Signup successful');
            navigate('/LoginPage');
          } catch (error) {
            setRegError(error);
          } finally {
            setLoading(false);
          }
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setRegError(error.message);
        } else {
          setRegError(`reCAPTCHA V3 verification failed!`);
        }
      }
    });
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  const handleToggleDisplayNewPassword = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDisplayNewPassword(!displayNewPassword);
    if (!displayNewPassword) {
      setNewPasswordType('text');
    } else {
      setNewPasswordType('password');
    }
  };

  const handleToggleDisplayConfirmPassword = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDisplayConfirmPassword(!displayConfirmPassword);
    if (!displayConfirmPassword) {
      setConfirmPasswordType('text');
    } else {
      setConfirmPasswordType('password');
    }
  };

  return (
    <form
      className="signup-form centered-container narrow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="centered-text">Sign Up to your account</h2>
      <div className="form-error centered-text">{regError}</div>
      {loading ? <div className="spinnerModal"></div> : ''}
      <fieldset>
        <label>*First Name</label>
        <div className="form-error" aria-live="polite">
          {errors.firstName?.message}
        </div>
        <input
          name="firstName"
          type="text"
          //placeholder='First name'
          {...register('firstName')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.firstName ? 'is-invalid' : ''}`}
        />
        <label>*Last Name</label>
        <div className="form-error" aria-live="polite">
          {errors.lastName?.message}
        </div>
        <input
          name="lastName"
          type="text"
          //placeholder='Last name'
          {...register('lastName')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.lastName ? 'is-invalid' : ''}`}
        />
        <label>*Email (Username)</label>
        <div className="form-error" aria-live="polite">
          {errors.email?.message}
        </div>
        <input
          disabled
          name="email"
          type="text"
          //placeholder='email'
          {...register('email')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.email ? 'is-invalid' : ''}`}
        />
        <div className="flex-sighup-password-icon">
          <label className="flex-sighup-password-icon-item">*Password</label>
          <span
            className="form-btn flex-sighup-password-icon-item"
            onClick={handleToggleDisplayNewPassword}
          >
            {displayNewPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
          </span>
        </div>
        <div className="form-error" aria-live="polite">
          {errors.password?.message}
        </div>
        <input
          name="password"
          type={newPasswordType}
          autoComplete="off"
          //placeholder='password'
          {...register('password')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.password ? 'is-invalid' : ''}`}
        />
        <div className="flex-sighup-password-icon">
          <label className="flex-sighup-password-icon-item">
            *Confirm Password
          </label>
          <span
            className="flex-sighup-password-icon-item"
            onClick={handleToggleDisplayConfirmPassword}
          >
            {displayConfirmPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
          </span>
        </div>
        <div className="form-error" aria-live="polite">
          {errors.confirmPassword?.message}
        </div>
        <input
          name="confirmPassword"
          type={confirmPasswordType}
          autoComplete="off"
          //placeholder='Confirm Password'
          {...register('confirmPassword')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.confirmPassword ? 'is-invalid' : ''}`}
        />
        <label>Organization</label>
        <div className="form-error" aria-live="polite">
          {errors.organization?.message}
        </div>
        <input
          name="organization"
          type="text"
          //placeholder='Organization'
          {...register('organization')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.organization ? 'is-invalid' : ''}`}
        />
        {/* <label>Team Id</label> */}
        <div className="form-error" aria-live="polite">
          {errors.teamId?.message}
        </div>
        <input
          disabled
          name="teamId"
          type="hidden"
          //placeholder='Team'
          {...register('teamId')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.team ? 'is-invalid' : ''}`}
        />
        <label>Team Name</label>
        <div className="form-error" aria-live="polite">
          {errors.teamName?.message}
        </div>
        <input
          disabled
          name="teamName"
          type="text"
          //placeholder='Team'
          {...register('teamName')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.team ? 'is-invalid' : ''}`}
        />

        <div className="form-item">
          <div>
            <label>Role</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.role?.message}
          </div>
          <div>
            <select
              disabled
              name="role"
              type="text"
              className="signup-form-select"
              {...register('role')}
            >
              <option value="Viewer">Viewer</option>
              <option value="User">User</option>
              <option value="Admin">Admin</option>
            </select>
          </div>
        </div>

        <div>
          <input
            name="acceptTerms"
            type="checkbox"
            {...register('acceptTerms')}
            id="acceptTerms"
            className={`form-check-input'${
              errors.acceptTerms ? 'is-invalid' : ''
            }`}
          />
          <label htmlFor="acceptTerms" className="">
            *Accept Terms & Conditions
          </label>
          <div className="form-error" aria-live="polite">
            {errors.acceptTerms?.message}
          </div>
        </div>
        <div>
          <input
            name="acceptPrivacy"
            type="checkbox"
            {...register('acceptPrivacy')}
            id="acceptPrivacy"
            className={`form-check-input'${
              errors.acceptPrivacy ? 'is-invalid' : ''
            }`}
          />
          <label htmlFor="acceptPrivacy" className="">
            *Accept Privacy Policy
          </label>
          <div className="form-error" aria-live="polite">
            {errors.acceptPrivacy?.message}
          </div>
        </div>
      </fieldset>

      <div className="form-group">
        <button type="submit" className="btn btn-primary">
          Sign Up
        </button>
      </div>
    </form>
  );
};

SignupForm.propTypes = {
  email: PropTypes.string,
  role: PropTypes.string,
  teamId: PropTypes.string,
  teamName: PropTypes.string,
};

export default SignupForm;
