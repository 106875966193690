import React from 'react';
import PropTypes from 'prop-types';
import {FaEllipsisV} from 'react-icons/fa';

import './GraphicDisplayTile.css';

const GraphicDisplayTile = ({info, children, isSelected, onSelect}) => {
  const ellipsisClicked = (event) => {
    // console.log(`ellipsis clicked: ${event.currentTarget.id}`);
    if (event.currentTarget.id) {
      const id = event.currentTarget.id;
      switch (id) {
        case 'Sleep':
          console.log('sleep');
          break;
        default:
          console.log('default');
          break;
      }
    }
  };

  return (
    <div className="category-tile-container flex-item">
      <button className="ellipsis-btn">
        <FaEllipsisV
          onClick={ellipsisClicked}
          className="ellipsis"
          id={info.subject}
        />
      </button>
      {isSelected !== undefined && onSelect !== undefined && (
        <input type="checkbox" checked={isSelected} onChange={onSelect} />
      )}
      {info.upperInfoLine1 && (
        <div className="tile-info">{info.upperInfoLine1}</div>
      )}
      {info.upperInfoLine2 && (
        <div className="tile-info">{info.upperInfoLine2}</div>
      )}
      <h3 className="tile-title">{info.subject}</h3>
      {info.errors && (
        <div className="error-message tile-errors">{info.errors}</div>
      )}
      {info.lowerInfoLine && (
        <div className="tile-text">{info.lowerInfoLine}</div>
      )}
      <div className="category-tile-content-wrapper">
        {/* Display the actual data */}
        {children}
      </div>
    </div>
  );
};

GraphicDisplayTile.propTypes = {
  info: PropTypes.object,
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default GraphicDisplayTile;
