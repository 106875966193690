import React, {useState, useEffect} from 'react';

import SFAVerifyForm from './SFAVerifyForm';
import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {useAuth} from '../authorization/AuthContext';

const SFAVerifyPage = () => {
  // Destructuring login from AuthContext
  const {logout} = useAuth();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [badAttempts, setBadAttempts] = useState(0);

  // Logout the user in case of 5 failed attempts to validate the SFA code
  useEffect(() => {
    if (badAttempts >= 5) {
      logout();
    }
  }, [badAttempts, logout]);

  const handleVerifySFATokenReq = async (data) => {
    try {
      setLoading(true);
      let res = await fetchApiSet('/auth/verifyTOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sfaToken: data.sfaToken,
        }),
      });
      setLoading(false);
      if (res.errors) {
        throw new Error(`Error: Could not verify sfa token!`);
      } else {
        console.log('Verifying sfa token was successful');
        logout();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setBadAttempts(badAttempts + 1);
      if (error && error.displayToUser && error.message) {
        setErrors(error.message);
      } else {
        setErrors('Error: verifying sfa token request failed!');
      }
    }
  };

  return (
    <div className="centered-text">
      {loading ? <div className="spinnerModal"></div> : ''}
      {errors && <DisplayErrors errors={errors} />}
      <SFAVerifyForm getDataCallback={handleVerifySFATokenReq} />
    </div>
  );
};

export default SFAVerifyPage;
