function roundToTwoDecimal(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}

function roundToOneDecimal(num) {
  return Math.round(num * 10) / 10;
}

const kmsToMiles = (kms) => {
  return roundToTwoDecimal(kms / 1.609);
};

const milesToKms = (miles) => {
  return roundToTwoDecimal(miles * 1.609);
};

const kgsToLbs = (kgs) => {
  return roundToOneDecimal(kgs * 2.20462);
};

export {kmsToMiles, milesToKms, kgsToLbs, roundToTwoDecimal};
