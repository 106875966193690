import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';

import {useYupValidationResolver} from '../forms/YupValidationResolver';

import './SFAValidateForm.css';

// Yup form validation rules
const validationSchema = yup.object({
  sfaToken: yup
    .string()
    .min(6, 'Code must be exactly 6 digits')
    .max(6, 'Code must be exactly 6 digits')
    .matches(/^[0-9]+$/, 'Code must be only digits')
    .required('SFA Code is required'),
});

function SFAValidateForm({getDataCallback}) {
  const resolver = useYupValidationResolver(validationSchema);
  const [regError, setRegError] = useState('');

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = (data) => {
    // Upon success send data back to the parent
    getDataCallback(data);
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  return (
    <form
      className="sfa_validate_form centered-container narrow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-error centered-text">{regError}</div>
      <fieldset>
        <label hidden>SFA Code</label>
        <div className="form-error" aria-live="polite">
          {errors.sfaToken?.message}
        </div>
        <input
          name="sfaToken"
          type="text"
          autoComplete="off"
          placeholder="Enter code from the authenticator app"
          {...register('sfaToken')}
          onFocus={setRegErrorMessage}
          className={`form-input ${errors.sfaToken ? 'is-invalid' : ''}`}
        />
        {/* Checkbox for skipping SFA for 48 hours */}
        <div className="form-group">
          <label>
            <input type="checkbox" {...register('skipSFA')} />
            Remember this device for 48 hours
          </label>
        </div>
      </fieldset>

      <div className="form-group">
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
}

SFAValidateForm.propTypes = {
  getDataCallback: PropTypes.func,
  sendSFAByEmailCallback: PropTypes.func,
  senSFAByAuthCallback: PropTypes.func,
};

export default SFAValidateForm;
