import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import ResetPwdReqForm from './ResetPwdReqForm';
import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';

const ResetPwdReqPage = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const handleSubmitResetPwdReq = async (data) => {
    try {
      setLoading(true);
      let url = `/auth/forgotPassword`;
      let res = await fetchApiSet(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email: data.email}),
      });
      if (res.errors) {
        console.error(res.errors);
        throw new Error(`Error: reset password request unsuccessful`);
      } else {
        // If everything is ok, display message to the user regarding sending
        // email to complete the reset process
        setSuccessMsg(
          'We received your reset password request. If we have your email on file we will send you a reset email.'
        );
      }
    } catch (error) {
      console.error(error);
      if (error && error.displayToUser && error.message) {
        setErrors(error.message);
      } else {
        setErrors('Error:reset password request failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancelBtnClicked = () => {
    navigate('/');
  };

  return (
    <>
      {loading ? <div className="spinnerModal"></div> : ''}
      <div className="centered-text">
        <h2>Reset Password Request</h2>
        {errors && <DisplayErrors errors={errors} />}
      </div>
      {!errors && successMsg ? (
        <div className="centered-text">{successMsg}</div>
      ) : (
        <ResetPwdReqForm
          getDataCallback={handleSubmitResetPwdReq}
          cancelBtnClickedCallback={handleCancelBtnClicked}
        />
      )}
    </>
  );
};

export default ResetPwdReqPage;
