import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';

import {normalizeResponseErrors} from '../../errorHandling/NormalizeErrors';
import {OmronApi} from '../api/OmronApi';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';

const OmronParticipantDataRaw = (props) => {
  console.log(`OmronData props: ${props.id}`);
  const id = props.id;
  const [bloodPressure, setBloodPressure] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');

  // TODO: add refresh token code when token is expired
  // TODO: this function needs code optimization - move some code to make it more generic
  // TODO: add error display to the user
  // Get participant's data based on the specific information in the query's string
  const OmronGetParticipantData = async () => {
    try {
      setLoading(true);
      let startDate = DateTime.local().minus({days: 7}).toISODate();
      const queryStr = {
        // type: queryData.category,
        since: startDate,
        sortOrder: 'asc',
      };
      let res = await OmronApi.GetParticipantDataApi(id, queryStr);
      if (res.errors) {
        throw new Error(res.errors);
      }
      // If everything is ok set the data state to be able to display it
      const bloodPressure = res.data.bloodPressure;
      if (bloodPressure) {
        console.log(`data: ${bloodPressure}`);
        setBloodPressure(bloodPressure);
      }
    } catch (error) {
      console.error(error.message);
      // TODO: decide what kind of errors to display here
      if (error) {
        // setErrors(error.message);
      } else {
        setErrors('Error: getting data from Omron server failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  const RefreshToken = async () => {
    try {
      let res = await fetch(`/omronRefreshToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
        }),
      });

      res = await normalizeResponseErrors(res);
      const data = await res.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const RevokeToken = async () => {
    try {
      let res = await fetch(`/omronRevokeToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
        }),
      });
      res = await normalizeResponseErrors(res);
      const data = await res.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const DisplayBloodPressure = (bloodPressure) => {
    return (
      <div>
        <h3>Blood Pressure</h3>
        {bloodPressure.map((item, key) => (
          <ul key={key}>
            {Object.entries(item).map((item1, key1) => (
              <li key={key1}>{item1}</li>
            ))}
          </ul>
        ))}
      </div>
    );
  };

  return (
    <div>
      {loading ? <div className="spinnerModal"></div> : ''}
      <h2>Omron Participant Raw Data</h2>
      {errors && <DisplayErrors errors={errors} />}
      <button type="button" className="btn" onClick={OmronGetParticipantData}>
        Get Data
      </button>
      <button type="button" className="btn" onClick={RefreshToken}>
        Refresh Access
      </button>
      <button type="button" className="btn" onClick={RevokeToken}>
        Revoke Access
      </button>
      {bloodPressure.length > 0 && DisplayBloodPressure(bloodPressure)}
    </div>
  );
};

OmronParticipantDataRaw.propTypes = {
  id: PropTypes.string,
};

export default OmronParticipantDataRaw;
