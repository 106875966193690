import React, {useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import ResetPwdForm from './ResetPwdForm';
import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';

import './AuthForms.css';

const ResetPasswordPage = () => {
  let navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);

  useEffect(() => {
    // Extract the token we got from the url
    const current_url = window.location.href;
    if (current_url.includes('token')) {
      // Get the search part of the url
      const searchStr = window.location.search;
      // Parse the search string parameters
      const searchParams = new URLSearchParams(searchStr);
      // Extract the auth token from the parameters
      const token = searchParams.get('token');
      setToken(token);
    } else {
      setErrors(`Error: Reset key is missing!`);
    }
  }, []);

  useEffect(() => {
    const checkResetPwdToken = async () => {
      try {
        let url = `/auth/resetPasswordValidation`;
        let res = await fetchApiSet(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({token}),
        });
        if (res.errors) {
          throw new Error(`Error: reset password validation unsuccessful!`);
        } else {
          // If everything is ok, display the reset password form
          const userId = res.userId;
          setUserId(userId);
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setErrors(error.message);
        } else {
          setErrors('Error: reset password validation failed!');
        }
      }
    };

    if (token) {
      checkResetPwdToken();
    }
  }, [token]);

  const handleSubmitPwdReq = async (data) => {
    try {
      setLoading(true);
      let res = await fetchApiSet('/auth/resetPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          newPassword: data.newPassword,
          newConfirmPassword: data.confirmNewPassword,
        }),
      });
      setLoading(false);
      if (res.errors) {
        throw new Error(`Error: Could not reset password!`);
      } else {
        // TODO: send email to the user to notify that the password was changed
        // If everything is ok redirect to Dashboard
        // navigate('/');
        setSuccessMsg('New password set successfully!');
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error && error.displayToUser) {
        if (error.errors) {
          if (error.errors[0].path && error.errors[0].msg) {
            setErrors(`${error.errors[0].path}: ${error.errors[0].msg}`);
          }
        } else if (error.message) {
          setErrors(error.message);
        }
      } else {
        setErrors('Error: reset password failed!');
      }
    }
  };

  const handleCancelBtnClicked = () => {
    navigate('/');
  };

  return (
    <>
      {loading ? <div className="spinnerModal"></div> : ''}
      <div className="centered-text">
        <h2>Reset Password</h2>
        {errors && (
          <>
            <DisplayErrors errors={errors} />
            <p className="centered-text">
              <Link to="/ResetPwdReqPage">
                Click to start another reset password request
              </Link>
            </p>
          </>
        )}
        {!errors && successMsg && <div>{successMsg}</div>}
      </div>
      {userId && !errors && !successMsg && (
        <ResetPwdForm
          getDataCallback={handleSubmitPwdReq}
          cancelBtnClickedCallback={handleCancelBtnClicked}
        />
      )}
    </>
  );
};

export default ResetPasswordPage;
