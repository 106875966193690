import React from 'react';
import PropTypes from 'prop-types';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useAuth} from './AuthContext';
import Loading from '../common/Loading';

export const PrivateRoute = ({
  currentUser,
  userRole,
  isLoading,
  allowedRoles = [],
}) => {
  const location = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  if (currentUser) {
    // Role-based access control: check if userRole is in allowedRoles
    const userHasAccess =
      userRole === 'Sys_Director' || allowedRoles.includes(userRole);

    // Handle specific cases for SFA (Second Factor Authentication) requirements
    if (
      currentUser === 'SFAIsNotSet' &&
      location.pathname !== '/SFASetupPage'
    ) {
      return (
        <Navigate
          to={{
            pathname: '/SFASetupPage',
            state: {from: location},
          }}
        />
      );
    } else if (
      currentUser === 'SFAVerifyCodeNeeded' &&
      location.pathname !== '/SFAVerifyPage'
    ) {
      return (
        <Navigate
          to={{
            pathname: '/SFAVerifyPage',
            state: {from: location},
          }}
        />
      );
    } else if (
      currentUser === 'SFAValidateCodeNeeded' &&
      location.pathname !== '/SFAValidatePage'
    ) {
      return (
        <Navigate
          to={{
            pathname: '/SFAValidatePage',
            state: {from: location},
          }}
        />
      );
    } else if (
      currentUser === 'SFAValidateEmailCodeNeeded' &&
      location.pathname !== '/SFASendCodeViaMsgPage'
    ) {
      return (
        <Navigate
          to={{
            pathname: '/SFASendCodeViaMsgPage',
            state: {from: location},
          }}
        />
      );
    } else if (!userRole) {
      // If userRole is not defined, print a message and redirect
      console.warn('User role is not defined. Access denied.');
      return (
        <div>
          <p>User role is not defined. You do not have access to this page.</p>
          <Navigate
            to={{
              pathname: '/not-authorized',
              state: {from: location},
            }}
          />
        </div>
      );
    } else if (!userHasAccess && allowedRoles.length > 0) {
      // If the user does not have access and there are specific roles allowed, deny access
      return (
        <Navigate
          to={{
            pathname: '/not-authorized',
            state: {from: location},
          }}
        />
      );
    } else {
      return <Outlet />;
    }
  } else {
    return (
      <Navigate
        to={{
          pathname: '/LoginPage',
          state: {from: location},
        }}
      />
    );
  }
};

PrivateRoute.propTypes = {
  currentUser: PropTypes.string,
  userRole: PropTypes.string,
  isLoading: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

export const ProtectedRoute = ({allowedRoles, ...props}) => {
  // Accept allowedRoles as a prop
  const {currentUser, userRole, isLoading} = useAuth();
  if (process.env.NODE_ENV !== 'production') {
    // console.log(`Protected currentUser: ${currentUser}`);
    // console.log(`Protected isLoading: ${isLoading}`);
    // console.log(`Protected userRole: ${userRole}`);
  }

  return (
    <PrivateRoute
      currentUser={currentUser}
      userRole={userRole} // Pass userRole to PrivateRoute
      isLoading={isLoading}
      allowedRoles={allowedRoles} // Pass allowedRoles to PrivateRoute
      {...props}
    />
  );
};

ProtectedRoute.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string), // Define allowedRoles prop type
  component: PropTypes.arrayOf(PropTypes.string),
};
