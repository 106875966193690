import {normalizeResponseErrors} from '../errorHandling/NormalizeErrors';

const SignupApi = (data) => {
  const {
    username,
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    organization,
    teamId,
    teamName,
    role,
  } = data;
  return fetch(`/users/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
      confirmPassword,
      email,
      firstName,
      lastName,
      organization,
      teamId,
      teamName,
      role,
    }),
  })
    .then((res) => normalizeResponseErrors(res))
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let msg = '';
      if (err && err.displayToUser) {
        if (err.errors) {
          if (err.errors[0].path && err.errors[0].msg) {
            msg = `${err.errors[0].path}: ${err.errors[0].msg}`;
          }
        } else {
          if (err.reason === 'ValidationError') {
            msg = `${err.location} ${err.message}`;
          } else if (err.reason === 'DBError') {
            msg = `${err.reason} ${err.errno} ${err.code}`;
          } else if (err.message) {
            msg = err.message;
          }
        }
      } else {
        msg = `Signup unsuccessful!`;
      }

      // Could not authenticate, return error message to the form
      return Promise.reject(msg);
    });
};

const LoginApi = async (data) => {
  try {
    const {username, password} = data;
    let res = await fetch(`/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });
    res = await normalizeResponseErrors(res);
    res = await res.json();
    if (res) {
      return res;
    }
  } catch (err) {
    let msg = '';
    if (err.reason === 'ValidationError') {
      msg = `${err.location} ${err.message}`;
    } else if (err.reason === 'DBError') {
      msg = `${err.reason} ${err.errno} ${err.code}`;
    } else if (err.message && err.displayToUser) {
      msg = err.message;
    } else {
      // Generic error message for unhandled cases
      msg = 'Login failed - Please try again.';
    }

    // Could not authenticate, return error message to the form
    throw new Error(msg);
  }
};

const LogoutApi = async () => {
  try {
    // Perform the fetch request
    const response = await fetch(`/auth/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // Include any necessary data here
        // username,
        // password,
      }),
    });

    // Normalize the response errors
    await normalizeResponseErrors(response);

    // Parse the response as JSON
    const data = await response.json();

    // Return the parsed data
    return data;
  } catch (err) {
    // Error handling
    let msg = '';
    if (err.reason === 'ValidationError') {
      msg = `${err.location} ${err.message}`;
    } else if (err.reason === 'DBError') {
      msg = `${err.reason} ${err.errno} ${err.code}`;
    } else if (err.message) {
      msg = err.message;
    } else {
      msg = 'An unknown error occurred.';
    }

    // Throw the error to be caught by the caller
    throw new Error(msg);
  }
};

export const UserAuthApi = {LoginApi, LogoutApi, SignupApi};
