import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';

import {useYupValidationResolver} from '../forms/YupValidationResolver';
import {onFormSubmitRecaptchaVerification} from '../common/googleRecaptcha';

import './AuthForms.css';

// Yup form validation rules
const validationSchema = yup.object({
  email: yup.string().required('Email is required').email('Email is invalid'),
});

function ResetPwdReqForm({getDataCallback, cancelBtnClickedCallback}) {
  const resolver = useYupValidationResolver(validationSchema);
  const [regError, setRegError] = useState('');

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  const onSubmit = async (data) => {
    await window.grecaptcha.ready(async () => {
      try {
        const res = await onFormSubmitRecaptchaVerification();
        if (res.errors) {
          // If reCAPTCHA version 3 failed, display an error
          throw new Error(res.errors);
        } else {
          // Upon success send data back to the parent
          getDataCallback(data);
        }
      } catch (error) {
        console.error(error);
        if (error && error.displayToUser && error.message) {
          setRegError(error.message);
        } else {
          setRegError(`reCAPTCHA V3 verification failed!`);
        }
      }
    });
  };

  const setRegErrorMessage = () => {
    setRegError('');
  };

  return (
    <>
      <form
        className="reset-pwd-req-form centered-container narrow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="reset-pwd-req-msg">
          Enter your registered email address to receive a password reset link.
        </div>
        <div className="form-error centered-text">{regError}</div>
        <fieldset>
          <label>*Email</label>
          <div className="form-error" aria-live="polite">
            {errors.email?.message}
          </div>
          <input
            name="email"
            type="text"
            {...register('email')}
            onFocus={setRegErrorMessage}
            className={`form-input ${errors.email ? 'is-invalid' : ''}`}
          />
        </fieldset>

        <div className="form-group centered-text">
          <button type="submit" className="btn btn-primary">
            Reset Password
          </button>
          <button
            type="button"
            className="btn cancel-btn"
            onClick={() => cancelBtnClickedCallback()}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
}

ResetPwdReqForm.propTypes = {
  getDataCallback: PropTypes.func,
  cancelBtnClickedCallback: PropTypes.func,
};
export default ResetPwdReqForm;
