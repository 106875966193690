import React from 'react';
import PropTypes from 'prop-types';

import FitbitParticipantAuthAccess from './FitbitParticipantAuthAccess';
import FitbitSendAuthRequest from './FitbitSendAuthRequest';

import './FitbitParticipantConsent.css';

const FitbitParticipantConsent = ({participant}) => {
  return (
    <>
      <h3>Consent!</h3>
      <div className="fitbit-consent-container">
        <FitbitParticipantAuthAccess participantId={participant.id} />
        <FitbitSendAuthRequest participant={participant} />
      </div>
    </>
  );
};

FitbitParticipantConsent.propTypes = {
  participant: PropTypes.object,
};

export default FitbitParticipantConsent;
