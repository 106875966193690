import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {useAuth} from '../../authorization/AuthContext';
import AddParticipantForm from './AddParticipantForm';
import {AddParticipantApi} from './AddParticipantApi';
import PhiWarning from '../../SecurityAndComplianceWarnings/PhiWarning';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';
import Modal from '../../modal/Modal';

import './AddParticipantPage.css';

const AddParticipantPage = ({trialInfo, getParticipantDataCallback}) => {
  const [errors, setErrors] = useState('');
  const [newParticipantModal, setNewParticipantModal] = useState(false);

  const handleSubmitAddNewParticipant = async (data) => {
    try {
      // Upon success send an api request to add the new participant
      // setLoading(true);
      // Add the current trial id to the data
      data.trialId = trialInfo.trialId;
      console.log(
        `handleSubmitAddNewParticipant - DATA: ${JSON.stringify(data)}`
      );
      const res = await AddParticipantApi({data});
      if (res.errors) {
        throw new Error(
          `Error: Adding new participant failed! - ${res.errors[0].errorType}`
        );
      } else {
        // If everything is ok go back to the current trial page
        setNewParticipantModal(false);
        if (!res.participantId) {
          throw new Error(`Error: Adding new participant failed. NO ID!}`);
        }
        // insert the new participant id to data
        data.participantId = res.participantId;
        getParticipantDataCallback(data);
      }
    } catch (err) {
      console.error(err.message);
      let msg = '';

      if (err && err.displayToUser) {
        if (err.errors) {
          if (err.errors[0].msg) {
            setErrors(`${err.errors[0].msg}`);
          }
        } else if (err.message) {
          if (err.reason === 'ValidationError') {
            msg = `${err.location} ${err.message}`;
          } else if (err.reason === 'DBError') {
            msg = `${err.reason} ${err.errno} ${err.code}`;
          } else if (err.message) {
            msg = err.message;
          }
          // Could not add the new participant, display error message
          setErrors(`Adding participant failed: ${msg}`);
        }
      } else {
        setErrors('Adding participant failed!');
      }
    }
  };

  const handleAddNewParticipant = async () => {
    setErrors(null);
    setNewParticipantModal(true);
  };

  const handleCancelBtnClicked = () => {
    setNewParticipantModal(false);
  };

  const closeModal = () => {
    setNewParticipantModal(false);
  };

  const {userRole} = useAuth(); // Get user's role

  return (
    <div className="add-participant">
      {(userRole === 'Sys_Director' ||
        userRole === 'Admin' ||
        userRole === 'User') && (
        <button onClick={() => handleAddNewParticipant()}>
          Add New Participant
        </button>
      )}
      {newParticipantModal && (
        <Modal
          title={'Add New Participant'}
          text={<PhiWarning />}
          closeModalCallback={closeModal}
        >
          <div className="centered-text">
            {<DisplayErrors errors={errors} />}
          </div>
          <AddParticipantForm
            getDataCallback={handleSubmitAddNewParticipant}
            cancelBtnClickedCallback={handleCancelBtnClicked}
          />
        </Modal>
      )}
    </div>
  );
};

AddParticipantPage.propTypes = {
  trialInfo: PropTypes.object,
  getParticipantDataCallback: PropTypes.func,
};

export default AddParticipantPage;
