import {fetchApiGet} from '../api/fetchApi';

const getAppVersion = async () => {
  const url = '/appVersion';
  const res = await fetchApiGet(url);
  if (res.data) {
    console.log(res.data);
  }

  return res.data;
};

export {getAppVersion};
