import {normalizeResponseErrors} from '../errorHandling/NormalizeErrors';

const SignupApi = (data) => {
  const {username, email, password, confirmPassword} = data;
  return fetch(`/Participants/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
      confirmPassword,
      email,
    }),
  })
    .then((res) => normalizeResponseErrors(res))
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let msg = '';
      if (err && err.displayToUser) {
        if (err.errors) {
          if (err.errors[0].path && err.errors[0].msg) {
            msg = `${err.errors[0].path}: ${err.errors[0].msg}`;
          }
        } else {
          if (err.reason === 'ValidationError') {
            msg = `${err.location} ${err.message}`;
          } else if (err.reason === 'DBError') {
            msg = `${err.reason} ${err.errno} ${err.code}`;
          } else if (err.message) {
            msg = err.message;
          }
        }
      } else {
        msg = `Signup unsuccessful!`;
      }

      // Could not authenticate, return error message to the form
      return Promise.reject(msg);
    });
};

const LoginApi = (data) => {
  const {username, password} = data;
  return fetch(`/Participants/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  })
    .then((res) => normalizeResponseErrors(res))
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let msg = '';
      if (err.reason === 'ValidationError') {
        msg = `${err.location} ${err.message}`;
      } else if (err.reason === 'DBError') {
        msg = `${err.reason} ${err.errno} ${err.code}`;
      } else if (err.message) {
        msg = err.message;
      }
      // Could not authenticate, return error message to the form
      return Promise.reject(msg);
    });
};

const LogoutApi = () => {
  //const {username, password} = data;
  return fetch(`/participants/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      //username,
      //password,
    }),
  })
    .then((res) => normalizeResponseErrors(res))
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let msg = '';
      if (err.reason === 'ValidationError') {
        msg = `${err.location} ${err.message}`;
      } else if (err.reason === 'DBError') {
        msg = `${err.reason} ${err.errno} ${err.code}`;
      } else if (err.message) {
        msg = err.message;
      }
      // Could not authenticate, return error message to the form
      return Promise.reject(msg);
    });
};

export const ParticipantAuthApi = {LoginApi, LogoutApi, SignupApi};
