import React from 'react';
import {useNavigate} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {useTrialInfo} from './TrialInfoContext';

const TrialInfo = () => {
  let {currentTrialInfo} = useTrialInfo();
  const navigate = useNavigate();

  // If currentTrialInfo doesn't exist in useTrialInfo context (can happen after page refresh)
  // try to update it from the localStorage
  if (!currentTrialInfo) {
    try {
      // Check if trial info exists in localStorage
      currentTrialInfo = JSON.parse(localStorage.getItem('trialInfo'));
    } catch (error) {
      console.error('Reading Trial Info from localStorage failed');
    }
  }

  const handleCurrentTrialButtonClicked = () => {
    navigate('/TrialPage', {
      state: currentTrialInfo,
    });
  };

  return (
    <div>
      {currentTrialInfo && (
        <button
          onClick={() => handleCurrentTrialButtonClicked()}
          data-tip
          data-for="return_to_trial"
        >
          <h2>{`Trial: #${currentTrialInfo.trialNumber} - ${currentTrialInfo.trialName}`}</h2>
          <ReactTooltip
            id="return_to_trial"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Return to main trial page
          </ReactTooltip>{' '}
        </button>
      )}
    </div>
  );
};

export default TrialInfo;
