import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import TrialUsersPage from './TrialUsersPage';
import {getLocationState} from '../../services/URL/urlLocation';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import TrialInfo from '../trials/TrialInfo';

const TrialUsers = () => {
  const location = useLocation();
  const [trialInfo, setTrialInfo] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    try {
      const res = getLocationState(location, 'TrialUsers');
      if (res.data) {
        setTrialInfo(res.data.trialInfo);
      }
    } catch (error) {
      console.error(error);
      setError('Something went wrong! We could not find trial and users info.');
    }
  }, [location]);

  return (
    <>
      {error && <DisplayErrors errors={error} />}
      <TrialInfo />
      {trialInfo && <TrialUsersPage trialInfo={trialInfo} />}
    </>
  );
};

export default TrialUsers;
