import React, {useMemo, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useSortBy,
  useFilters,
  useRowSelect,
  hiddenColumns,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import {FaSearch} from 'react-icons/fa';
import {AiOutlineClose} from 'react-icons/ai';
import {IoIosArrowUp} from 'react-icons/io';
import {IoIosArrowDown} from 'react-icons/io';
import {IoEllipsisVerticalSharp} from 'react-icons/io5';

import {exportDataCSV, exportDataXLSX} from './ExportFileBlob';
import IndeterminateCheckbox from '../inputElements/IndeterminateCheckbox';

import './Table.css';

const Table = ({
  deviceName,
  tableName,
  data,
  columns,
  dataCallback,
  ...rest
}) => {
  deviceName = deviceName || '';
  tableName = tableName || '';

  // Get columns and data from the parent component
  columns = useMemo(() => columns, [columns]);
  data = useMemo(() => data, [data]);
  // Get all additional required features if applicable
  const {
    multipleRowsSelection = false,
    singleRowSelection = false,
    maxNumOfSelectedRows = 4,
    displayGlobalFilter = false,
    displayPerColFilter = false,
    exportDataToFile = false,
    userCanHideCols = false,
  } = rest.features;

  if (!rest.hideColsProps) {
    // In case of no hideColsProps just set as an empty object
    rest.hideColsProps = {};
  }
  const {
    hiddenColsOnInitialState = ['selection'], // Always hide 'selection' as default
    storageUserHiddenColsKey = '',
    colsListCanBeHiddenByUser = [],
  } = rest.hideColsProps;

  // Start with select multiple rows as false
  const [multipleRowsActive, setMultipleRowsActive] = useState(false);

  const defaultColumn = useMemo(
    () => ({
      // Set up a default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const multipleRowsCheckboxClicked = (event) => {
    if (event.target.checked === true) {
      setMultipleRowsActive(true);
    } else {
      setMultipleRowsActive(false);
    }
  };

  const handleRowClicked = (row) => {
    // console.log('Row CLicked' + JSON.stringify(row, null, 2));
    if (dataCallback) {
      dataCallback(row);
    }
  };

  // Show/Hide columns option
  /***************************/
  const [openShowHideColsList, setOpenShowHideColsList] = useState(false);
  const ref = useRef();
  const isFirstRender = useRef(true);
  let hiddenCols = hiddenColsOnInitialState;
  let userHiddenCols = [];

  if (userCanHideCols) {
    try {
      // Check that list of hidden columns by the user exists in localStorage
      if (localStorage.getItem(storageUserHiddenColsKey) === null) {
        // If there is no list in localStorage add an empty new list there
        localStorage.setItem(storageUserHiddenColsKey, JSON.stringify([]));
      } else {
        // Get user's list of hidden columns from localStorage
        // and store it in a local array
        userHiddenCols = JSON.parse(
          localStorage.getItem(storageUserHiddenColsKey)
        );
      }

      // Add user's list of hidden columns also to the actual hiddenCols array
      if (userHiddenCols.length > 0) {
        hiddenCols = hiddenCols.concat(userHiddenCols);
      }
    } catch (error) {
      console.error('Reading list of hidden cols from localStorage failed');
    }
  }

  const handleShowHideColCheckboxChanged = (col) => {
    if (col.isVisible) {
      // If the state of the current column was changed to visible and found in the hidden columns list,
      // remove its name from there
      if (userHiddenCols && userHiddenCols.includes(col.id)) {
        userHiddenCols = userHiddenCols.filter((item) => item !== col.id);
        // Update localStorage
        localStorage.setItem(
          storageUserHiddenColsKey,
          JSON.stringify(userHiddenCols)
        );
      }
    } else {
      // col.isVisible === false
      // If the state of the current column was changed to hidden,
      // and its name is not in the hidden columns list, add the name to the list
      if (userHiddenCols && !userHiddenCols.includes(col.id)) {
        userHiddenCols.push(col.id);
        localStorage.setItem(
          storageUserHiddenColsKey,
          JSON.stringify(userHiddenCols)
        );
      }
    }
  };

  const openShowHideColsListBtnClicked = () => {
    setOpenShowHideColsList(!openShowHideColsList);
  };

  // Display checkboxes of columns that users can toggle between show and hide
  const ShowHideColsList = () => {
    return allColumns.map((column) => {
      if (colsListCanBeHiddenByUser.includes(column.id)) {
        return (
          <div key={column.id}>
            <label>
              <input
                type="checkbox"
                onChange={handleShowHideColCheckboxChanged(column)}
                {...column.getToggleHiddenProps()}
              />{' '}
              {column.Header}
            </label>
          </div>
        );
      }
    });
  };

  // We don't support this feature yet Shlomi 12/12/2023
  // const handleClearAllColSelections = () => {
  //   console.log('handleClearAllColSelections');
  // };

  // Detect clicks outside the show/hide columns menu and close it in these cases
  useEffect(() => {
    const checkIfClickedOutside = (evt) => {
      // If the menu is open and the clicked target is not within the menu,
      // close the menu
      if (
        openShowHideColsList &&
        ref.current &&
        !ref.current.contains(evt.target)
      ) {
        setOpenShowHideColsList(false);
      }
    };

    // Event listener to detect mouse clicks outside the menu
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });

  const tableData = useTable(
    {
      columns,
      data,
      // Manually reset the page when needed otherwise there are unwanted
      // resets upon returning from other pages.
      autoResetPage: false,
      defaultColumn,
      hiddenColumns,
      // Set csv file name (We are actually changing the default file name here).
      // TODO: make file name dynamic, options: 1. add an hidden column with the preferred name
      // 2. send the name as a parameter
      getExportFileName: ({all}) => {
        return `${
          all ? `CDConnect-csv-export` : 'CDConnect-current-view-csv-export'
        }`;
      },
      initialState: {
        pageIndex: 0,
        hiddenColumns: hiddenCols, // Set initial state to hide the selected columns
        pageSize: rest.features.pageSize || 10,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Add a 'multiple rows selection' column
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: function Header({getToggleAllRowsSelectedProps}) {
            return (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            );
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to render a checkbox
          Cell: function Cell({row}) {
            return (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            );
          },
        },
        ...columns,
      ]);
    }
  );

  const submitSelectedRows = (rows) => {
    if (dataCallback) {
      dataCallback(rows, 'multipleRows');
    }
  };

  const renderMultipleRowsSelection = () => {
    return (
      <>
        <label htmlFor="SelectMultipleRows">
          <input
            type="checkbox"
            name="SelectMultipleRows"
            id="SelectMultipleRows"
            // onClick toggle the state of select multiple rows
            onClick={multipleRowsCheckboxClicked}
            // onChange show/hide the multiple rows selection column
            {...allColumns[0].getToggleHiddenProps()}
          />
          Select Multiple Rows
        </label>
        {/* Multiple rows selection data */}
        <div className="selected-rows-controllers-container">
          {multipleRowsActive && (
            <div>Marked Rows: {Object.keys(selectedRowIds).length}</div>
          )}
          {multipleRowsActive &&
            Object.keys(selectedRowIds).length > 0 &&
            (Object.keys(selectedRowIds).length > maxNumOfSelectedRows ? (
              <div>Max Number of Selected Rows: {maxNumOfSelectedRows}</div>
            ) : (
              <button
                className="btn"
                onClick={() => submitSelectedRows(selectedFlatRows)}
              >
                Select Marked Rows
              </button>
            ))}
        </div>
      </>
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    selectedFlatRows,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setSortBy,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize, selectedRowIds, sortBy},
  } = tableData;

  // Load states from local storage
  useEffect(() => {
    let sessionCurrentPage = null;
    let sessionGlobalFilter = null;
    let sessionSortBy = null;
    switch (tableName) {
      case 'participantsTable':
        sessionCurrentPage = sessionStorage.getItem(
          'currentParticipantsTableLastPage'
        );
        sessionGlobalFilter = sessionStorage.getItem(
          'currentParticipantsTableGlobalFilter'
        );
        sessionSortBy = sessionStorage.getItem(
          'currentParticipantsTableSortBy'
        );
        break;
      case 'trialsTable':
        sessionCurrentPage = sessionStorage.getItem(
          'currentTrialsTableLastPage'
        );
        sessionGlobalFilter = sessionStorage.getItem(
          'currentTrialsTableGlobalFilter'
        );
        sessionSortBy = sessionStorage.getItem('currentTrialsTableSortBy');
        break;
    }

    if (sessionSortBy) {
      setSortBy(JSON.parse(sessionSortBy));
    }
    if (sessionGlobalFilter !== undefined) {
      setGlobalFilter(sessionGlobalFilter);
    }
    if (sessionCurrentPage) {
      gotoPage(JSON.parse(sessionCurrentPage));
    }

    isFirstRender.current = false;
  }, [gotoPage, tableName, setGlobalFilter, setSortBy]);

  // Save current states to local storage,
  // so that they can be retrieved when the user returns to the table from another page.
  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    switch (tableName) {
      case 'participantsTable':
        sessionStorage.setItem(
          'currentParticipantsTableLastPage',
          JSON.parse(pageIndex)
        );
        break;
      case 'trialsTable':
        sessionStorage.setItem(
          'currentTrialsTableLastPage',
          JSON.parse(pageIndex)
        );
        break;
    }
  }, [pageIndex, tableName]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    switch (tableName) {
      case 'participantsTable':
        sessionStorage.setItem(
          'currentParticipantsTableGlobalFilter',
          state.globalFilter !== undefined ? state.globalFilter : ''
        );
        break;
      case 'trialsTable':
        sessionStorage.setItem(
          'currentTrialsTableGlobalFilter',
          state.globalFilter !== undefined ? state.globalFilter : ''
        );
        break;
    }
  }, [tableName, state.globalFilter]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    switch (tableName) {
      case 'participantsTable':
        sessionStorage.setItem(
          'currentParticipantsTableSortBy',
          JSON.stringify(sortBy)
        );
        break;
      case 'trialsTable':
        sessionStorage.setItem(
          'currentTrialsTableSortBy',
          JSON.stringify(sortBy)
        );
        break;
    }
  }, [tableName, sortBy]);

  const buildFileName = (deviceName, tableName, fileType) => {
    let fileName = 'CDConnect';

    if (deviceName) {
      fileName += `-${deviceName}`;
    }

    if (tableName) {
      fileName += `-${tableName}`;
    }

    fileName += fileType === 'CSV' ? '-Data.csv' : '-Data.xlsx';
    return fileName;
  };

  return (
    <>
      {/* Export data */}
      {exportDataToFile && (
        <div className="export-data dropdown">
          <IoEllipsisVerticalSharp className="export-data icon" />
          <div className={'dropdown-content'}>
            <a
              onClick={() => {
                let fileName = buildFileName(deviceName, tableName, 'CSV');
                exportDataCSV(columns, data, fileName);
              }}
            >
              Export data as CSV
            </a>
            <a
              className="export-data-btn"
              onClick={() => {
                let fileName = buildFileName(deviceName, tableName, 'XLSX');
                exportDataXLSX(columns, data, fileName);
              }}
            >
              Export data as XLSX
            </a>
          </div>
        </div>
      )}
      <div className="table-controllers-container">
        {/* Set a global filter to be able to search in the entire table*/}
        <div className="table-controllers-item">
          {displayGlobalFilter && (
            <div colSpan={visibleColumns.length} className="global-filter-col">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                gotoPage={gotoPage}
              />
            </div>
          )}
        </div>
        <div className="table-controllers-item">
          {/* Show/Hide columns list */}
          {userCanHideCols && (
            <div className="show-hide-cols-list-wrapper" ref={ref}>
              <button onClick={openShowHideColsListBtnClicked} className="">
                {openShowHideColsList ? (
                  <IoIosArrowUp className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
                Columns Selection
                {userHiddenCols.length > 0 && (
                  <div>{userHiddenCols.length} Hidden</div>
                )}
              </button>
              <div
                className={
                  openShowHideColsList
                    ? 'show-hide-cols-list-active'
                    : 'show-hide-cols-list'
                }
              >
                {openShowHideColsList && (
                  <div>
                    <ShowHideColsList />
                    {/* <br />
                    <button
                      className="trials-filter-btn"
                      onClick={() => {
                        handleClearAllColSelections();
                      }}
                    >
                      Clear All
                    </button> */}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Multiple rows selection */}
      {multipleRowsSelection && renderMultipleRowsSelection()}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, colIndex) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={colIndex}
                >
                  {column.render('Header')}
                  {/* Add sorting capability */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <IoIosArrowUp className="sort-arrow" />
                      ) : (
                        <IoIosArrowDown className="sort-arrow" />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                  {/* Display per column filters if applicable */}
                  {displayPerColFilter && (
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={rowIndex}
                // Single row selection - only if no multiple rows selection
                onClick={
                  singleRowSelection === true && multipleRowsSelection === false
                    ? () => handleRowClicked(row.original)
                    : null
                }
              >
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td {...cell.getCellProps()} key={cellIndex}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={pageSize}
          onChange={(evt) => {
            setPageSize(Number(evt.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>{' '}
        <span>
          Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(evt) => {
              const page = evt.target.value ? Number(evt.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>
      </div>
    </>
  );
};

// Set the global filter for the entire table
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  gotoPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState('');
  const onChange = useAsyncDebounce((value) => {
    gotoPage(0);
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    if (globalFilter) {
      setValue(globalFilter);
    } else {
      setValue('');
    }
  }, [globalFilter]);

  return (
    <div className="global-filter-fields input-container">
      <i>
        <FaSearch className="search-icon" />
      </i>
      <input
        className="input-field"
        value={value || ''}
        onChange={(evt) => {
          setValue(evt.target.value);
          onChange(evt.target.value);
        }}
        placeholder={`${count} records found`}
      />
      {value && (
        <AiOutlineClose
          className="clear-search-icon"
          onClick={() => {
            setValue('');
            onChange('');
          }}
        />
      )}
    </div>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: {filterValue, preFilteredRows, setFilter},
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ''}
        onChange={(evt) => {
          setFilter(evt.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search  ${count} records...`}
      />
    </div>
  );
}

Table.propTypes = {
  deviceName: PropTypes.string,
  tableName: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  dataCallback: PropTypes.func,
};

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array,
  globalFilter: PropTypes.func,
  setGlobalFilter: PropTypes.func,
  gotoPage: PropTypes.func,
};

Table.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.func,
  row: PropTypes.array,
};

DefaultColumnFilter.propTypes = {
  column: PropTypes.object,
};

export default Table;
