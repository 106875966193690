import {DateTime} from 'luxon';

import {
  kmsToMiles,
  kgsToLbs,
  roundToTwoDecimal,
} from '../../../services/units/unitsConversion';
import {safelyGetValueNestedObj} from '../../../services/objects/objectAccess';
import {
  minutesToHoursMinutes,
  ShortDateTimeToTimeOnly,
} from '../../../services/date&time/DateTimeUtils';

const prepareDateValueData = (content) => {
  // content.data is always in the structure: [{resource-name: Array(n)}]
  // The Array has n objects in it in the format: {dateTime: 'yyyy-mm-dd', value: 'x'}
  // Get only the date/value objects for display
  const data = safelyGetValueNestedObj(
    () => content.data[Object.keys(content.data)[0]],
    ''
  );
  const subject = content.subject;
  let tableSubject = subject;
  // Edit the names of the keys, based on the current data
  content.data = data.map((item) => {
    let value = safelyGetValueNestedObj(() => item.value, '');
    let date = safelyGetValueNestedObj(() => item.dateTime, '');
    let tableValue = value;
    // Convert date to locale
    date = DateTime.fromISO(date).toLocaleString();
    // TODO: for now we are assuming that we always getting the data in the same units,
    // in the future we will need to check the units we are getting from Fitbit
    // Convert values to number and to the right units if needed
    switch (subject) {
      case 'Distance':
        value = Number(kmsToMiles(value));
        tableValue = value;
        break;
      case 'Weight':
        value = Number(kgsToLbs(value));
        tableValue = value;
        break;
      case 'Minutes Sedentary':
        value = Number(value);
        // tableValue = minutesToHoursMinutes(value);
        tableValue = value;
        tableSubject = `Table ${subject}`;
        break;
      case 'BMI':
        value = Number(value);
        value = roundToTwoDecimal(value);
        tableValue = value;
        break;
      default:
        value = Number(value);
        tableValue = value;
    }
    return {
      date: date,
      [subject]: value,
      [tableSubject]: tableValue,
    };
  });

  // Add info of how to display the data in the chart
  let color = '';
  let units = '';
  switch (subject) {
    case 'Distance':
      color = '#7DB3FF';
      units = 'Miles';
      break;
    case 'Weight':
      color = '#07e843';
      units = 'Lbs';
      break;
    default:
      color = '#7DB3FF';
      units = '';
      break;
  }
  content.chartInfo = [
    {valueName: subject, valueColor: color, units: ` ${units}`},
  ];
  content.xAxisKey = ['date'];
  // Add info of how to display the data in the table
  content.columns = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: `${subject} ${units ? '(' + units + ')' : ''}`,
      accessor: tableSubject,
    },
  ];
  return content;
};

const prepareRestingHeartRateData = (content) => {
  const data = safelyGetValueNestedObj(
    () => content.data['activities-heart'],
    ''
  );

  content.data = data.map((item) => {
    let date = safelyGetValueNestedObj(() => item.dateTime, '');
    // Convert date to locale
    date = DateTime.fromISO(date).toLocaleString();
    return {
      date: date,
      RHR: safelyGetValueNestedObj(
        () => Number(item.value.restingHeartRate),
        0
      ),
    };
  });

  // Add info of how to display the data in the chart
  content.chartInfo = [{valueName: 'RHR', valueColor: 'tomato'}];
  content.xAxisKey = ['date'];
  // Add info of how to display the data in the table
  content.columns = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Resting Heart Rate (BPM)',
      accessor: 'RHR',
    },
  ];
  return content;
};

const prepareTotalTrackerActiveMinutesData = (content) => {
  const data = safelyGetValueNestedObj(() => content.data, '');
  let obj = {};
  content.chartInfo = [];

  // Get only the data that we want to display
  // Data from Fitbit is in descending order we need to reverse it
  content.data = data.map((item) => {
    let date = safelyGetValueNestedObj(() => item.dateTime, '');
    // Convert date to locale
    date = DateTime.fromISO(date).toLocaleString();

    const lightlyActiveMins = safelyGetValueNestedObj(
      () => Number(item.lightlyActive),
      0
    );
    const fairlyActiveMins = safelyGetValueNestedObj(
      () => Number(item.fairlyActive),
      0
    );
    const veryActiveMins = safelyGetValueNestedObj(
      () => Number(item.veryActive),
      0
    );

    const totalFairlyVeryMins = safelyGetValueNestedObj(
      () => Number(item.totalFairlyVeryMins),
      0
    );

    const totalActiveMins =
      lightlyActiveMins + fairlyActiveMins + veryActiveMins;
    const lightlyActivePercent = (
      (lightlyActiveMins / totalActiveMins) *
      100
    ).toFixed(1);
    const fairlyActivePercent = (
      (fairlyActiveMins / totalActiveMins) *
      100
    ).toFixed(1);
    const veryActivePercent = (
      (veryActiveMins / totalActiveMins) *
      100
    ).toFixed(1);

    obj = {};
    obj.date = date;
    obj['Lightly Active'] = lightlyActiveMins;
    obj['Lightly Active HourMin'] = minutesToHoursMinutes(lightlyActiveMins);
    obj['Lightly Active percent'] = lightlyActivePercent;

    obj['Fairly Active'] = fairlyActiveMins;
    obj['Fairly Active HourMin'] = minutesToHoursMinutes(fairlyActiveMins);
    obj['Fairly Active percent'] = fairlyActivePercent;

    obj['Very Active'] = veryActiveMins;
    obj['Very Active HourMin'] = minutesToHoursMinutes(veryActiveMins);
    obj['Very Active percent'] = veryActivePercent;

    obj['totalFairlyVeryMins'] = totalFairlyVeryMins;
    obj['totalFairlyVeryMins HourMin'] =
      minutesToHoursMinutes(totalFairlyVeryMins);
    return obj;
  });

  // Add info of how to display the data in the chart
  content.chartInfo = [
    {valueName: 'Lightly Active', valueColor: '#7DB3FF', units: ' minutes'},
    {valueName: 'Fairly Active', valueColor: '#0066ff', units: ' minutes'},
    {valueName: 'Very Active', valueColor: '#041a94', units: ' minutes'},
  ];

  content.pieChartInfo = [
    {
      valueName: 'Lightly Active',
      valueColor: '#7DB3FF',
      units: ' minutes',
      chart: 'pie',
    },
    {
      valueName: 'Fairly Active',
      valueColor: '#0066ff',
      units: ' minutes',
      chart: 'pie',
    },
    {
      valueName: 'Very Active',
      valueColor: '#041a94',
      units: ' minutes',
      chart: 'pie',
    },
  ];
  content.xAxisKey = ['date'];
  content.displayStyle = {customTooltip: true};
  // Add info of how to display the data in the table
  content.columns = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Lightly Active (Mins)',
      accessor: 'Lightly Active',
    },
    {
      Header: 'Fairly Active (Mins)',
      accessor: 'Fairly Active',
    },
    {
      Header: 'Very Active (Mins)',
      accessor: 'Very Active',
    },
    {
      Header: 'Total (Fairly + Very) (Mins)',
      accessor: 'totalFairlyVeryMins',
    },
  ];

  return content;
};

const prepareSleepDurationData = (content, target) => {
  const data = safelyGetValueNestedObj(() => content.data.sleep, '');
  let obj = {};
  content.chartInfo = [];

  // Get only the data that we want to display
  content.data = data
    .slice(0)
    .reverse() // Data from Fitbit is in descending order we need to reverse it
    .map((item) => {
      let date = safelyGetValueNestedObj(() => item.dateOfSleep, '');
      // Convert date to locale
      date = DateTime.fromISO(date).toLocaleString();
      let startTime = safelyGetValueNestedObj(() => item.startTime, '');
      // Convert to locale
      startTime = DateTime.fromISO(startTime).toLocaleString(
        DateTime.DATETIME_SHORT
      );
      let endTime = safelyGetValueNestedObj(() => item.endTime, '');
      // Convert to locale
      endTime = DateTime.fromISO(endTime).toLocaleString(
        DateTime.DATETIME_SHORT
      );
      let timeAsleep = safelyGetValueNestedObj(() => item.minutesAsleep, '');
      timeAsleep = minutesToHoursMinutes(timeAsleep);
      const startTimeOnly = ShortDateTimeToTimeOnly(startTime);
      const endTimeOnly = ShortDateTimeToTimeOnly(endTime);

      const deepMins = safelyGetValueNestedObj(
        () => Number(item.levels.summary.deep.minutes),
        0
      );
      const lightMins = safelyGetValueNestedObj(
        () => Number(item.levels.summary.light.minutes),
        0
      );
      const remMins = safelyGetValueNestedObj(
        () => Number(item.levels.summary.rem.minutes),
        0
      );
      const awakeMins = safelyGetValueNestedObj(
        () => Number(item.levels.summary.wake.minutes),
        0
      );

      const totalSleepMins = deepMins + lightMins + remMins + awakeMins;

      const deepPercent = ((deepMins / totalSleepMins) * 100).toFixed(1);
      const lightPercent = ((lightMins / totalSleepMins) * 100).toFixed(1);
      const remPercent = ((remMins / totalSleepMins) * 100).toFixed(1);
      const awakePercent = ((awakeMins / totalSleepMins) * 100).toFixed(1);

      obj = {};
      obj.date = date;
      obj['timeAsleep'] = timeAsleep;
      obj['start time'] = startTime;
      obj['end time'] = endTime;
      obj['StartTimeOnly'] = startTimeOnly;
      obj['EndTimeOnly'] = endTimeOnly;
      obj.efficiency = safelyGetValueNestedObj(() => item.efficiency, '');
      obj.deep = deepMins;
      obj['deep HourMin'] = minutesToHoursMinutes(deepMins);
      obj['deep percent'] = deepPercent;

      obj.light = lightMins;
      obj['light HourMin'] = minutesToHoursMinutes(lightMins);
      obj['light percent'] = lightPercent;

      obj.rem = remMins;
      obj['rem HourMin'] = minutesToHoursMinutes(remMins);
      obj['rem percent'] = remPercent;

      obj.awake = awakeMins;
      obj['awake HourMin'] = minutesToHoursMinutes(awakeMins);
      obj['awake percent'] = awakePercent;

      return obj;
    });

  // Add info of how to display the data in the main chart
  content.chartInfo = [
    {
      valueName: 'light',
      valueColor: '#7DB3FF',
      units: ' mins',
      chart: 'bar',
    },
    {
      valueName: 'rem',
      valueColor: '#ea57f2',
      units: ' mins',
      chart: 'bar',
    },
    {
      valueName: 'deep',
      valueColor: '#041a94',
      units: ' mins',
      chart: 'bar',
    },
    {
      valueName: 'awake',
      valueColor: '#f21d56',
      units: ' mins',
      chart: 'bar',
    },
    {
      valueName: 'efficiency',
      valueColor: '#FF6347',
      units: ' %',
      chart: 'line',
    },
  ];
  content.xAxisKey = ['start time', 'date'];
  content.yAxisInfo = [
    {
      type: 'number',
      orientation: 'left',
      fontSize: '80%',
      label: {value: 'Duration', angle: -90, fontSize: '90%'},
    },
    {
      type: 'number',
      orientation: 'right',
      fontSize: '80%',
      label: {
        value: 'Efficiency',
        angle: -90,
        fontSize: '90%',
      },
    },
  ];

  content.pieChartInfo = [
    {
      valueName: 'light',
      valueColor: '#7DB3FF',
      units: ' mins',
      chart: 'pie',
    },
    {
      valueName: 'rem',
      valueColor: '#ea57f2',
      units: ' mins',
      chart: 'pie',
    },
    {
      valueName: 'deep',
      valueColor: '#041a94',
      units: ' mins',
      chart: 'pie',
    },
    {
      valueName: 'awake',
      valueColor: '#f21d56',
      units: ' mins',
      chart: 'pie',
    },
  ];

  content.displayStyle = {customTooltip: true};
  // Add info of how to display the data in the table
  if (target && target === 'overview') {
    content.columns = [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Light (Mins)',
        accessor: 'light',
      },
      {
        Header: 'Rem (Mins)',
        accessor: 'rem',
      },
      {
        Header: 'Deep (Mins)',
        accessor: 'deep',
      },
      {
        Header: 'Awake (Mins)',
        accessor: 'awake',
      },
      {
        Header: 'Efficiency (%)',
        accessor: 'efficiency',
      },
    ];
  } else {
    content.columns = [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Start Time',
        accessor: 'StartTimeOnly',
      },
      {
        Header: 'End Time',
        accessor: 'EndTimeOnly',
      },
      {
        Header: 'Light (Mins)',
        accessor: 'light',
      },
      {
        Header: 'Light (%)',
        accessor: 'light percent',
      },
      {
        Header: 'Rem (Mins)',
        accessor: 'rem',
      },
      {
        Header: 'Rem (%)',
        accessor: 'rem percent',
      },
      {
        Header: 'Deep (Mins)',
        accessor: 'deep',
      },
      {
        Header: 'Deep (%)',
        accessor: 'deep percent',
      },
      {
        Header: 'Awake (Mins)',
        accessor: 'awake',
      },
      {
        Header: 'Awake (%)',
        accessor: 'awake percent',
      },
      {
        Header: 'Efficiency (%)',
        accessor: 'efficiency',
      },
    ];
  }

  return content;
};

export {
  prepareDateValueData,
  prepareRestingHeartRateData,
  prepareTotalTrackerActiveMinutesData,
  prepareSleepDurationData,
};
