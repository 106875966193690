import React from 'react';

const PhiWarning = () => {
  return (
    <div className="centered-container max">
      Please remember that using any Personal Health Information (PHI) in this
      system is strictly prohibited. All information must be anonymized and meet
      the de-identification standard!
    </div>
  );
};

export default PhiWarning;
