import React, {useState, useEffect, useRef} from 'react';
import {NavLink, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {CgProfile} from 'react-icons/cg';
import ReactTooltip from 'react-tooltip';

import {useAuth} from '../authorization/AuthContext';
import ProfileMenu from './ProfileMenu';
import {fetchApiGet} from '../api/fetchApi';

import './Navbar.css';

const Navbar = () => {
  const {currentUser, logout} = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const [displayProfileMenu, setDisplayProfileMenu] = useState(false);
  const ref = useRef();

  const [userLoginInfo, setUserLoginInfo] = useState(null);

  // Get login info of current user to be able to display in the profile menu
  useEffect(() => {
    const getUserLoginInfo = async () => {
      try {
        // Get login info of current user from the server
        const res = await fetchApiGet('/auth/login');
        setUserLoginInfo(res);
      } catch (error) {
        console.error(error);
      }
    };
    getUserLoginInfo();
  }, [currentUser]);

  // Detect clicks outside the profile menu and close the menu in these cases
  useEffect(() => {
    const checkIfClickedOutside = (evt) => {
      // If the menu is open and the clicked target is not within the menu,
      // close the menu
      if (
        displayProfileMenu &&
        ref.current &&
        !ref.current.contains(evt.target)
      ) {
        setDisplayProfileMenu(false);
      }
    };

    // Event listener to detect mouse clicks outside the profile menu
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [displayProfileMenu]);

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  const closeNavbar = () => {
    setIsExpanded(false);
    setDisplayProfileMenu(false);
  };

  const handleProfileBtnClicked = () => {
    setDisplayProfileMenu(!displayProfileMenu);
  };

  const closeProfileMenu = (evt) => {
    if (evt.currentTarget.innerText === 'Logout') {
      {
        logout();
      }
    }
    setDisplayProfileMenu(false);
  };

  return (
    <div role="navigation" className="navbar">
      <nav className="nav">
        <div className="logo" onClick={closeNavbar}>
          <Link to="/">
            <div>
              <img src="images/IconHeart.png" alt="Logo Image" />
            </div>
            <p>CDConnect</p>
          </Link>
        </div>
        {/* If user is logged in, display the relevant navbar items, else display Login item */}
        {currentUser ? (
          <>
            <FontAwesomeIcon icon={faBars} onClick={toggleNavbar} />

            <div className="wrapper" ref={ref}>
              <button
                className="profile-icon"
                onClick={handleProfileBtnClicked}
              >
                <div className="dropdown">
                  <CgProfile data-tip data-for="user-profile" />
                  {/* Display tool tip only if the actual menu is not displayed*/}
                  {!displayProfileMenu && userLoginInfo && (
                    <ReactTooltip
                      /*Hide tool tip after a few seconds*/
                      afterShow={() => {
                        setTimeout(ReactTooltip.hide, 5000);
                      }}
                      /*Always display the tool tip at the bottom*/
                      place="bottom"
                      id="user-profile"
                      aria-haspopup="true"
                      effect="float"
                      className="custom-tooltip"
                    >
                      {userLoginInfo.userName && userLoginInfo.userName}
                      <br />
                      Click to manage account
                    </ReactTooltip>
                  )}
                </div>
              </button>
              {displayProfileMenu && userLoginInfo && (
                <ProfileMenu
                  itemClickedCallback={closeProfileMenu}
                  userLoginInfo={userLoginInfo}
                />
              )}
              <ul className={`collapsed ${isExpanded ? 'is-expanded' : ''}`}>
                <NavLink
                  to="/"
                  className={({isActive}) => {
                    return isActive ? 'active' : '';
                  }}
                  onClick={closeNavbar}
                >
                  <li>Dashboard</li>
                </NavLink>
                {userLoginInfo && userLoginInfo.role === 'Sys_Director' && (
                  <NavLink
                    to="/DbInfoPage"
                    className={({isActive}) => {
                      return isActive ? 'active' : '';
                    }}
                    onClick={closeNavbar}
                  >
                    <li>Sys_Director</li>
                  </NavLink>
                )}
              </ul>
            </div>
          </>
        ) : (
          <ul className={`collapsed ${isExpanded ? 'is-expanded' : ''}`}>
            <NavLink to="/LoginPage" className="navbar-right-menu">
              <li>Login</li>
            </NavLink>
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
